import { Button, Checkbox, Modal, ModalDialog, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BusinessContactService } from "../../../../services/business-contact.service";
import { EmployeeService } from "../../../../services/employee.service";
import { getActiveBusiness, useAuthStore } from "../../../../store/session";
import { getInitials } from "../../../../utils/misc";

export interface IShareContactModalProps {
  open: boolean;
  contactId?: string;
  isLoading: boolean;
  onClose: () => void;
  unshareContact: (ids: string[]) => void;
  onFinish?: (employeeIds: string[]) => void;
}
export default function ShareContactModal(props: IShareContactModalProps) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<any[]>([]);
  const [checkboxSelected, setCheckboxSelected] = useState<any[] | null>(null);
  const [originalSelecedContact, setOriginalSelecedContact] = useState<any[]>(
    []
  );
  const auth = useAuthStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const contactId = params.contactId || props.contactId;
  const contactAccessQuery = useQuery({
    queryKey: ["contact-access", contactId],
    queryFn: () => BusinessContactService.getContact(contactId!),
    enabled: !!contactId,
  });
  const onGetRecipients = () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    EmployeeService.getAll({})
      .then((data) => {
        setOptions(
          data
            .filter((d: any) => {
              return !d.isOwner;
            })
            .map((d: any) => {
              return {
                id: d.id,
                accountId: d.id,
                label: d.email,
                value: d.email,
                name: d.fullName,
                ...d,
              };
            })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    onGetRecipients();
  }, []);
  useEffect(() => {
    if (options.length > 0) {
      if (contactAccessQuery.isPending) return;
      const data = contactAccessQuery.data.contactAccess
        .filter((ca: any) => ca.account.id !== auth.session?.account.id && options.some((o: any) => o.id === ca.account.id))
        .map((d: any) => ({
          id: d.id,
          accountId: d.account.id,
          label: d.account.email,
          value: d.account.id,
          name: d.account.profile?.fullName,
        }));
      setOriginalSelecedContact(data);
      setCheckboxSelected(data);
      console.log("SHARECONTACT::setOriginalSelecedContact", data);
      console.log("SHARECONTACT::setCheckboxSelected", checkboxSelected);

    }
  }, [options, contactAccessQuery.data, contactAccessQuery.isPending]);

  useEffect(() => {
    return () => {
      setCheckboxSelected([]);
      setOriginalSelecedContact([]);
    };
  }, []);

  const isPending = contactAccessQuery.isPending || props.isLoading || !checkboxSelected;
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModalDialog
        layout="center"
        maxWidth={"lg"}
        sx={{
          // width: "100%",
          // maxWidth: "600px",
          // maxWidth: "330px",
          borderRadius: "24px",
        }}
        className="lg:p-6 p-4 max-h-[572px] w-full lg:max-w-[600px] max-w-[330px]"
      >
        {isLoading || contactAccessQuery.isPending ? (
          <div className="flex items-center justify-center w-full  h-[572px]">
            <div className="w-[42px] h-[42px] border-black rounded-full border-l-transparent top-[50%] left-[50%] absolute outline-none animate-spin border-[4px]"></div>
          </div>
        ) : (
          <div className="flex flex-col h-full gap-8 overflow-hidden">
            <Typography
              textColor={"primary.solidActiveBg"}
              textAlign={"center"}
              fontWeight={600}
              className="text-lg"
            >
              Share Contact
            </Typography>

            {options.length < 1 ? (
              <p className="text-[#475467] text-base text-center">
                No recipient has been listed yet
              </p>
            ) : (
              <>
                <div className="flex items-center p-2 px-0 border-b">
                  <div className="flex items-center flex-1 gap-2">
                    <Checkbox
                      onChange={() => {
                        if (checkboxSelected?.length === options.length) {
                          setCheckboxSelected([]);
                        } else {
                          setCheckboxSelected(options);
                        }
                      }}
                      checked={
                        (checkboxSelected?.length ?? 0) > 0 && checkboxSelected?.length === options.length
                      }
                    />
                    <Typography className="text-sm font-medium">
                      Select all
                    </Typography>
                  </div>
                  {/* <div className="flex items-center gap-2">
                    <Button
                      variant="outlined"
                      color="neutral"
                      disabled={checkboxSelected?.length === 0}
                      onClick={handleRemoveAll}
                      className="rounded-full border border-[#475467] lg:px-4 lg:py-[10px] text-sm p-2 disabled:text-[#98A2B3] disabled:border-[#98A2B3]"
                    >
                      <p className="text-sm">Remove</p>
                    </Button>
                  </div> */}
                </div>
                <div className="flex-1 overflow-scroll">
                  <div className="">
                    {!!checkboxSelected && options.map((account) => (
                      <>
                        <AccountItem
                          account={account}
                          selected={checkboxSelected?.some((s) => s.accountId === account.id) ?? false}
                          onChange={(account, checked) => {
                            if (!checked) {
                              setCheckboxSelected((prev) => prev?.filter((p) => !(p.accountId === account.accountId)) ?? []);
                            } else {
                              setCheckboxSelected((prev) => [...(prev || []),
                              { ...account },
                              ]);
                            }
                          }}
                        />
                      </>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center justify-between pb-4">
              <Button
                variant="plain"
                color="primary"
                onClick={() => props.onClose()}
              >
                <Typography className="text-sm text-[#499873]">
                  Cancel
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  const updatedSelected = checkboxSelected
                    ?.filter(
                      (item) =>
                        !originalSelecedContact.some(
                          (contact) => contact.accountId === item.accountId
                        )
                    )
                    .map((item) => item.accountId);
                  const toRemove = originalSelecedContact.filter(
                    (item) =>
                      !checkboxSelected?.some(
                        (contact) => contact.accountId === item.accountId
                      )
                  );
                  console.log("SHARECONTACT::toRemove", toRemove);
                  console.log("SHARECONTACT::updatedSelected", updatedSelected);
                  console.log("SHARECONTACT::checkboxSelected", checkboxSelected);
                  console.log("SHARECONTACT::originalSelecedContact", originalSelecedContact);
                  if (toRemove.length > 0) {
                    props.unshareContact(toRemove.map((tr) => tr.id));
                  }
                  props.onFinish?.(updatedSelected ?? []);
                }}
                disabled={!checkboxSelected}
              >
                <Typography className="text-sm text-white">
                  {isPending ? "Loading...." : t("commons.saveBtn")}
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </ModalDialog>
    </Modal>
  );
}

function AccountItem({
  account,
  selected,
  onChange,
}: {
  account: any;
  selected: boolean;
  onChange: (account: any, checked: boolean) => void;
}) {
  const business = getActiveBusiness();
  return (
    <div
      key={account.id}
      className="flex items-center gap-4 p-2 px-0 border-b border-[#F2F4F7]"
    >
      <Checkbox
        checked={selected}
        onChange={(e) => onChange(account, e.target.checked)}
      />
      <div className="flex items-center flex-1 gap-4 ">
        <div className="flex border font-bold  border-[#636B744D] items-center justify-center min-w-[32px] min-h-[32px] md:w-[50px] md:h-[50px] text-sm text-[#499873] uppercase bg-[#F1F8F4] rounded-full">
          {account.id && account?.name
            ? getInitials(account?.name)
            : getInitials(account.value)}
        </div>
        <div className="flex flex-col gap-1">
          {account.id && account?.name && (
            <Typography className="text-[#101828] text-sm font-semibold md:text-lg">
              {account.name ?? "-"}
            </Typography>
          )}
          <Typography className="text-[#475467] text-xs lg:text-base">
            {account.value}
          </Typography>
          <Typography className="text-[#101828] text-sm font-semibold">
            {business?.companyName}
          </Typography>
        </div>
      </div>
    </div>
  );
}
