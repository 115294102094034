import * as React from "react";
import {
  Modal,
  ModalDialog,
  Typography,
  Box,
  Button,
  Input,
  Select,
  Option,
} from "@mui/joy";
import { formatCurrencyByCode } from "../../../utils/formatPrice";
import { OrderService } from "../../../services/order.service";
import showErrorToast, { ErrorToastConfig } from "../../Error";
import toast from "react-hot-toast";

interface RequestFullPaymentModalProps {
  open: boolean;
  orderId: string;
  currency: string;
  paidAmount: number;
  grandTotal: number;
  onClose: () => void;
}

export default function RequestFullPaymentModal({
  open,
  orderId,
  currency,
  paidAmount,
  grandTotal,
  onClose,
}: RequestFullPaymentModalProps) {
  const [discount, setDiscount] = React.useState("0");
  const [discountType, setDiscountType] = React.useState("fixed");
  const remainingAmount = grandTotal - paidAmount;
  const discountAmount =
    discountType === "fixed"
      ? Number(discount)
      : (Number(discount) / 100) * remainingAmount;
  const payableAmount = remainingAmount - discountAmount;

  const handleSubmit = () => {
    OrderService.requestFullPayment({
      remainingAmount: remainingAmount,
      discount: discountAmount,
      payableAmount: payableAmount,
      orderId: orderId,
    })
      .then(() => {
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        toast(showErrorToast(error), ErrorToastConfig);
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          maxWidth: 500,
          borderRadius: "xl",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography level="h2" fontSize="xl2" mb={1}>
            Request Full Payment
          </Typography>
          <Typography level="body-md" mb={3} color="neutral">
            Request the buyer to pay the remaining balance early
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: "var(--joy-palette-primary-50)",
            p: 3,
            borderRadius: "md",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              gap: 2,
              mb: 2,
            }}
          >
            <Typography level="body-lg">Grand total amount</Typography>
            <Typography level="body-lg">
              {formatCurrencyByCode(grandTotal, currency)}
            </Typography>

            <Typography level="body-lg">Paid amount</Typography>
            <Typography level="body-lg">
              ({formatCurrencyByCode(paidAmount, currency)})
            </Typography>

            <Typography level="body-lg">Remaining payable amount</Typography>
            <Typography level="body-lg">
              {formatCurrencyByCode(remainingAmount, currency)}
            </Typography>

            <Typography level="body-lg">Full Payment Discount</Typography>
            <Typography level="body-lg">
              ({formatCurrencyByCode(discountAmount, currency)})
            </Typography>

            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Input
                value={discount}
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
                slotProps={{
                  input: {
                    sx: { width: "80px" },
                  },
                }}
                type="number"
                endDecorator={
                  <Select
                    value={discountType}
                    onChange={(_, value) => {
                      setDiscountType(value as string);
                      setDiscount("0");
                    }}
                    variant="plain"
                    sx={{
                      borderLeft: "1px solid #ccc",
                      borderRadius: 0,
                      mr: "-1rem",
                    }}
                    defaultValue="percentage"
                    size="md"
                  >
                    <Option value="percentage">%</Option>
                    <Option value="fixed">123</Option>
                  </Select>
                }
              />
            </Box>
          </Box>

          <Box sx={{ height: 1, bgcolor: "divider", my: 3 }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h4">Payable amount</Typography>
            <Typography level="h4">
              {formatCurrencyByCode(payableAmount, currency)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
          <Button
            variant="outlined"
            color="neutral"
            onClick={onClose}
            sx={{ flex: 1 }}
          >
            Cancel
          </Button>
          <Button
            disabled={payableAmount <= 0}
            onClick={handleSubmit}
            variant="solid"
            color="primary"
            sx={{ flex: 1 }}
          >
            Submit
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
