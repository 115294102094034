import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Box, { BoxProps } from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

import AppBar from "../components/AppBar";
import LinkzSVG from "../components/Icons/LinkzSVG";
import Dock from "../components/NavDock/NavDock";
import LanguageSelector from "../components/LanguageSelector";

interface IParams extends BoxProps {
  children: React.ReactNode;
  fullHeight?: boolean;
  isCentered?: boolean;
  sxProps?: React.CSSProperties;
  title?: string;
  withAppBar?: boolean;
  withDock?: boolean;
  showNavArrow?: boolean;
}

export default function Shared(parsm: IParams) {
  const {
    children,
    fullHeight,
    isCentered,
    sxProps,
    title,
    withAppBar,
    withDock,
    showNavArrow,
  } = parsm;

  return (
    <Box
      component="main"
      id="default"
      sx={{
        height: fullHeight ? "100vh" : undefined,
        marginX: "auto",
        position: "relative",
        ...(isCentered && {
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "100vh",
        }),
        ...sxProps,
      }}
      // maxWidth={{ lg: "23px" }}
      // width={"393px"}
      {...parsm}
    >
      {withAppBar ? <AppBar /> : null}
      {title ? (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          paddingY={2}
          position="relative"
        >
          {withDock || !showNavArrow ? null : (
            <IconButton
              aria-labelledby="navigate-before"
              onClick={() => window.history.back()}
              sx={{ left: 20, position: "absolute" }}
            >
              <NavigateBeforeIcon />
            </IconButton>
          )}
          <Typography
            fontSize="xl"
            level="h1"
            textAlign="center"
            textColor="primary.900"
          >
            {title}
          </Typography>
        </Stack>
      ) : null}
      {withDock ? <Dock /> : null}
      <Stack height="100vh" paddingY={4} paddingX={"1rem"}>
        <Stack alignItems="center">
          <Box className="w-full flex justify-end">
            <LanguageSelector />
          </Box>

          <a href="/" className="p-4">
            <LinkzSVG />
          </a>
        </Stack>
        {children}
      </Stack>
    </Box>
  );
}
