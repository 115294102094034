import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Input,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import { BrowserRouterConstants } from "../../../../utils/constants";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useContainer from "./useContainer";
import BusinessSelectionModal from "../../BusinessSelectionModal/BusinessSelectionModal";

const EmailSigninForm = () => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isLoading,
    closeBusinessModalHandler,
    openBusinessSelectionModal,
  } = useContainer();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          alignItems="stretch"
          direction="column"
          justifyContent="center"
          marginTop={3}
          spacing={3}
          px={"1rem"}
        >
          <Stack rowGap={1}>
            {/* <Typography
            className={`transition-opacity duration-300 ${
              email ? "opacity-100 h-auto" : "opacity-0 h-0"
            }`}
            fontSize={"md"}
            fontWeight={"500"}
          >
            {t("landing.signup.email")}
          </Typography> */}
            <Input
              placeholder="hello@linkzasia.com"
              size="lg"
              type="email"
              {...register("email", { required: true })}
            />
          </Stack>
          <Stack rowGap={1}>
            {/* <Typography
            className={`transition-opacity duration-300 ${
              password ? "opacity-100 h-auto" : "opacity-0 h-0"
            }`}
            fontSize={"md"}
            fontWeight={"500"}
          >
            {t("landing.signup.password")}
          </Typography> */}
            <Input
              endDecorator={
                <IconButton onClick={handleShowPassword}>
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              }
              placeholder="••••••••••"
              required
              size="md"
              type={showPassword ? "text" : "password"}
              {...register("password")}
            />
          </Stack>
          <Link
            component={RouterLink}
            fontSize="sm"
            to={BrowserRouterConstants.Recover}
          >
            {t("landing.login.forgotPassword")}
          </Link>
          <Box
            sx={{
              width: "300px",
              textAlign: "center",
              mx: "auto !important",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {errors && (
              <Typography fontSize={12} color="danger">
                {errors.email?.message || errors.password?.message}
              </Typography>
            )}
            <Button
              id={"btn-login"}
              disabled={isLoading}
              size="lg"
              fullWidth
              type="submit"
            >
              {t("landing.loginBtn")}
            </Button>
          </Box>
        </Stack>
      </form>

      <BusinessSelectionModal
        close={closeBusinessModalHandler}
        isOpen={openBusinessSelectionModal}
      />
    </>
  );
};

export default EmailSigninForm;
