/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

import FormControl from "@mui/joy/FormControl";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";

import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Fragment, useEffect, useState } from "react";

import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteOption,
  Stack,
  Tab,
  tabClasses,
  TabList,
  Tabs,
} from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { Pagination } from "../../components/Pagination";
import DropdownButton from "../../components/buttons/DropdownButton";

import { OrderService } from "../../services/order.service";
import { useAuthStore } from "../../store/session";
import {
  IOrderList,
  orderReportTypes,
  OrderStatusEnum,
  orderStatuses,
} from "../../types/order";
import {
  BrowserRouterConstants,
  DefaultCurrencies,
} from "../../utils/constants";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import toast from "react-hot-toast";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import OrderReportTable from "../../components/OrdersV2/OrderReportTable";
import OrderDetailsPdf, { OrderDetailsProps } from "./pdf/OrderDetailsPDF";
import { getInvoiceNumber } from "../../utils/order";
import { usePDF } from "react-to-pdf";
import { BusinessService } from "../../services/business.service";
import {
  ArrowDownTrayIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from "@heroicons/react/24/solid";
import FlagById from "../../components/FlagById";
import { WhiteListedCountries } from "../../utils/constants";
import OrderReportCards from "../../components/OrdersV2/OrderReportCards";

const DownloadPdfButton = ({ handleDownloadPdf, orderId }: any) => (
  <IconButton onClick={() => handleDownloadPdf(orderId)}>
    <ArrowDownTrayIcon width={20} height={20} />
  </IconButton>
);

const DateFilter = ({
  selectedOrderDateFilter,
  handleOrderDateFilterChange,
  t,
}: any) => {
  return (
    <Select
      value={selectedOrderDateFilter}
      size="sm"
      placeholder={t("order.filters.date.placeholder")}
      slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
      onChange={handleOrderDateFilterChange}
    >
      <Option value="">{t("order.filters.date.all")}</Option>
      <Option value="today">{t("order.filters.date.today")}</Option>
      <Option value="yesterday">{t("order.filters.date.yesterday")}</Option>
      <Option value="this_week">{t("order.filters.date.thisWeek")}</Option>
      <Option value="this_month">{t("order.filters.date.thisMonth")}</Option>
    </Select>
  );
};

const CompanyFilter = ({
  externalBusinessList,
  selectedCompany,
  handleOnChangeCompanyFilter,
  t,
}: any) => {
  return (
    <Autocomplete
      name={"companyFilter"}
      size="sm"
      // variant="linkz-autocomplete-rounded"
      // startDecorator={<MagnifyingGlassIcon width={20} height={20} />}
      placeholder={t("order.filters.company.placeholder")}
      options={externalBusinessList || null}
      getOptionLabel={(option: any) => option.companyName || ""}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      value={selectedCompany || ""}
      renderOption={(props, option: any) => (
        <AutocompleteOption
          key={option?.id}
          {...props}
          sx={{
            py: 1,
            "&:hover": {
              backgroundColor: "var(--joy-palette-neutral-100)",
            },
          }}
        >
          <Typography>
            {option?.newBusinessInput
              ? option?.newBusinessOptionText
              : `${option?.companyName}`}
          </Typography>
        </AutocompleteOption>
      )}
      onChange={(_event, newValue) => {
        if (newValue) {
          handleOnChangeCompanyFilter(newValue.id);
        } else {
          handleOnChangeCompanyFilter("");
        }
      }}
    />
  );
};

const OrderTypeFilter = ({
  selectedOrderTypeFilter,
  handleOrderTypeFilterChange,
  t,
}: any) => {
  return (
    <Select
      value={selectedOrderTypeFilter}
      size="sm"
      placeholder={t("order.filters.type.all")}
      onChange={handleOrderTypeFilterChange}
    >
      <Option value="">{t("order.filters.type.all")}</Option>
      <Option value="sales">{t("order.filters.type.sales")}</Option>
      <Option value="purchase">{t("order.filters.type.purchase")}</Option>
    </Select>
  );
};

const OrderStatusFilter = ({
  selectedOrderStatusFilter,
  handleOrderStatusFilterChange,
  t,
}: any) => {
  return (
    <Select
      value={selectedOrderStatusFilter}
      size="sm"
      placeholder={t("order.filters.status.all")}
      onChange={handleOrderStatusFilterChange}
    >
      <Option value="">{t("order.filters.status.all")}</Option>
      {orderStatuses.map((status) => (
        <Option value={status}>{status.label}</Option>
      ))}
    </Select>
  );
};

const CurrencyFilter = ({
  selectedCurrencyFilter,
  handleOnChangeCurrencyFilter,
  t,
}: any) => {
  return (
    <Select
      size="sm"
      placeholder={t("order.filters.currency")}
      value={selectedCurrencyFilter}
      onChange={(_, newValue) => {
        handleOnChangeCurrencyFilter(newValue); // Currency's id
      }}
      renderValue={(option) => (
        <Typography
          startDecorator={
            <FlagById
              id={
                DefaultCurrencies.find(
                  (currency) => currency.id === option?.value
                )?.country ?? ""
              }
            />
          }
        >
          {option?.label}
        </Typography>
      )}
      sx={{ backgroundColor: "white" }}
    >
      {DefaultCurrencies.filter((currency) =>
        WhiteListedCountries.includes(currency.country)
      ).map((currency) => (
        <Option
          key={currency.country}
          value={currency.id}
          label={currency.label}
        >
          <Typography startDecorator={<FlagById id={currency.country} />}>
            {currency.label}
          </Typography>
        </Option>
      ))}
    </Select>
  );
};

const DateSort = ({
  sortByDate,
  handleOnChangeSortByDate,
  t,
}: // isMobile,
any) => {
  return (
    <Select
      size="sm"
      placeholder={"Sort By Date"}
      indicator={null}
      endDecorator={
        sortByDate === "DESC" ? (
          <BarsArrowDownIcon width={20} height={20} />
        ) : (
          <BarsArrowUpIcon width={20} height={20} />
        )
      }
      value={sortByDate}
      // renderValue={(option) => (isMobile ? "" : option?.label)}
      onChange={(_, value) => {
        handleOnChangeSortByDate(value);
      }}
    >
      <Option value="DESC">
        {t("order.sort.date.DESC")} <BarsArrowDownIcon width={20} height={20} />
      </Option>
      <Option value="ASC">
        {t("order.sort.date.ASC")} <BarsArrowUpIcon width={20} height={20} />
      </Option>
    </Select>
  );
};

export default function ReportOrders() {
  const { t } = useTranslation();
  // const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState<any>(true);
  const { session } = useAuthStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md")); // Mobile is started from md
  // const [searchTerm, setSearchTerm] = useState<string>();
  const [orderList, setOrderList] = useState<IOrderList[] | any>([]);
  const [externalBusinessList, setExternalBusinessList] = useState<any>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>("");
  const [selectedOrderTypeFilter, setSelectedOrderTypeFilter] = useState<any>();
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] =
    useState<any>();
  const [selectedOrderDateFilter, setSelectedOrderDateFilter] = useState<any>();
  const [selectedCurrencyFilter, setSelectedCurrencyFilter] = useState("");
  const [sortByDate, setSortByDate] = useState("DESC");
  const [reportType, setReportType] = useState("");
  const [pagination, setPagination] = useState<any>({
    currentLast: 0,
    max: 0,
    limit: 20,
    offset: 0,
  });
  const [pdfContent, setPdfContent] = useState<OrderDetailsProps>(
    {} as OrderDetailsProps
  );

  const selectedCompany = externalBusinessList?.find(
    (business: any) => business.id === selectedCompanyId
  );

  console.log("Selected Company >>>", selectedCompany);

  // const defaultSearchParams = new URLSearchParams(searchParams);
  // defaultSearchParams.set("reportType", orderReportTypes.TOTAL_RECEIVABLE)
  // setSearchParams(defaultSearchParams);

  const paramStatus = searchParams.get("status");
  const paramDate = searchParams.get("date");
  const paramType = searchParams.get("type");
  const paramQ = searchParams.get("q");
  const paramReportType = searchParams.get("reportType");
  const paramCompanyId = searchParams.get("companyId");
  const paramCurrency = searchParams.get("currency");
  const paramSortByDate = searchParams.get("sortByDate");

  const initiateOrderForm = (orderType: "purchase" | "sales") => {
    const data = {
      grandTotalAmount: 0,
      paymentTerm: "",
      sellerId: null,
      sellerIds:
        orderType === "sales"
          ? {
              businessId: session?.activeBusiness.id,
              accountId: session?.account.id,
            }
          : null,
      buyerId: null,
      buyerIds:
        orderType === "purchase"
          ? {
              businessId: session?.activeBusiness.id,
              accountId: session?.account.id,
            }
          : null,
      invoiceNumber: "",
      createdById: session?.account.id || null,
      status: OrderStatusEnum.DRAFT,
    };
    // console.log("To initiate: ", data);

    /** Mixpanel Tracking */
    if (orderType === "sales") {
      TrackerService.track(
        TRACKER_CONSTANTS.ORDER.events.SalesOrderButtonClicked
      );
    } else if (orderType === "purchase") {
      TrackerService.track(
        TRACKER_CONSTANTS.ORDER.events.PurchaseOrderButtonClicked
      );
    }

    OrderService.initiateDraft(data)
      .then((res) =>
        navigate(
          BrowserRouterConstants.ClientPrefix +
            BrowserRouterConstants.Orders +
            `/${res.data.id}`
        )
      )
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAndSetExternalBusinessList();
  }, []);

  useEffect(() => {
    if (paramDate && !selectedOrderDateFilter) {
      setSelectedOrderDateFilter(paramDate);
    }
    if (paramStatus && !selectedOrderStatusFilter) {
      setSelectedOrderStatusFilter(paramStatus);
    }
    if (paramType && !selectedOrderTypeFilter) {
      setSelectedOrderTypeFilter(paramType);
    }
    if (paramQ && !searchTerm) {
      setSearchTerm(paramQ ?? "");
      getAndSetOrderList(paramQ ?? "");
    }
    if (paramReportType) {
      console.log("from paramReportType >>>", paramReportType);
      setReportType(paramReportType);
      getAndSetOrderList();
    }

    if (paramCompanyId) {
      console.log("from paramCompanyId >>>", paramCompanyId);
      setSelectedCompanyId(paramCompanyId);
      getAndSetOrderList();
    }

    if (paramSortByDate) {
      setSortByDate(paramSortByDate);
    }

    if (paramCurrency) {
      setSelectedCurrencyFilter(paramCurrency);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      session?.activeBusiness &&
      !(
        (paramQ && !searchTerm)
        // (paramDate && !selectedOrderDateFilter) ||
        // (paramType && !selectedOrderTypeFilter) ||
        // (paramStatus && !selectedOrderStatusFilter)
      )
    ) {
      getAndSetOrderList();
    }
  }, [session?.activeBusiness, pagination.offset, pagination.limit]);

  async function getAndSetExternalBusinessList() {
    const externalBusinessList = await BusinessService.getExternalBusinessList({
      businessId: session?.activeBusiness.id,
    });
    if (externalBusinessList) {
      console.log("External Business List", externalBusinessList);
      setExternalBusinessList(externalBusinessList);
    }
  }

  async function fetchOrderPdfContent(orderId: string) {
    try {
      const result = await OrderService.getOrderPDFContent(orderId);
      result.invoiceNumber = getInvoiceNumber(result, result.revisions);
      console.log("PDF Content ::", result);
      setPdfContent(result);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAndSetOrderList(searchTerm?: string) {
    setLoading(true);

    try {
      const responseData = await OrderService.getOrderReportByReportType({
        businessId: session?.activeBusiness.id,
        reportType: paramReportType,
        searchTerm: searchTerm,
        companyId: paramCompanyId,
        hasPagination: true,
        limit: pagination.limit,
        offset: pagination.offset,
      });
      console.log("Order List Response :::", responseData);
      const initialOrderList = responseData.items.map((item: any) => {
        const orderType =
          item.orderType ||
          (item.seller && item.seller.id === session?.activeBusiness?.id)
            ? "sales"
            : item.buyer && item.buyer.id === session?.activeBusiness?.id
            ? "purchase"
            : "draft";
        return {
          ...item,
          orderType,
        };
      });
      console.log("Initial Order List :::", initialOrderList);

      setOrderList(initialOrderList);
      setPagination(responseData.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  }

  const handleSearchSubmit = () => {};

  // const handleOnClick = (action: any, orderId: any) => {
  //   switch (action) {
  //     case "duplicate":
  //       setConfirmationModal({
  //         open: true,
  //         title: "Are you sure you want to duplicate this order?",
  //         content: "",
  //         btnConfirmText: "Duplicate",
  //         onConfirm: () => duplicateOrder(orderId),
  //         onCancel: () => setConfirmationModal({ open: false }),
  //       });
  //       break;
  //     case "delete":
  //       setConfirmationModal({
  //         open: true,
  //         title: "Are you sure you want to delete this draft?",
  //         content: "This cannot be undone.",
  //         btnConfirmText: "Delete",
  //         onConfirm: () => deleteOrder(orderId),
  //         onCancel: () => setConfirmationModal({ open: false }),
  //       });
  //       break;
  //     case "cancel":
  //       setConfirmationModal({
  //         open: true,
  //         title: "Are you sure you want to cancel this order?",
  //         content: "This cannot be undone.",
  //         onConfirm: () => cancelOrder(orderId),
  //         onCancel: () => setConfirmationModal({ open: false }),
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const { toPDF, targetRef } = usePDF({
    filename: `order-${pdfContent?.invoiceNumber || "00000000-0000000"}.pdf`,
  });

  useEffect(() => {
    if (Object.keys(pdfContent).length > 0) {
      toPDF({});
    }
  }, [pdfContent]);

  // if (Object.keys(pdfContent).length > 0) {
  //   console.log("PDF Content >>>", !!pdfContent)
  //   toPDF({});
  // }

  const handleDownloadPdf = (orderId: string) => {
    fetchOrderPdfContent(orderId);
    // toPDF({});
  };

  const handleOnSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(e.target.value);
    const newParams = new URLSearchParams(searchParams);
    if (e.target.value) {
      newParams.set("q", e.target.value);
    } else {
      newParams.delete("q");
    }
    setSearchParams(newParams);
    getAndSetOrderList(e.target.value);
  };

  const handleOrderDateFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("date", newValue);
    } else {
      newParams.delete("date");
    }
    setSearchParams(newParams);
    setSelectedOrderDateFilter(newValue);
  };

  const filterByDate = (orderDate: any) => {
    const now = dayjs();
    switch (selectedOrderDateFilter) {
      case "today":
        return dayjs(orderDate).isSame(now, "day");
      case "yesterday":
        return dayjs(orderDate).isSame(now.subtract(1, "day"), "day");
      case "this_week":
        return dayjs(orderDate).isSame(now, "week");
      case "this_month":
        return dayjs(orderDate).isSame(now, "month");
      default:
        return true;
    }
  };

  const handleOrderStatusFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: { value: string; label: string; backendStatuses: [] } | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("status", newValue.value);
    } else {
      newParams.delete("status");
    }
    setSearchParams(newParams);
    setSelectedOrderStatusFilter(newValue);
  };

  const handleOrderTypeFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("type", newValue);
    } else {
      newParams.delete("type");
    }
    setSearchParams(newParams);
    setSelectedOrderTypeFilter(newValue);
  };

  const handleOnChangeCompanyFilter = (companyId: any) => {
    console.log("handleOnChangeCompanyFilter >>>", companyId);
    const newParams = new URLSearchParams(searchParams);
    if (companyId) {
      newParams.set("companyId", companyId);
    } else {
      newParams.delete("companyId");
    }
    setSearchParams(newParams);
    setSelectedCompanyId(companyId);
  };

  const handleOnChangeCurrencyFilter = (currencyId: any) => {
    const newParams = new URLSearchParams(searchParams);
    if (currencyId) {
      newParams.set("currency", currencyId);
    } else {
      newParams.delete("currency");
    }
    setSearchParams(newParams);
    setSelectedCurrencyFilter(currencyId);
  };

  const handleOnChangeSortByDate = (newValue: any) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("sortByDate", newValue);
    } else {
      newParams.delete("sortByDate");
    }
    setSearchParams(newParams);
    setSortByDate(newValue);
  };

  const handleChangeReportType = (newValue: any) => {
    setReportType(newValue as string);
    setPagination({ ...pagination, offset: 0 }); // pagination should start from 1st page whenever report type is changed
    const newParams = new URLSearchParams(searchParams);
    newParams.set("reportType", newValue);
    setSearchParams(newParams);
  };

  const filteredOrderList = orderList
    ?.filter((order: any) => {
      console.log("Order to be filtered :::", selectedOrderStatusFilter, order);
      return (
        (selectedOrderTypeFilter
          ? order.orderType === selectedOrderTypeFilter
          : true) &&
        (selectedOrderStatusFilter
          ? selectedOrderStatusFilter.backendStatuses.includes(order.status)
          : true) &&
        (selectedCurrencyFilter
          ? order.currency === selectedCurrencyFilter
          : true) &&
        filterByDate(order.updatedAt)
      );
    })
    .sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt) as any;
      const dateB = new Date(b.updatedAt) as any;

      if (sortByDate === "ASC") {
        return dateA - dateB;
      } else if (sortByDate === "DESC") {
        return dateB - dateA;
      }
      return 0;
    });

  const tableHeader = () => {
    if (reportType === "TOTAL_PAYABLE") {
      return ["OrderNo.", "Company", "Payable", "Status"];
    } else if (reportType === "TOTAL_RECEIVABLE") {
      return ["OrderNo.", "Company", "Receivable", "Status"];
    } else if (reportType === "PAYMENT_HISTORY") {
      return [
        { title: "Type", width: "80px" },
        "OrderNo.",
        "Company",
        "Payment Type",
        "Total Amount",
        "Paid",
        "Settlement",
        "Payment Method",
      ];
    }
  };

  const handlePaging = (action: string | number) => {
    console.log("Paging Action :::", action);
    if (typeof action === "string") {
      /** when pagination button is string */
      switch (action) {
        case "first":
          setPagination({ ...pagination, offset: 0 });
          break;

        case "previous":
          setPagination({
            ...pagination,
            offset: pagination.offset - pagination.limit,
          });
          break;

        case "next":
          setPagination({
            ...pagination,
            offset: pagination.offset + pagination.limit,
          });
          break;

        case "last":
          setPagination({
            ...pagination,
            offset:
              (Math.floor(pagination.max / pagination.limit) - 1) *
                pagination.limit +
              pagination.limit,
          });
          break;
      }
    } else {
      /** when pagination button is number */
      console.log({ pagination });
      setPagination({
        ...pagination,
        offset: (action - 1) * pagination.limit,
      });
    }
  };

  const renderDesktopFilters = () => (
    <Fragment>
      <Stack flexGrow={1}>
        <FormControl sx={{ width: "100%", maxWidth: "460px" }}>
          {/* <FormLabel>Search for order</FormLabel> */}
          <Input
            value={searchParams.get("q") || ""}
            variant="linkz-input-search"
            placeholder={t("order.searchPlaceholder")}
            startDecorator={<MagnifyingGlassIcon width={20} height={20} />}
            onChange={handleOnSearch}
            size="sm"
          />
        </FormControl>
      </Stack>
      <Stack flexGrow={1} width={"100%"}></Stack>
      <Stack>
        <DateFilter
          selectedOrderDateFilter={selectedOrderDateFilter}
          handleOrderDateFilterChange={handleOrderDateFilterChange}
          t={t}
        />
      </Stack>
      <Stack direction={"row"} gap={3}>
        <CompanyFilter
          externalBusinessList={externalBusinessList}
          selectedCompany={selectedCompany}
          handleOnChangeCompanyFilter={handleOnChangeCompanyFilter}
          t={t}
        />

        {reportType === orderReportTypes.PAYMENT_HISTORY && (
          <OrderTypeFilter
            selectedOrderTypeFilter={selectedOrderTypeFilter}
            handleOrderTypeFilterChange={handleOrderTypeFilterChange}
            t={t}
          />
        )}

        {[
          orderReportTypes.TOTAL_PAYABLE,
          orderReportTypes.TOTAL_RECEIVABLE,
        ].includes(reportType) && (
          <OrderStatusFilter
            selectedOrderStatusFilter={selectedOrderStatusFilter}
            handleOrderStatusFilterChange={handleOrderStatusFilterChange}
            t={t}
          />
        )}

        <CurrencyFilter
          selectedCurrencyFilter={selectedCurrencyFilter}
          handleOnChangeCurrencyFilter={handleOnChangeCurrencyFilter}
          t={t}
        />

        {/** Sort By Date */}
        <DateSort
          sortByDate={sortByDate}
          handleOnChangeSortByDate={handleOnChangeSortByDate}
          t={t}
          isMobile={isMobile}
        />

        {/* {orderList && orderList.length > 0 && (
          <Stack direction={"row"} alignItems={"center"}>
            <Typography>
              {`${pagination.offset + 1} - ${pagination.currentLast}`}{" "}
              {t("commons.pagination.of") +
                " " +
                pagination.max +
                " " +
                t("commons.pagination.items")}
            </Typography>
            <IconButton
              key={"previous"}
              disabled={pagination.offset <= 0}
              type="button"
              onClick={() => {
                handlePaging("previous");
              }}
            >
              <ChevronLeftOutlined />
            </IconButton>
            <IconButton
              key={"next"}
              disabled={pagination.offset + pagination.limit >= pagination.max}
              type="button"
              onClick={() => {
                handlePaging("next");
              }}
            >
              <ChevronRightOutlined />
            </IconButton>
          </Stack>
        )} */}
      </Stack>
    </Fragment>
  );

  const renderMobileFilters = () => {
    return (
      <Fragment>
        <Stack
          direction={"row"}
          gap={1}
          mb={2}
          sx={{
            [`& > *`]: { flexGrow: 1 },
          }}
        >
          <CompanyFilter
            externalBusinessList={externalBusinessList}
            selectedCompany={selectedCompany}
            handleOnChangeCompanyFilter={handleOnChangeCompanyFilter}
            t={t}
          />

          <CurrencyFilter
            selectedCurrencyFilter={selectedCurrencyFilter}
            handleOnChangeCurrencyFilter={handleOnChangeCurrencyFilter}
            t={t}
          />
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          sx={{
            [`& > *`]: { flexGrow: 1 },
          }}
        >
          <DateFilter
            selectedOrderDateFilter={selectedOrderDateFilter}
            handleOrderDateFilterChange={handleOrderDateFilterChange}
            t={t}
          />

          {reportType === orderReportTypes.PAYMENT_HISTORY && (
            <OrderTypeFilter
              selectedOrderTypeFilter={selectedOrderTypeFilter}
              handleOrderTypeFilterChange={handleOrderTypeFilterChange}
              t={t}
            />
          )}

          {[
            orderReportTypes.TOTAL_PAYABLE,
            orderReportTypes.TOTAL_RECEIVABLE,
          ].includes(reportType) && (
            <OrderStatusFilter
              selectedOrderStatusFilter={selectedOrderStatusFilter}
              handleOrderStatusFilterChange={handleOrderStatusFilterChange}
              t={t}
            />
          )}

          {/** Sort By Date */}
          <DateSort
            sortByDate={sortByDate}
            handleOnChangeSortByDate={handleOnChangeSortByDate}
            t={t}
            isMobile={isMobile}
          />
        </Stack>
      </Fragment>
    );
  };

  /* Render Page UI */
  return (
    <Fragment>
      {/* {isMobile ? "Mobile" : "Desktop"} */}
      <Tabs
        aria-label="transactions"
        sx={{
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          backgroundColor: "transparent",
        }}
        onChange={(_event, newValue) => {
          console.log("Report Type >>>", newValue);
          handleChangeReportType(newValue);
        }}
        value={reportType}
      >
        <TabList
          sx={{
            overflowX: "scroll",
            [`&& .${tabClasses.root}`]: {
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                "&::after": {
                  bgcolor: "primary.500",
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  height: 2,
                },
                color: "primary.plainColor",
              },
              bgcolor: "transparent",
              flex: isMobile ? "1 0 auto" : "0 0 auto",
              paddingX: isMobile ? 1 : 2,
              paddingY: 2,
              whiteSpace: "nowrap",
            },
            gap: isMobile ? 1 : 4,
            justifyContent: "start",
          }}
        >
          <Tab
            value={"TOTAL_RECEIVABLE"}
            sx={{ fontWeight: "600" }}
            disabled={false}
          >
            {t("order.reportType.TOTAL_RECEIVABLE")}
          </Tab>
          <Tab
            value={"TOTAL_PAYABLE"}
            sx={{ fontWeight: "600" }}
            disabled={false}
          >
            {t("order.reportType.TOTAL_PAYABLE")}
          </Tab>
          <Tab
            value={"PAYMENT_HISTORY"}
            sx={{ fontWeight: "600" }}
            disabled={false}
          >
            {t("order.reportType.PAYMENT_HISTORY")}
          </Tab>
        </TabList>
      </Tabs>

      {/** Search Filters - Mobile  */}
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", lg: "none" },
          my: 2,
          gap: 3,
          flexDirection: "column",
        }}
      >
        <Stack
          spacing={2}
          direction={"row"}
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleSearchSubmit();
          }}
        >
          <Input
            value={searchParams.get("q") || ""}
            variant="linkz-input-search"
            name="searchFormData.search"
            placeholder={t("order.searchPlaceholder")}
            startDecorator={<MagnifyingGlassIcon width={20} height={20} />}
            sx={{ flexGrow: 1 }}
            onChange={handleOnSearch}
          />
          {/* <IconButton
            size="sm"
            variant="outlined"
            color="neutral"
            sx={{ flexGrow: 1 }}
            onClick={() => setOpen(true)}
          >
            <FilterAltIcon />
          </IconButton>
          <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
              <ModalClose />
              <Typography id="filter-modal" level="h2">
                {t("order.filters.label")}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {renderFilters()}
                <Button
                  type="submit"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  {t("commons.submitBtn")}
                </Button>
              </Sheet>
            </ModalDialog>
          </Modal> */}
        </Stack>
        <Stack>{renderMobileFilters()}</Stack>
        <Stack>
          <DropdownButton
            element={
              <Button
                endDecorator={<ExpandMoreOutlined />}
                fullWidth
                // onClick={initiateOrderForm}
              >
                {t("order.createOrderBtn")}
              </Button>
            }
            options={[
              {
                label: t("order.createPurchaseOrder"),
                action: () => {
                  initiateOrderForm("purchase");
                },
              },
              {
                label: t("order.createSalesOrder"),
                action: () => {
                  initiateOrderForm("sales");
                },
              },
            ]}
          />
        </Stack>
      </Sheet>

      {/** Search Filters - Tablet and Above  */}
      <Stack
        className="SearchAndFilters-tabletUp"
        display={{ xs: "none", lg: "flex" }}
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        sx={{
          borderRadius: "sm",
          py: 2,
          gap: 1,
        }}
      >
        {renderDesktopFilters()}
      </Stack>

      {filteredOrderList && filteredOrderList?.length > 0 ? (
        isMobile ? (
          <OrderReportCards
            orderList={filteredOrderList}
            reportType={reportType}
            handleDownloadPdf={handleDownloadPdf}
            DownloadPdfButton={DownloadPdfButton}
          />
        ) : (
          <>
            <OrderReportTable
              orderList={filteredOrderList}
              tableHeader={tableHeader()}
              reportType={reportType}
              handleDownloadPdf={handleDownloadPdf}
              DownloadPdfButton={DownloadPdfButton}
            />

            {/** Bottom Pagination */}

            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              alignContent={"center"}
              gap={0}
              mt={6}
            >
              <FormControl
                size="sm"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography>Show</Typography>
                <Select
                  size="sm"
                  placeholder={"Limit"}
                  value={pagination.limit}
                  onChange={(_, value) => {
                    // const currentPage = Math.ceil(pagination.currentLast / pagination.limit);
                    setPagination({
                      ...pagination,
                      limit: value,
                      // offset: (currentPage - 1) * value,
                    });
                  }}
                >
                  {[10, 20, 50, 100].map((limit) => (
                    <Option value={limit}>{limit}</Option>
                  ))}
                </Select>
                <Typography>invoices per page</Typography>
              </FormControl>
              <Pagination
                count={Math.ceil(pagination.max / pagination.limit)}
                page={Math.ceil(pagination.currentLast / pagination.limit)}
                // defaultPage={pagination.currentLast}
                onChange={(_, value) => {
                  console.log(
                    "pagination value",
                    value,
                    pagination.currentLast
                  );
                  handlePaging(value);
                }}
                showFirstButton
                showLastButton
              />
            </Stack>
          </>
        )
      ) : (
        <Box textAlign={"center"} mt={4}>
          <Typography level={"h3"} mb={2}>
            {t("order.table.noOrders")}
          </Typography>
          <Typography> {t("order.table.noOrdersPrompt")}</Typography>
        </Box>
      )}

      {/** Modals */}
      <LoadingModal isLoading={loading} />

      {/* Pdf Content for Order invoice download */}
      <div className="h-0 overflow-hidden w-[800px]">
        {/* <div className="w-[800px]"> */}
        <div ref={targetRef}>
          <OrderDetailsPdf {...(pdfContent as OrderDetailsProps)} />
        </div>
      </div>
    </Fragment>
  );
}
