import { signInWithPhoneNumber } from "firebase/auth";
import { privateAxios, publicAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";
import { auth } from "../lib/firebase";

export class AuthService {
  static async changePhoneNumber(payload: IChnagePhoneNumberPayload) {
    return await publicAxios.post(ApiConstants.changePhoneNumberURL, payload);
  }

  static async signin(payload: ISigninPayload) {
    return await publicAxios.post(ApiConstants.signinURL, payload);
  }

  static async signinSSO(payload: ISignInSSO) {
    return await publicAxios.post(ApiConstants.signinSSOURL, payload);
  }

  static async signup(payload: ISignupPayload) {
    return await publicAxios.post(ApiConstants.signupURL, payload);
  }

  static async signupInvitationWithRole(
    payload: ISignupInvitationWithRolePayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupInvitationWithRoleURL,
      payload
    );
  }

  static async signupInvitationWithoutRole(
    payload: ISignupInvitationWithoutRolePayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupInvitationWithoutRoleURL,
      payload
    );
  }

  static async signupInvitationBusinessConnect(
    payload: ISignupInvitationBusinessConnectPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupInvitationBusinessConnectURL,
      payload,
      { params: { token: payload.token } }
    );
  }

  static async signupSSO(payload: ISignupSSOPayload) {
    return await publicAxios.post(ApiConstants.signupSSOURL, payload);
  }

  static async signupSSOWithRoleInvitation(
    payload: ISignupSSOWithRoleInvitationPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupSSOInvitationWithRoleURL,
      payload
    );
  }

  static async signupSSOWithoutRoleInvitation(
    payload: ISignupSSOWithoutRoleInvitationPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupSSOInvitationWithoutRoleURL,
      payload
    );
  }

  static async signupSSOBusinessConnectInvitation(
    payload: ISignupSSOBusinessConnectPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupSSOBusinessConnectURL,
      payload,
      {
        params: { token: payload.token },
      }
    );
  }

  static async signUpWithPhone(payload: ISignupWithPhonePayload) {
    return await publicAxios.post(`${ApiConstants.signupURL}/phone`, payload);
  }

  static async signUpWithPhoneWithRoleInvitation(
    payload: ISignupSSOWithRoleInvitationPayload
  ) {
    return await publicAxios.post(
      `${ApiConstants.signupURL}/phone/with-role`,
      payload
    );
  }

  static async otpVerify(payload: IOtpVerificationPayload) {
    return await publicAxios.post(ApiConstants.otpVerificationURL, payload);
  }

  static async resendOTP(payload: IResendOtpVerificationPayload) {
    return await publicAxios.post(ApiConstants.resendOTPURL, payload);
  }

  static async forgotPassword(payload: IForgotPassword) {
    return await publicAxios.post(ApiConstants.forgotPassword, payload);
  }

  static async resendPasswordResetCode(payload: IForgotPassword) {
    return await publicAxios.post(
      ApiConstants.ResendPasswordResetCode,
      payload
    );
  }

  static async verifyResetPasswordCode(payload: IVerifyPasswordResetCode) {
    return await publicAxios.post(
      ApiConstants.verifyPasswordResetCode,
      payload
    );
  }

  static async resetPassword({
    payload,
    token,
  }: {
    payload: IResetPassword;
    token: string;
  }) {
    return await publicAxios.post(ApiConstants.resetPassword, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async invitationTokenCheck(payload: IInvitationTokenCheckPayload) {
    return await publicAxios.post(ApiConstants.invitationTokenCheck, payload);
  }

  static async referralCodeCheck(payload: IReferralCodeCheckPayload) {
    return await publicAxios.post(ApiConstants.referralCodeCheck, payload);
  }

  static async changePassword(payload: IChangePassword) {
    return await privateAxios.post(ApiConstants.changePassword, payload);
  }

  static async chooseBusinessAfterLogin(businessId: string) {
    return await publicAxios.post(
      `${ApiConstants.chooseBusinessURL}/${businessId}`
    );
  }

  static async logout() {
    return await privateAxios.get(ApiConstants.logoutURL);
  }

  static async verifyPhoneNumber(payload: IVerifyPhoneSignupPayload) {
    return await publicAxios.post(ApiConstants.verifyPhoneNumber, payload);
    // return { data: { isRegisteredPhone: false } };
  }

  static async verifyEmail(payload: IVerifyEmailSignupPayload) {
    return await publicAxios.post(ApiConstants.verifyEmail, payload);
    // return { data: { isExistEmail: false } };
  }

  static async verifyCompanyName(payload: IVerifyCompanyNamePayload) {
    return await publicAxios.post(ApiConstants.verifyCompanyName, payload);
    // return { data: { isExistCompanyName: false } };
  }

  static async sendFirebaseOtp(payload: IVerifyPhoneSignupPayload) {
    const { phoneNumber, phoneNumberCountryCode } = payload;
    const appVerifier = window.recaptchaVerifier;
    try {
      const response = await signInWithPhoneNumber(
        auth,
        `+${phoneNumberCountryCode}${phoneNumber.replace("+", "")}`,
        appVerifier
      );

      window.confirmationResult = response;
      // sessionStorage.setItem("confirmationResult", response.verificationId);
      return { message: "success sending OTP", status: 200 };
    } catch (error: any) {
      console.error("Error sending OTP", error);
    }
  }
}
