import { InfoOutlined} from "@mui/icons-material";
import { Avatar, Button, Card, Modal, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  content: string;
  open: boolean;
  btnConfirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmationModal(props: IProps) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: 24,
    px: 4,
    py: "22px",
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
    maxWuidth: 400,
  };

  const { t } = useTranslation();

  return (
    <Modal
      open={props.open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Avatar color="primary" size="lg">
            <InfoOutlined />
          </Avatar>
          <Typography
            mt={4}
            textColor={"text.primary"}
            fontWeight={"lg"}
            fontSize={24}
            id="modal-modal-title"
          >
            {props.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.content}
          </Typography>
          <br />
          <Stack
            direction={"row"}
            gap={2}
            // justifyContent={"space-between"}
            // alignContent={"space-between"}
            // alignItems={"space-between"}
          >
            <Button
              size="sm"
              onClick={props.onCancel}
              variant="plain"
              color="danger"
            >
              {t("commons.cancelBtn")}
            </Button>
            <Button
              size="sm"
              onClick={props.onConfirm}
              variant="solid"
              color="primary"
            >
              {!!props.btnConfirmText
                ? props.btnConfirmText
                : t("commons.removeBtn")}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}
