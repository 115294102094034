import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentLinkService } from "../../services/payment-link.service";
import { useForm } from "react-hook-form";
import { BusinessService } from "../../services/business.service";

export type PaymentMethodFormValue = {
  paymentMethod: string;
};
export const useContainer = () => {
  const { linkId } = useParams();
  const { control, setValue, getValues } = useForm<PaymentMethodFormValue>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);
  const [paymentData, setPaymentData] = useState<any>();
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sellerData, setSellerData] = useState(null);
  const [adminFee, setAdminFee] = useState<any>(0);

  const getPaymentMethod = async () => {
    if (linkId) {
      const data = await PaymentLinkService.getMethodList(linkId);
      setPaymentMethod(data);
      setTimeout(() => {
        setLoading(false);
        setShowPaymentModal(true);
      }, 2000);
    }
  };

  const getSellerData = async (sellerId: string) => {
    const data = await BusinessService.getBusinessPublicData({
      businessId: sellerId,
    });
    setSellerData(data);
  };

  const requestPayment = () => {
    setShowPaymentModal(false);
    setLoading(true);
    const payload = {
      adminFee: adminFee,
      method: getValues("paymentMethod").split(":")[0],
      option: getValues("paymentMethod").split(":")[1] ?? "",
      redirectUrl: window.location.href,
    };
    if (linkId)
      PaymentLinkService.requestPayment(linkId, payload).then(async () => {
        setLoading(false);
        await getPaymentInfo(linkId);
      });
  };

  const getPaymentInfo = async (linkId: string, onErr?: () => void) => {
    try {
      const data = await PaymentLinkService.getPaymentInfo(linkId);
      if (data) {
        if (
          `${data.status}`.toLowerCase().includes("success") &&
          data.transactionInfo.paymentMethod !== "loan-payment"
        ) {
          setSuccess(true);
          setTimeout(() => {
            window.close();
          }, 3000);
        } else {
          setShowPaymentInfoModal(true);
          if (new Date(data.transactionInfo.expirationDate) < new Date()) {
            onErr?.();
          } else setPaymentInfo(data.transactionInfo);
        }
      }
    } catch (err: any) {
      console.log(err);
      if (`${err?.response.data.message}` === "payment-already-completed") {
        setLoading(false);
        setSuccess(true);
        return;
      }
      onErr?.();
    }
  };

  const verifyLink = async (linkId: string) => {
    const data = await PaymentLinkService.verifyLink(linkId);
    setPaymentData(data);
    await getSellerData(data.sellerId);
    await getPaymentInfo(linkId, () => {
      getPaymentMethod();
    });
  };

  useEffect(() => {
    if (!paymentData && linkId) {
      setLoading(true);
      verifyLink(linkId);
    }
  }, []);
  return {
    showPaymentInfoModal,
    showPaymentModal,
    paymentInfo,
    paymentData,
    paymentMethod,
    setPaymentMethod,
    loading,
    success,
    setShowPaymentModal,
    setShowPaymentInfoModal,
    setPaymentInfo,
    setLoading,
    setSuccess,
    control,
    setValue,
    getValues,
    requestPayment,
    sellerData,
    setAdminFee,
  };
};
