import Button from "@mui/joy/Button";
import Tabs from "@mui/joy/Tabs";
// import Shared from "../../layouts/Shared";
import { Add, LocalShippingOutlined } from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PurchaseOrderForm from "../../components/Orders/PurchaseOrderForm";
import SalesOrderForm from "../../components/Orders/SalesOrderForm";
import {
  Toggler,
  formatInventoryItemResponse,
  transformUomList,
} from "../../components/utils";
import { AccountService } from "../../services/account.service";
import { ItemService } from "../../services/item.service";
import { OrderService } from "../../services/order.service";

import GrandTotal from "../../components/Orders/GrandTotal";
import { IOrderSavePayload, OrderStatusEnum } from "../../types/order";
import { BusinessService } from "../../services/business.service";
import dayjs from "dayjs";
import { SuccessModal } from "../../components/SuccessModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import PaymentModal from "../../components/payments/PaymentModal";
import OrderSummary from "../../components/Orders/OrderSummary";
import AddNewBusinessModal from "../../components/Orders/AddNewBusinessModal";
import ProductAndService from "../../components/Orders/ProductAndService";
import { PaymentService } from "../../services/payment.service";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import PaymentInfoModal from "../../components/payments/PaymentInfoModal";
import { useAuthStore } from "../../store/session";
import { BrowserRouterConstants } from "../../utils/constants";
import { getMessaging, onMessage } from "firebase/messaging";
import { TaxService } from "../../services/tax.service";
// import { orderStatuses } from "../../types/order";

// const localeCurrencies = ["IDR", "MYR", "PHP", "THB", "VND"];
export const currencyList = [
  "IDR",
  "MYR",
  "PHP",
  "THB",
  "VND",
  "USD",
  "SGD",
  "EUR",
  "HKD",
  "JPY",
];
// currencyList.unshift(localeCurrencies[0]);

export default function EditOrders() {
  const { t } = useTranslation();

  const [orderType, setOrderType] = useState<any>("sales");
  const [currentOrderStatus, setCurrentOrderStatus] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const [isReadOnly, setIsReadOnly] = useState<any>(false);
  const initSuccessModal = {
    open: false,
    title: "Success",
    content: "Order Saved Successfully.",
  };
  const [successModal, setSuccessModal] = useState<any>(initSuccessModal);

  const [autoSaveLoading, setAutoSaveLoading] = useState<any>(null);

  const [confirmationModal, setConfirmationModal] = useState<any>({
    open: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {},
  });

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [searchParam] = useSearchParams();

  const enquiryPaymentStatus = async (transactionId: string) => {
    setLoading(true);
    PaymentService.enquiryPaymentStatus(transactionId,"")
      .then((_) => {
        setLoading(false);
        setSuccessModal({
          ...successModal,
          open: true,
          content: "Payment successfully made",
        });
        setTimeout(() => {
          window.location.href = window.location.href.split("?")[0];
          setSuccessModal(initSuccessModal);
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast(showErrorToast(error), ErrorToastConfig);
      });
  };

  // Listen to payment redirect url
  useEffect(() => {
    console.log("IFRAME", searchParam.get("transaction_id"));
    const transactionId = searchParam.get("transaction_id");
    if (transactionId && (transactionId?.length ?? 0) > 0) {
      enquiryPaymentStatus(searchParam.get("transaction_id") ?? "");
    }
  }, [searchParam]);

  onMessage(getMessaging(), (payload) => {
    console.log("FCM Message received. ", payload);
    if (`${payload.data?.topic}`.includes("payment-success")) {
      setSuccessModal({
        ...successModal,
        open: true,
        content: "Payment successfully made",
      });
      setTimeout(() => {
        setSuccessModal(initSuccessModal);
      }, 2000);
    }
  });

  const [addNewBusinessModal, setAddNewBusinessModal] = useState<any>({
    open: false,
    businessName: "",
    accountName: "",
    btnAddText: "",
    email: "",
    modalTitle: "",
  });

  const [loading, setLoading] = useState<any>(false);
  // const [currencyList, setCurrencyList] = useState<any[]>();
  const [internalAccountList, setInternalAccountList] = useState<any[]>([]);
  const [selectedInternalAccount, setSelectedInternalAccount] = useState<any>(
    {}
  );
  const [externalBusinessList, setExternalBusinessList] = useState<any>([]);
  const [selectedExternalBusiness, setSelectedExternalBusiness] = useState<any>(
    {}
  );
  const [productSearchList, setProductSearchList] = useState<any[]>([]);

  const [nationalTaxes, setNationalTaxes] = useState<any[]>([]);

  const [showBtnConfirmOrderByBuyer, setShowBtnConfirmOrderByBuyer] =
    useState(false);

  const prevFormValuesRef = useRef({});
  const prevFocusedError = useRef<any>("");
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { session } = useAuthStore();

  const methods = useForm<IOrderSavePayload>({
    mode: "onChange",
    defaultValues: {
      // sellerIds: {
      //   businessId: "",
      //   accountId: "",
      // },
      // buyerIds: {
      //   businessId: "",
      //   accountId: "",
      // },
      sellerIds: null,
      buyerIds: null,
      orderItems: [],
      grandTotalAmount: 0,
      totalTax: 0,
      additionalDiscount: 0,
      deliveryFee: 0,
    },
  });

  const {
    control,
    register,
    setValue,
    getValues,
    trigger,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  /** Initial fetch and set Data: Internal Account List */
  useEffect(() => {
    if (session?.activeBusiness) {
      getAndSetInternalAccountList();
      getAndSetExternalBusinessList();
    }
  }, [session?.activeBusiness]);

  /** Initial fetch and set Data: Order */
  useEffect(() => {
    if (internalAccountList && orderId && session?.account)
      getAndSetInitialOrderData(orderId);
  }, [internalAccountList, orderId, session?.account]);

  async function getAndSetInternalAccountList() {
    const internalAccountList =
      await AccountService.getAccountListByCurrentBusinessInternal(
        session?.activeBusiness.id || null
      );
    if (internalAccountList) {
      console.log("Internal Account List", internalAccountList);
      setInternalAccountList(internalAccountList);
    }
  }

  async function deleteDraftOrder() {
    const isDeleted = await OrderService.deleteOrder(orderId);
    if (isDeleted) {
      navigate(
        BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
      );
    }
  }

  async function cancelOrder() {
    console.log("To Cancel", orderId);
    setLoading(true);
    const softDeletedTime = await OrderService.cancelOrder(orderId);
    setLoading(false);
    if (softDeletedTime)
      navigate(
        BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
      );
  }

  async function updateOrderDataToDB(data: IOrderSavePayload) {
    console.log("To Update Order Data:", data);
    return OrderService.update(orderId, data);
  }

  async function getAndSetExternalBusinessList() {
    const externalBusinessList = await BusinessService.getExternalBusinessList({
      businessId: session?.activeBusiness.id,
    });
    if (externalBusinessList) {
      console.log("External Business List", externalBusinessList);
      setExternalBusinessList(externalBusinessList);
    }
  }

  function getAndSetProductSearchList(
    orderT: string,
    sellerId: string,
    buyerId: string | null
  ) {
    /** If sales, only sellerId is necessary to fetch seller's products
     * because the current user is seller */
    if (orderT === "sales" && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsSeller(sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));

      /** If purchase, buyerId and sellerId is necessary to fetch seller's products
       * because the current user is buyer and need to check seller's products first */
    } else if (orderT === "purchase" && buyerId && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsBuyer(buyerId, sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));
    }
  }

  async function getAndSetNationalTaxes(businessId: any) {
    try {
      const res = await TaxService.getBusinessTaxes(businessId || "");
      console.log("Response Taxes::", res);
      setNationalTaxes(res.map((res: any) => res.tax));
    } catch (error) {
      console.error(error);
    }
  }

  async function getAndSetInitialOrderData(orderId: string | undefined) {
    const responseOrderData = await OrderService.getOrder(orderId);
    if (responseOrderData) {
      console.log(
        "Response Get Order :::",
        responseOrderData,
        session?.activeBusiness.id,
        internalAccountList
      );

      setCurrentOrderStatus(responseOrderData.status || OrderStatusEnum.DRAFT);
      const formEditableOrderStatuses = [
        OrderStatusEnum.DRAFT,
        OrderStatusEnum.AWAITING_RESPONSE,
      ];
      formEditableOrderStatuses.includes(responseOrderData.status)
        ? setIsReadOnly(false)
        : setIsReadOnly(true);
      setInvoiceNumber(responseOrderData.invoiceNumber || null);

      if (responseOrderData.seller?.id || responseOrderData.buyer?.id) {
        /** Case: Having seller or buyer */

        let initialOrderData: IOrderSavePayload = {};
        initialOrderData.createdById = responseOrderData.createdBy;

        initialOrderData.invoiceNumber =
          responseOrderData.invoiceNumber || null;

        if (responseOrderData.seller?.id === session?.activeBusiness.id) {
          /** Current active business is seller */

          setOrderType("sales");
          setSelectedInternalAccount(responseOrderData.sellerUser);
          // handleOnChangeInternalAccount("sales", responseOrderData.sellerUser);
          // handleOnChangeExternalBusiness("sales", responseOrderData.buyer);
          responseOrderData.buyer &&
            setSelectedExternalBusiness(responseOrderData.buyer);

          getAndSetNationalTaxes(responseOrderData.seller?.id);
          getAndSetProductSearchList(
            "sales",
            responseOrderData.seller?.id,
            null
          );
        } else if (responseOrderData.buyer?.id === session?.activeBusiness.id) {
          /** Current active business is buyer */

          setOrderType("purchase");

          setSelectedInternalAccount(responseOrderData.buyerUser);
          responseOrderData.seller &&
            setSelectedExternalBusiness(responseOrderData.seller);
          // handleOnChangeExternalBusiness("purchase", responseOrderData.seller);
          getAndSetNationalTaxes(responseOrderData.seller?.id);
          getAndSetProductSearchList(
            "purchase",
            responseOrderData.seller?.id,
            responseOrderData.buyer?.id
          );

          if (responseOrderData.status === OrderStatusEnum.AWAITING_RESPONSE) {
            /** Show Accept Order (Behalf of Seller) button by buyer
             * only if seller is not in system */
            setShowBtnConfirmOrderByBuyer(
              responseOrderData.sellerUser && !responseOrderData.sellerUser.uid
            );
          } else {
            setShowBtnConfirmOrderByBuyer(false);
          }
        }

        initialOrderData.sellerIds = responseOrderData.seller?.id
          ? {
              businessId: responseOrderData.seller.id,
              accountId: responseOrderData.sellerUser.id,
            }
          : null;

        initialOrderData.buyerIds = responseOrderData.buyer?.id
          ? {
              businessId: responseOrderData.buyer?.id,
              accountId: responseOrderData.buyerUser?.id,
            }
          : null;

        initialOrderData.paymentTerm = responseOrderData.paymentTerm;
        initialOrderData.currency = responseOrderData.currency;
        initialOrderData.deliveryDate = responseOrderData.deliveryDate
          ? dayjs(responseOrderData.deliveryDate).format("YYYY-MM-DD")
          : "";
        initialOrderData.shippingMethod = responseOrderData.shippingMethod;
        initialOrderData.vehicleNumber = responseOrderData.vehicleNumber;
        initialOrderData.trackingNumber = responseOrderData.trackingNumber;
        initialOrderData.driverName = responseOrderData.driverName;
        initialOrderData.deliveryFee = responseOrderData.deliveryFee || 0;
        initialOrderData.additionalDiscount =
          responseOrderData.additionalDiscount || 0;
        initialOrderData.additionalDiscountType =
          responseOrderData.additionalDiscountType;
        initialOrderData.grandTotalAmount = responseOrderData.grandTotalAmount;
        initialOrderData.remarks = responseOrderData.remarks;

        /** One OrderItem, One Product.. (only if there's orderItem data) */
        console.log("Response Order Items :::", responseOrderData.orderItems);
        initialOrderData.orderItems = responseOrderData.orderItems?.map(
          (orderItem: any) => {
            orderItem.orderItemId = orderItem.id;
            orderItem.productId = orderItem.inventoryItem.id;
            orderItem.createdById = orderItem.inventoryItem.createdBy;
            orderItem.businessId = orderItem.inventoryItem.businessId;
            orderItem.productName = orderItem.inventoryItem.productName;
            orderItem.productDescription =
              orderItem.inventoryItem.productDescription;
            orderItem.inventoryType = orderItem.inventoryItem.inventoryType;
            orderItem.sku = orderItem.inventoryItem.sku;
            orderItem.uomList = transformUomList(orderItem.inventoryItem);
            orderItem.uomItem = orderItem.uomList.find(
              (option: any) => option.uom === orderItem.uom
            );
            orderItem.uom = orderItem.uom;
            orderItem.taxLabel = orderItem.taxLabel;
            orderItem.tax = orderItem.tax;
            orderItem.taxItem = {
              taxLabel: orderItem.taxLabel,
              taxAmount: orderItem.tax,
            };
            orderItem.conversion = orderItem.conversion;
            return orderItem;
          }
        );

        console.log("initial Order Data (before reset)", initialOrderData);

        reset(initialOrderData);
      } else {
        /** Case: Both buyer and seller are null */

        console.log("Both seller and buyer are null");

        setOrderType("sales");
        setValue("createdById", session?.account.id || null);
        // Set the current logged in user in the seller's Autocomplete
        const currentLoggedInAccount = internalAccountList?.filter(
          (account: any) => account.id === session?.account.id
        )?.[0];
        console.log("Setting sellerId:", currentLoggedInAccount);

        setSelectedInternalAccount(currentLoggedInAccount);
        setValue(
          `sellerIds`,
          session?.account.id
            ? {
                businessId: session?.activeBusiness.id || null,
                accountId: session?.account.id,
              }
            : null
        );
        setValue(
          "currency",
          currentLoggedInAccount.role?.[0]?.business?.bankInfo?.[0]?.currency
        );
        session?.activeBusiness.id &&
          getAndSetProductSearchList("sales", session?.activeBusiness.id, null);
        // handleOnChangeInternalAccount("sales", currentLoggedInAccount);
      }
    }
  }

  const handleDraftSave = async () => {
    const payload = getValues();
    payload.status = currentOrderStatus;
    console.log("To Draft Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: "Success",
          content: "Order draft saved successfully",
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error when Saving Draft: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleSendSave = async (event: any) => {
    event.preventDefault();

    const isValidated = await handleValidation();
    if (!isValidated) return;

    console.log("After clicking handle Send Save ::", selectedInternalAccount);

    const isCreditEnough = await checkCreditAmount();
    if (!isCreditEnough) return;

    const payload = getValues();
    payload.status = OrderStatusEnum.AWAITING_RESPONSE;
    payload.deductedCredit = 1;

    console.log("To Send Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: "Success",
          content: "Order saved and sent successfully",
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Sending and Saving Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleConfirm = async (event: any) => {
    event.preventDefault();
    const isValidated = await handleValidation();
    if (!isValidated) return;

    const isCreditEnough = await checkCreditAmount();
    if (!isCreditEnough) return;

    const payload = getValues();
    payload.status = OrderStatusEnum.PENDING_PAYMENT;
    // payload.status = OrderStatusEnum.PROCESSING;
    payload.deductedCredit = 1;

    console.log("To Confirm :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: "Success",
          content: "Order confirmed Successfully",
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Confirming Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleComplete = async (event: any) => {
    event.preventDefault();
    const payload = getValues();
    payload.status = OrderStatusEnum.COMPLETE;
    console.log("To Complete :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: "Success",
          content: "Order completed",
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Completing Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleDeleteDraftOrder = () => {
    setConfirmationModal({
      open: true,
      title: "Are you sure you want to delete?",
      content: "This cannot be undone",
      btnConfirmText: "Delete",
      onConfirm: () => deleteDraftOrder(),
      onCancel: () => setConfirmationModal({ open: false }),
    });
  };

  const handleTopUpCredit = async () => {
    const payload = getValues();
    payload.status = currentOrderStatus;
    console.log("To Draft Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        navigate(
          BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Credit
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Draft Saving Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleChangeOrderType = (orderT: any) => {
    setOrderType(orderT);

    const prevSellerIds = getValues(`sellerIds`);
    const prevBuyerIds = getValues(`buyerIds`);

    /** Swap buyerIds -> sellerIds, sellerIds -> buyerIds */
    setValue(`sellerIds`, prevBuyerIds || null);
    prevBuyerIds && errors.sellerIds && clearErrors("sellerIds");

    setValue(`buyerIds`, prevSellerIds || null);
    prevSellerIds && errors.buyerIds && clearErrors("buyerIds");

    if (orderT === "sales") {
      session?.activeBusiness.id &&
        getAndSetProductSearchList("sales", session?.activeBusiness.id, null);
    } else if (orderT === "purchase") {
      const sellerBusinessId = getValues("sellerIds.businessId");
      sellerBusinessId &&
        getAndSetProductSearchList(
          "purchase",
          sellerBusinessId,
          session?.activeBusiness.id || null
        );
    }
  };

  const handleCancel = async () => {
    setConfirmationModal({
      open: true,
      title: "Are you sure you want to cancel this order?",
      content: "This cannot be undone.",
      btnConfirmText: "Confirm",
      onConfirm: () => cancelOrder(),
      onCancel: () => setConfirmationModal({ open: false }),
    });
  };

  const handleAutoDraftSave = async (data: IOrderSavePayload) => {
    data.status = currentOrderStatus;
    // data.grandTotalAmount = data.grandTotalAmount || 0;
    console.log("To Auto Save Data:", data);
    setAutoSaveLoading(true);
    setTimeout(async () => {
      const resData = await updateOrderDataToDB(data);
      if (resData) {
        setAutoSaveLoading(false);

        console.log("Response Data:", resData);
      }
    }, 2000);
  };

  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);

  const getPaymentStatus = (onSuccess?: () => void) => {
    PaymentService.enquiryPaymentStatus(orderId ?? "","").then((response) => {
      setPaymentInfo(response.data.transactionInfo);
      onSuccess?.();
    });
  };

  useEffect(() => {
    getPaymentStatus();
  }, [orderId]);

  const handlePaymentContinue = async () => {
    const payload = getValues();
    payload.status = OrderStatusEnum.PROCESSING;
    console.log("To Continue", payload);
    setOpenPaymentModal(false);
    if (
      !["request-for-loan", "offline-payment"].includes(
        payload?.paymentMethod ?? ""
      )
    ) {
      const method = payload.paymentMethod?.split(":")[0];
      const option = payload.paymentMethod?.split(":")[1];
      if (method) {
        setLoading(true);
        PaymentService.requestPayment(
          PaymentService.createPaymentRequestPayload({
            method,
            option: option ?? "",
            transactionType: "order",
            transactionId: orderId,
            amount: getValues("grandTotalAmount"),
            currency: getValues("currency"),
            redirectUrl: window.location.href,
          },),""
        )
          .then((response) => {
            if (response) {
              getPaymentStatus(() => {
                setShowPaymentInfoModal(true);
              });
            }
          })
          .catch((err) => {
            toast(showErrorToast(err), ErrorToastConfig);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      updateOrderDataToDB(payload)
        .then((_) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast(showErrorToast(err), ErrorToastConfig);
        });
    }
  };
  const handlePaymentSkip = () => {
    const payload = getValues();
    payload.paymentMethod = null;
    payload.status = OrderStatusEnum.PROCESSING;
    console.log("To Skip", payload);
    updateOrderDataToDB(payload);
    setOpenPaymentModal(false);
  };

  const handleGeneratePdf = async () => {
    console.log("Generate Pdf clicked:");
    setLoading(true);
    try {
      const responseData = await OrderService.getOrderPdf(orderId);
      const blob = new Blob([responseData], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `order-${invoiceNumber}.pdf`;
      document.body.appendChild(a);
      setLoading(false);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setLoading(false);
      console.error("Error downloading PDF: ", error);
    }
  };

  const handleValidation = async () => {
    prevFocusedError.current = "";
    const isValid = await trigger(); // switch the form validation on manually
    if (!isValid) {
      console.log("isValid Errors ::::", errors);
      if (errors.buyerIds) {
        orderType === "sales" &&
          getValues("newBusiness") &&
          clearErrors("buyerIds"); // clear buyerIds errors if newBusiness is filled for buyer
      } else if (errors.sellerIds) {
        orderType === "purchase" &&
          getValues("newBusiness") &&
          clearErrors("sellerIds"); // clear buyerIds errors if newBusiness is filled for buyer
      }
      return false;
    }
    return true;
  };

  const checkCreditAmount = async () => {
    const remainingCredits = Number(
      selectedInternalAccount?.role?.[0]?.business.credits
    );
    if (remainingCredits <= 0) {
      setConfirmationModal({
        open: true,
        title: "Your credit is low",
        content:
          "You need to top up your credit first before continue this transaction",
        btnConfirmText: "Top up credit",
        onConfirm: () => handleTopUpCredit(),
        onCancel: () => setConfirmationModal({ open: false }),
      });
      return false;
    }
    return true;
  };

  const handleOnChangeInternalAccount = (orderT: any, selectedAcc: any) => {
    setSelectedInternalAccount(selectedAcc);
    if (orderT === "sales") {
      setValue(
        "sellerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );

      setValue(
        "currency",
        selectedAcc?.role?.[0]?.business?.bankInfo?.[0]?.currency
      );
    } else if (orderT === "purchase") {
      setValue(
        "buyerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );
    }
  };

  const handleOnChangeExternalBusiness = (orderT: any, selectedBiz: any) => {
    console.log("Selected External Business", selectedBiz);
    if (orderT === "purchase") {
      setValue("currency", selectedBiz?.bankInfo?.[0]?.currency || "");
    }
    if (typeof selectedBiz === "string") {
      console.log("Selected External Business [STRING]", selectedBiz);
      /** Timeout to avoid instant validation of the dialog's form. */

      setTimeout(() => {
        setAddNewBusinessModal({
          ...addNewBusinessModal,
          open: true,
          email: "",
          businessName: "",
        });
      });
    } else if (selectedBiz && selectedBiz.newBusinessInput) {
      /** If the selected one is the adding new business */

      const businessType =
        orderType === "sales"
          ? "Buyer"
          : orderType === "purchase"
          ? "Seller"
          : "Business";
      const modalTitleMode = getValues("newBusiness") ? "Edit" : "Add";
      const btnAddTextMode = getValues("newBusiness") ? "Save" : "Add";
      setAddNewBusinessModal({
        ...addNewBusinessModal,
        open: true,
        email: "",
        businessName: selectedBiz.newBusinessInput,
        accountName: "",
        btnAddText: `${btnAddTextMode} New ${businessType}`,
        modalTitle: `${modalTitleMode} New ${businessType}`,
      });
    } else {
      /** If the selected one is the existing business */
      console.log(
        "Selected External Business [NORMAL EXISTING BUSINESS]",
        selectedBiz
      );
      setSelectedExternalBusiness(selectedBiz);
      if (orderT === "sales") {
        setValue(
          `buyerIds`,
          selectedBiz?.id
            ? {
                businessId: selectedBiz?.id,
                accountId: selectedBiz?.role?.[0].account.id,
              }
            : null
        );
        errors.buyerIds && clearErrors("buyerIds");
      } else if (orderT === "purchase") {
        setValue(
          `sellerIds`,
          selectedBiz?.id
            ? {
                businessId: selectedBiz?.id,
                accountId: selectedBiz?.role?.[0].account.id,
              }
            : null
        );
        errors.sellerIds && clearErrors("sellerIds");

        setValue("currency", selectedBiz?.bankInfo?.[0]?.currency || "");

        getAndSetProductSearchList(
          "purchase",
          selectedBiz?.id,
          session?.activeBusiness.id || null
        );
      }
      /** Clear the new business input if existing business is selected */
      setValue("newBusiness", null);
    }
  };

  const handleUpdateCompanyInfo = () => {
    console.log("handleUpdateCompanyInfo :::", getValues());
    handleAutoDraftSave(getValues());
  };

  const handleEditNewBusiness = () => {
    const businessType =
      orderType === "sales"
        ? "Buyer"
        : orderType === "purchase"
        ? "Seller"
        : "Business";
    setAddNewBusinessModal({
      ...addNewBusinessModal,
      open: true,
      btnAddText: `Save New ${businessType}`,
      modalTitle: `Edit New ${businessType}`,
    });
  };

  const handleRemoveNewBusiness = () => {
    setAddNewBusinessModal({
      ...addNewBusinessModal,
      businessName: "",
      accountName: "",
      email: "",
    });
    setValue("newBusiness", null);
  };

  const handleCancelAddNewBusinessModal = () => {
    setAddNewBusinessModal({
      ...addNewBusinessModal,
      open: false,
    });
  };
  const handleSubmitAddNewBusinessModal = () => {
    setAddNewBusinessModal({
      ...addNewBusinessModal,
      open: false,
    });
    setValue("newBusiness", {
      businessName: addNewBusinessModal.businessName,
      accountName: addNewBusinessModal.accountName,
      email: addNewBusinessModal.email,
    });
    setSelectedExternalBusiness(null);
    console.log("Add New Business Modal: ", addNewBusinessModal);
  };

  const watchedValues = useWatch({ control });

  useEffect(() => {
    if (
      currentOrderStatus === OrderStatusEnum.DRAFT ||
      currentOrderStatus === OrderStatusEnum.AWAITING_RESPONSE
    ) {
      const interval = setInterval(() => {
        const currentValues: any = watchedValues;

        /** Check only if there are changes in order form */
        if (
          JSON.stringify(currentValues) !==
          JSON.stringify(prevFormValuesRef.current)
        ) {
          prevFormValuesRef.current = currentValues;
          handleAutoDraftSave(currentValues);
          // handleSubmit(handleAutoDraftSave)();
        }
      }, 30000); // Autosave every 30 seconds
      // }, 3000); // Autosave every 3 seconds (testing purpose)

      return () => clearInterval(interval);
    }
  }, [currentOrderStatus, watchedValues]);

  // Function to focus on the first field with an error
  useEffect(() => {
    // const firstErrorField = Object.keys(errors)[0];
    const firstErrorField = Object.keys(errors).find(
      (key) => (errors as any)[key]
    );
    console.log(
      "Use Effect Error Current Field ::",
      firstErrorField,
      prevFocusedError.current
    );
    if (
      JSON.stringify(firstErrorField) !==
      JSON.stringify(prevFocusedError.current)
    ) {
      (
        document.querySelector(
          `input[name="${firstErrorField}"]`
        ) as HTMLInputElement | null
      )?.focus();

      prevFocusedError.current = firstErrorField;
    }
  }, [Object.keys(errors)]);

  return (
    <FormProvider {...methods}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            py: 2,
            display: "grid",
            gap: 4,
            gridTemplateColumns: { xs: "1fr", md: "1fr 30%" },
          }}
        >
          {/** Main Column */}
          <Box>
            <Tabs
              aria-label="transactions"
              sx={{
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
                backgroundColor: "transparent",
              }}
              onChange={(_event, newValue) => {
                handleChangeOrderType(newValue);
              }}
              value={orderType}
            >
              <TabList
                sx={{
                  [`&& .${tabClasses.root}`]: {
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                    [`&.${tabClasses.selected}`]: {
                      "&::after": {
                        bgcolor: "primary.500",
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                        height: 2,
                      },
                      color: "primary.plainColor",
                    },
                    bgcolor: "transparent",
                    flex: "initial",
                    paddingY: 2,
                  },
                  justifyContent: "space-evenly",
                }}
              >
                <Tab
                  value={"sales"}
                  sx={{ fontWeight: "600" }}
                  disabled={
                    currentOrderStatus !== OrderStatusEnum.DRAFT &&
                    orderType === "purchase"
                  }
                >
                  {t("order.createOrder.sales")}
                </Tab>
                <Tab
                  value={"purchase"}
                  sx={{ fontWeight: "600" }}
                  disabled={
                    currentOrderStatus !== OrderStatusEnum.DRAFT &&
                    orderType === "sales"
                  }
                >
                  {t("order.createOrder.purchase")}
                </Tab>
              </TabList>

              {/** Mobile Order Summary */}
              <Stack display={{ xs: "block", md: "none" }} spacing={3} my={4}>
                {/* Order Summary */}
                <OrderSummary
                  orderType={orderType}
                  invoiceNumber={invoiceNumber}
                  currentOrderStatus={currentOrderStatus}
                  handleGeneratePdf={handleGeneratePdf}
                  isReadOnly={isReadOnly}
                  autoSaveLoading={autoSaveLoading}
                />
              </Stack>

              {/* "Sales" Tab Content */}
              <TabPanel value={"sales"} sx={{ px: 0 }}>
                <SalesOrderForm
                  internalAccountList={internalAccountList}
                  selectedInternalAccount={selectedInternalAccount}
                  externalBusinessList={externalBusinessList}
                  selectedExternalBusiness={selectedExternalBusiness}
                  currencyList={currencyList}
                  handleOnChangeInternalAccount={handleOnChangeInternalAccount}
                  handleOnChangeExternalBusiness={
                    handleOnChangeExternalBusiness
                  }
                  handleEditNewBusiness={handleEditNewBusiness}
                  handleRemoveNewBusiness={handleRemoveNewBusiness}
                  handleUpdateCompanyInfo={handleUpdateCompanyInfo}
                  isReadOnly={isReadOnly}
                />
              </TabPanel>

              {/* "Purchase" Tab Content */}
              <TabPanel value={"purchase"} sx={{ px: 0 }}>
                <PurchaseOrderForm
                  internalAccountList={internalAccountList}
                  externalBusinessList={externalBusinessList}
                  selectedInternalAccount={selectedInternalAccount}
                  selectedExternalBusiness={selectedExternalBusiness}
                  currencyList={currencyList}
                  handleOnChangeInternalAccount={handleOnChangeInternalAccount}
                  handleOnChangeExternalBusiness={
                    handleOnChangeExternalBusiness
                  }
                  handleEditNewBusiness={handleEditNewBusiness}
                  handleRemoveNewBusiness={handleRemoveNewBusiness}
                  handleUpdateCompanyInfo={handleUpdateCompanyInfo}
                  isReadOnly={isReadOnly}
                />
              </TabPanel>
            </Tabs>

            {/* Section - Delivery Info */}
            <Stack spacing={4} mt={4}>
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    onClick={() => setOpen(!open)}
                    sx={sxClasses}
                  >
                    <Typography
                      startDecorator={<LocalShippingOutlined />}
                      fontWeight={600}
                    >
                      {t("order.createOrder.deliveryForm.addDeliveryInfo")}
                    </Typography>
                    <Add />
                  </Stack>
                )}
              >
                <Stack gap={3}>
                  <Stack direction={"row"} gap={3}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.deliveryDate")}
                      </FormLabel>
                      <Input
                        type="date"
                        aria-label="Date"
                        {...register("deliveryDate")}
                        error={!!errors.deliveryDate}
                        disabled={isReadOnly}
                      />
                    </FormControl>

                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.shippingMethod")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.shippingMethod"
                        )}
                        defaultValue={""}
                        {...register("shippingMethod")}
                        disabled={isReadOnly}
                      />
                    </FormControl>

                    {/* <Controller
                      name="shippingMethod"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControl sx={{ flex: "1" }}>
                          <FormLabel>
                            {t("order.createOrder.deliveryForm.shippingMethod")}
                          </FormLabel>
                          <Select
                            ref={ref}
                            size="sm"
                            placeholder={t(
                              "order.createOrder.deliveryForm.shippingMethod"
                            )}
                            slotProps={{
                              button: { sx: { whiteSpace: "nowrap" } },
                            }}
                            onChange={(_event, newValue: string | null) => {
                              // setValue("shippingMethod", newValue);
                              onChange(newValue);
                            }}
                            onBlur={onBlur}
                            value={value}
                            disabled={isReadOnly}
                          >
                            <Option value="EXPRESS">Express</Option>
                            <Option value="GROUND">Ground</Option>
                          </Select>
                        </FormControl>
                      )}
                    /> */}
                  </Stack>

                  <Stack direction={"row"} gap={3}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.vehicleNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.vehicleNumber"
                        )}
                        defaultValue={""}
                        {...register("vehicleNumber")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.trackingNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.trackingNumber"
                        )}
                        defaultValue={""}
                        {...register("trackingNumber")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Stack>

                  <FormControl>
                    <FormLabel>
                      {t("order.createOrder.deliveryForm.driverName")}
                    </FormLabel>
                    <Input
                      placeholder={t(
                        "order.createOrder.deliveryForm.driverName"
                      )}
                      defaultValue={""}
                      {...register("driverName")}
                      disabled={isReadOnly}
                    />
                  </FormControl>
                </Stack>
              </Toggler>
              <Divider />
            </Stack>

            <Stack spacing={4} mt={4}>
              {/** Section - Add Product & Service */}
              <ProductAndService
                nationalTaxes={nationalTaxes}
                productSearchList={productSearchList}
                isReadOnly={isReadOnly}
              />

              {/** Part - Grand Total Amount */}
              <GrandTotal isReadOnly={isReadOnly} />

              <Divider />
            </Stack>

            <Stack direction={"row"} alignItems={"center"} gap={3} mt={4}>
              {(() => {
                switch (currentOrderStatus) {
                  case OrderStatusEnum.DRAFT:
                    return (
                      <>
                        <Button
                          variant="plain"
                          sx={{
                            alignSelf: "start",
                            color: "var(--joy-palette-danger-500)",
                            "&:hover": {
                              backgroundColor: "var(--joy-palette-danger-100)",
                            },
                          }}
                          onClick={() => {
                            handleDeleteDraftOrder();
                          }}
                        >
                          {t("order.createOrder.deleteDraftBtn")}
                        </Button>

                        <Button
                          variant="outlined"
                          sx={{ marginLeft: "auto" }}
                          onClick={handleDraftSave}
                        >
                          {t("commons.saveBtn")}
                        </Button>
                        <Button
                          onClick={(e) => {
                            handleSendSave(e);
                          }}
                        >
                          {t("commons.submitBtn")}
                        </Button>
                      </>
                    );
                  case "AWAITING_RESPONSE":
                    return (
                      <>
                        {/** Show this only if the current user is buyer side (purchase) */}
                        {orderType === "purchase" && (
                          <>
                            <Button
                              variant="plain"
                              sx={{
                                alignSelf: "start",
                                color: "var(--joy-palette-primary-500)",
                                "&:hover": {
                                  backgroundColor:
                                    "var(--joy-palette-primary-100)",
                                },
                              }}
                              onClick={handleCancel}
                            >
                              {t("order.createOrder.cancelOrderBtn")}
                            </Button>

                            {showBtnConfirmOrderByBuyer ? (
                              <Button
                                variant="solid"
                                sx={{ marginLeft: "auto" }}
                                onClick={(e) => {
                                  handleConfirm(e);
                                }}
                              >
                                {t("order.createOrder.acceptForSellerBtn")}
                              </Button>
                            ) : (
                              <Typography sx={{ marginLeft: "auto" }}>
                                {t("order.createOrder.waitingResponseBtn")}
                              </Typography>
                            )}
                          </>
                        )}

                        {/** Show this only if the current user is seller side (sales) */}
                        {orderType === "sales" && (
                          <>
                            <Button
                              variant="plain"
                              sx={{
                                alignSelf: "start",
                                color: "var(--joy-palette-primary-500)",
                                "&:hover": {
                                  backgroundColor:
                                    "var(--joy-palette-primary-100)",
                                },
                              }}
                              onClick={handleCancel}
                            >
                              {t("order.createOrder.cancelOrderBtn")}
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ marginLeft: "auto" }}
                              onClick={handleDraftSave}
                            >
                              {t("commons.saveBtn")}
                            </Button>
                            <Button
                              variant="solid"
                              onClick={(e) => {
                                handleConfirm(e);
                              }}
                            >
                              {t("order.createOrder.confirmOrderBtn")}
                            </Button>
                          </>
                        )}
                      </>
                    );
                  case "PENDING_PAYMENT":
                    return (
                      <>
                        {orderType === "purchase" &&
                          paymentInfo &&
                          paymentInfo.totalAmount &&
                          (Date.now() >
                          Date.parse(paymentInfo.expirationDate) ? (
                            <Stack
                              sx={{
                                width: "100%",
                              }}
                              direction={"row"}
                              gap={3}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Chip color="danger">
                                Your payment is expired
                              </Chip>
                              <Button
                                variant="solid"
                                sx={{
                                  marginLeft: "auto",
                                }}
                                onClick={() => setOpenPaymentModal(true)}
                              >
                                {t("order.createOrder.makePaymentBtn")}
                              </Button>
                            </Stack>
                          ) : (
                            <Button
                              variant="plain"
                              sx={{
                                marginLeft: "auto",
                              }}
                              onClick={() => setShowPaymentInfoModal(true)}
                            >
                              View Payment Info
                            </Button>
                          ))}
                        {orderType === "purchase" &&
                          !(paymentInfo && paymentInfo.totalAmount) && (
                            <Button
                              variant="solid"
                              sx={{
                                marginLeft: "auto",
                              }}
                              onClick={() => setOpenPaymentModal(true)}
                            >
                              {t("order.createOrder.makePaymentBtn")}
                            </Button>
                          )}

                        {orderType === "sales" && (
                          <>
                            <Button
                              variant="plain"
                              sx={{
                                alignSelf: "start",
                                color: "var(--joy-palette-primary-500)",
                                "&:hover": {
                                  backgroundColor:
                                    "var(--joy-palette-primary-100)",
                                },
                              }}
                              onClick={handleCancel}
                            >
                              {t("order.createOrder.cancelOrderBtn")}
                            </Button>
                            <Typography sx={{ marginLeft: "auto" }}>
                              {t("order.createOrder.waitingForPayment")}
                            </Typography>
                            <Button
                              // type="submit"
                              variant="solid"
                              // value="complete"
                              onClick={handleComplete}
                            >
                              {t("order.createOrder.completeOrderBtn")}
                            </Button>
                          </>
                        )}
                      </>
                    );
                  case "PROCESSING":
                    return (
                      <>
                        {/** Show this only if the current user is seller side (sales) */}
                        {orderType === "sales" ? (
                          <Button
                            variant="solid"
                            sx={{ marginLeft: "auto" }}
                            onClick={handleComplete}
                          >
                            {t("order.createOrder.completeOrderBtn")}
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  default:
                    return "";
                }
              })()}
            </Stack>
          </Box>

          {/** Right Sidebar Column - Laptop & Desktop */}
          <Box
            alignSelf={"start"}
            position={"sticky"}
            top={24}
            display={{ xs: "none", md: "grid" }}
            justifyContent={"stretch"}
            alignContent={"start"}
            gap={3}
          >
            {/** Order Summary */}
            <OrderSummary
              orderType={orderType}
              invoiceNumber={invoiceNumber}
              currentOrderStatus={currentOrderStatus}
              handleGeneratePdf={handleGeneratePdf}
              autoSaveLoading={autoSaveLoading}
            />
          </Box>
        </Box>

        {/** Modals */}
        <AddNewBusinessModal
          modalData={addNewBusinessModal}
          setModalData={setAddNewBusinessModal}
          handleCancel={handleCancelAddNewBusinessModal}
          handleSubmit={handleSubmitAddNewBusinessModal}
        />
        <PaymentModal
          open={openPaymentModal}
          setOpenPaymentModal={setOpenPaymentModal}
          handleContinue={handlePaymentContinue}
          handleSkip={handlePaymentSkip}
          control={control}
          setValue={setValue}
          onlinePaymentOnly={false}
          paymentAmount={getValues("grandTotalAmount")}
          paymentCurrency={getValues("currency") ?? ""}
        />
        <ConfirmationModal
          title={confirmationModal.title}
          content={confirmationModal.content}
          open={confirmationModal.open}
          btnConfirmText={confirmationModal.btnConfirmText}
          onCancel={confirmationModal.onCancel}
          onConfirm={confirmationModal.onConfirm}
        />
        {paymentInfo && paymentInfo && (
          <PaymentInfoModal
            open={showPaymentInfoModal}
            setOpenModal={setShowPaymentInfoModal}
            paymentInfo={paymentInfo}
            currency={getValues("currency") ?? ""}
          />
        )}
        <SuccessModal
          open={successModal.open}
          title={successModal.title}
          content={successModal.content}
        />
        <LoadingModal isLoading={loading} />
      </form>
    </FormProvider>
  );
}
