import {
  ClipboardDocumentIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import {
  Add,
  CheckCircle,
  CheckCircleOutline,
  CloudDoneOutlined,
  CloudUploadOutlined,
  CopyAllOutlined,
  EventRepeatOutlined,
  Remove,
  ShareOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from "@mui/joy";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  OrderStatusEnum,
  orderStatuses,
  PaymentFlowTypeEnum,
} from "../../types/order";
import { OrderTypeTag, Toggler } from "../utils";
import toast from "react-hot-toast";
import { showSuccessToast, SuccessToastConfig } from "../Error";
import dayjs from "dayjs";
import { formatDate } from "../../utils/formatDate";
import { theme } from "../Theme";

const OrderSummary = ({
  orderType,
  invoiceNumber,
  currentOrderStatus,
  handleGeneratePdf,
  autoSaveLoading,
  paymentLink,
  revisions,
  paymentInfo,
  latestOrderUpdatedAt,
}: any) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();

  const isPendingPaymentStatusSkipped = (status: any) => {
    return (
      status.value === OrderStatusEnum.PENDING_PAYMENT &&
      (!getValues("downPayment") ||
        getValues("downPayment") >= getValues("grandTotalAmount"))
    );
  };

  const isPendingFinalPaymentStatusSkipped = (status: any) => {
    return (
      status.value === OrderStatusEnum.PENDING_FINAL_PAYMENT &&
      getValues("paymentFlowType") === PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
    );
  };

  const isCancelledStatusSkipped = (status: any) => {
    return (
      status.value === OrderStatusEnum.CANCELLED &&
      currentOrderStatus !== OrderStatusEnum.CANCELLED
    );
  };

  const isProcessStatusSkipped = (status: any) => {
    return (
      status.value == OrderStatusEnum.PROCESSING &&
      [
        PaymentFlowTypeEnum.RECURRING_PAYMENT,
        PaymentFlowTypeEnum.MULTIPLE_PAYMENTS,
      ].includes(getValues("paymentFlowType")) &&
      getValues("paymentFlowType") !== PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
    );
  };

  const isCompleteStatusSkipped = (status: any) => {
    return (
      status.value === OrderStatusEnum.COMPLETE &&
      currentOrderStatus === OrderStatusEnum.CANCELLED
    );
  };

  const OrderStatusStepper = () => {
    return (
      <Stepper
        orientation="vertical"
        sx={{
          display: "flex",
          rowGap: "4px",
          color: "var(--joy-palette-neutral-400)",
          "& .Mui-active": { color: "var(--joy-palette-primary-500)" },
        }}
      >
        {orderStatuses.map((status, index) => {
          // get the index number of the currentOrderStatus (Backend status)
          let currentOrderStatusIndex = orderStatuses.findIndex((s) =>
            s.backendStatuses.includes(currentOrderStatus)
          );
          let stepElement;
          if (
            isPendingPaymentStatusSkipped(status) ||
            isPendingFinalPaymentStatusSkipped(status) ||
            isCancelledStatusSkipped(status) ||
            isProcessStatusSkipped(status) ||
            isCompleteStatusSkipped(status)
          ) {
            stepElement = (
              <>
                {/**
                 * Skip the Statuses:
                 * - Pending Payment (Conditional)
                 * - Pending Final Payment (Conditional)
                 * - Cancelled (Not necessary to show it in normal Complete flow)
                 *  */}
              </>
            );
          } else {
            stepElement = (
              <Step
                key={index}
                {...(index <= currentOrderStatusIndex ? { active: true } : "")}
                indicator={
                  <StepIndicator
                    variant="plain"
                    sx={{
                      color: "var(--joy-palette-neutral-400)",
                      ".Mui-active &": {
                        color: "var(--joy-palette-primary-500)",
                      },
                      "& svg": {
                        fontSize: "26px",
                      },
                    }}
                  >
                    {index <= currentOrderStatusIndex ? (
                      <CheckCircle />
                    ) : (
                      <CheckCircleOutline />
                    )}
                  </StepIndicator>
                }
                sx={{
                  minHeight: "52px",
                  rowGap: "0",
                  fontWeight: "600",
                }}
              >
                {status.label}
              </Step>
            );
          }
          return stepElement;
        })}
      </Stepper>
    );
  };
  console.log("paymentLink", paymentLink);
  const recurringDay = getValues("recurringDay");
  const startMonthYear = dayjs(getValues("startMonth"));
  const today = dayjs();
  const concatDayMonthYear = recurringDay && startMonthYear.date(recurringDay);

  const PaymentInfoElement = () => {
    const paymentMethodLabel = paymentInfo?.paymentMethod?.replace("-", " ");
    const currentPaymentType = getValues("paymentFlowType");

    return currentPaymentType !== PaymentFlowTypeEnum.SPLIT_PAYMENT ? (
      <Stack
        direction={"column"}
        sx={{
          gap: "0.5rem",
          width: "100%",
          px: { xs: "1.25rem", lg: "1.5rem" },
          color: theme.palette.others.secondary_text,
          mt: "1.5rem",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography fontSize={12} fontWeight={500}>
            {currentPaymentType === PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
              ? "Upfront Payment"
              : "Payment Time"}
          </Typography>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: 12,
              fontWeight: 500,
              textAlign: "right",
            }}
          >
            {formatDate(new Date(paymentInfo?.updatedAt))}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography fontSize={12} fontWeight={500}>
            Paid by
          </Typography>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: 12,
              fontWeight: 500,
              textAlign: "right",
            }}
          >
            {paymentMethodLabel === "va"
              ? "Virtual Account"
              : paymentMethodLabel}
          </Typography>
        </Stack>
        {currentOrderStatus === OrderStatusEnum.COMPLETE && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography fontSize={12} fontWeight={500}>
              Completed Time
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: 500,
                textAlign: "right",
              }}
            >
              {formatDate(new Date(latestOrderUpdatedAt))}
            </Typography>
          </Stack>
        )}
      </Stack>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <OrderTypeTag type={orderType} />
        <FormControl sx={{ flex: "1" }}>
          <FormLabel>{t("order.createOrder.summary.orderNumber")}</FormLabel>
          {/* Generate Invoice Number Once Awaiting Response occurred */}
          <Typography
            fontWeight={600}
            endDecorator={
              getValues("paymentFlowType") ===
              PaymentFlowTypeEnum.RECURRING_PAYMENT ? (
                <EventRepeatOutlined
                  color="primary"
                  sx={{ marginLeft: "4px" }}
                />
              ) : revisions?.length > 0 ? (
                <div className="rounded-lg bg-[#F7D5AC] w-[24px] h-[24px] flex justify-center items-center ml-3">
                  <DocumentDuplicateIcon
                    width={16}
                    height={18}
                    color="#D48434"
                  />
                </div>
              ) : (
                ""
              )
            }
          >
            {invoiceNumber || OrderStatusEnum.DRAFT}
          </Typography>
        </FormControl>
      </Stack>

      {autoSaveLoading &&
        (autoSaveLoading ? (
          <Typography
            startDecorator={<CloudUploadOutlined />}
            fontWeight={600}
            pl={0.6}
          >
            {t("order.createOrder.summary.draftSaving")}
          </Typography>
        ) : (
          <Typography
            color="primary"
            startDecorator={<CloudDoneOutlined />}
            fontWeight={600}
            pl={0.6}
          >
            {t("order.createOrder.summary.draftSaved")}
          </Typography>
        ))}

      {handleGeneratePdf && currentOrderStatus !== OrderStatusEnum.DRAFT && (
        <Box
          display={"flex"}
          gap={2}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Button
            variant="outlined"
            startDecorator={<ShareOutlined />}
            onClick={handleGeneratePdf}
          >
            {t("order.createOrder.summary.sharePdfBtn")}
          </Button>
          {paymentLink &&
            (!(
              getValues("paymentFlowType") ===
                PaymentFlowTypeEnum.RECURRING_PAYMENT &&
              today.isBefore(concatDayMonthYear)
            ) ||
              getValues("paymentFlowType") !==
                PaymentFlowTypeEnum.RECURRING_PAYMENT) &&
            getValues("paymentFlowType") !==
              PaymentFlowTypeEnum.MULTIPLE_PAYMENTS && (
              <Button
                startDecorator={<CopyAllOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(paymentLink?.url);
                  toast(
                    showSuccessToast(
                      t("order.createOrder.summary.paymentLinkCopied")
                    ),
                    SuccessToastConfig
                  );
                }}
              >
                {t("order.createOrder.summary.copyPaymentLinkBtn")}
              </Button>
            )}
        </Box>
      )}

      <Divider />

      {/** Order Status Stepper - Mobile & Tablet */}
      <Stack display={{ xs: "flex", md: "none" }}>
        <Toggler
          renderToggle={({ open, setOpen, sxClasses }) => (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              onClick={() => setOpen(!open)}
              width={{ xs: "90vw", md: "100%" }}
              sx={sxClasses}
            >
              <Typography
                startDecorator={
                  <ClipboardDocumentIcon width={24} height={24} />
                }
                fontWeight={600}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {currentOrderStatus.toLowerCase()}
              </Typography>
              {open ? <Remove color="primary" /> : <Add color="primary" />}
            </Stack>
          )}
        >
          <Stack mt={4}>
            <OrderStatusStepper />

            <Divider
              sx={{
                mt: "1.5rem",
              }}
            />
            <PaymentInfoElement />
            <Divider
              sx={{
                mt: "1.5rem",
              }}
            />
          </Stack>
        </Toggler>
      </Stack>

      {/** Order Status Stepper - Laptop & Desktop */}
      <Stack gap={2} display={{ xs: "none", md: "flex" }}>
        <Stack justifyContent={"space-between"}>
          <Typography
            startDecorator={<ClipboardDocumentIcon width={24} height={24} />}
            fontWeight={600}
            sx={{
              textTransform: "capitalize",
            }}
          >
            {t("order.createOrder.summary.orderStatus")}
          </Typography>
        </Stack>
        <OrderStatusStepper />
      </Stack>
      <Divider />

      {/* <Toggler
        renderToggle={({ open, setOpen, sxClasses }) => (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            onClick={() => setOpen(!open)}
            sx={sxClasses}
          >
            <Typography startDecorator={<NewspaperOutlined />} fontWeight={600}>
              {t("order.createOrder.summary.remarksLabel")}
            </Typography>
            <KeyboardArrowDownOutlined
              sx={{ transform: open ? "rotate(180deg)" : "none" }}
            />
          </Stack>
        )}
      >
        <Stack rowGap={4}>
          <Controller
            name="remarks"
            control={control}
            defaultValue={""}
            render={({ field: { name, ref, onChange, onBlur, value } }) => (
              <FormControl ref={ref}>
                <Textarea
                  placeholder={t(
                    "order.createOrder.summary.remarksPlaceholder"
                  )}
                  minRows={4}
                  name={name}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  disabled={isReadOnly}
                />
              </FormControl>
            )}
          />
        </Stack>
      </Toggler>
      <Divider /> */}
      {/* <Toggler
        renderToggle={({ open, setOpen, sxClasses }) => (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            onClick={() => setOpen(!open)}
            sx={sxClasses}
          >
            <Typography startDecorator={<TextsmsOutlined />} fontWeight={600}>
              Chats
            </Typography>
            <KeyboardArrowDownOutlined
              sx={{ transform: open ? "rotate(180deg)" : "none" }}
            />
          </Stack>
        )}
      >
        <Stack rowGap={4}>
          <FormControl>
            <Input placeholder="Type Chat" />
          </FormControl>
          <Box>
            <Button variant="outlined">Post Chat</Button>
          </Box>
          <Typography mx={"auto"}>There is no chat history.</Typography>
        </Stack>
      </Toggler> */}
    </>
  );
};

export default OrderSummary;
