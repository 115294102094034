import { H } from "highlight.run";
import mixpanel from "mixpanel-browser";

export class TrackerService {
  static identified = false;
  static init() {
    if (import.meta.env.VITE_MIXPANEL_DISABLED !== "true") {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {
        debug: true,
        track_pageview: false,
        persistence: "localStorage",
      });
    }
    H.init(import.meta.env.VITE_LOGGER_PROJECT_ID, {
      serviceName: "linkz-app",
      tracingOrigins: [new URL(import.meta.env.VITE_API_URL).origin],
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        disableWebSocketEventRecordings: true,
        urlBlocklist: [
          // insert full or partial urls that you don't want to record here
          // Out of the box, Highlight will not record these URLs (they can be safely removed):
          "https://www.googleapis.com/identitytoolkit",
          "https://securetoken.googleapis.com",
          "https://www.google-analytics.com",
          "https://fonts.googleapis.com",
          "https://cdn.jsdelivr.net",
        ],
      },
    });
  }

  // force is used to force identify even if already identified
  static identify(email: string, payload?: any, force?: boolean) {
    console.log(
      "TRACKER SERVICE IDENTIFY",
      email,
      TrackerService.identified,
      force
    );
    if (!TrackerService.identified || force) {
      TrackerService.identified = true;
      mixpanel.identify(email);
      mixpanel.people.set(payload);
      H.identify(email, payload);
    }
  }

  static track(event: string, payload?: any) {
    console.log("TRACKER SERVICE TRACK", event);
    mixpanel.track(event, payload);
    H.track(event, payload);
  }

  static stop() {
    TrackerService.identified = false;
    H.stop();
  }
}
