import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/joy";
import useContainer from "./useContainer";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
} from "../../../../utils/constants";
import { matchesFormat } from "../../../../utils/misc";
import { useTranslation } from "react-i18next";

import "react-phone-input-2/lib/high-res.css";

const PhoneSignupForm = () => {
  const { t } = useTranslation();

  const {
    errors,
    phoneNo,
    setError,
    setValue,
    isLoading,
    isValid,
    agreed,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
    register,
  } = useContainer();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full gap-y-4 px-4"
    >
      <Stack direction={"row"} gap={2} width={"100%"}>
        <FormControl error={errors.mobile ? true : false} className="w-full">
          {/* <FormLabel>
            <Typography
              fontSize={"sm"}
              fontWeight={500}
              className={`transition-opacity duration-300 ${
                mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
              }`}
              textColor={"text.secondary"}
            >
              {t("landing.signup.phoneNumber")}
            </Typography>
          </FormLabel> */}
          <PhoneInput
            inputProps={{
              name: "input-phone",
            }}
            value={phoneNo}
            onlyCountries={PhNoWhiteListedCountries}
            masks={{
              my: "..-....-....",
              sg: "....-....",
              th: "... ... ...",
              id: "... ... ... ...",
            }}
            enableTerritories={false}
            country={"id"}
            isValid={(inputNumber, meta: any, countries) => {
              if (inputNumber === "") {
                return true;
              }
              const result = (countries || []).some((country: any) => {
                if (
                  startsWith(inputNumber, country.dialCode) ||
                  startsWith(country.dialCode, inputNumber)
                ) {
                  const iso2: CountryCode = meta.iso2 as CountryCode;
                  const format = PhNoFormat[iso2];
                  if (!format) return false;
                  const isMatch = matchesFormat(
                    inputNumber,
                    format,
                    country.countryCode
                  );

                  return isMatch;
                }
                return false;
              });

              if (!result && !errors.mobile?.message) {
                setError("mobile", {
                  message: t("commons.error.form.mobileInvalid"),
                });
              } else if (result && !!errors.mobile?.message) {
                if (errors.mobile.type === "manual") {
                  return false;
                }
                clearErrors("mobile");
                trigger("mobile");
              }
              return result;
            }}
            inputStyle={{
              height: "56px",
              width: "100%",
              fontSize: "14px",
              fontWeight: 500,
              borderRadius: "8px",
              paddingLeft: "5.5rem",
            }}
            buttonStyle={{
              padding: "0 1rem",
              borderRadius: "8px 0 0 8px",
            }}
            onChange={(phone, meta: any) => {
              setValue("country.code", meta.dialCode);
              setValue("country.label", meta.name);
              setValue(
                "country.dial",
                phone.replace(new RegExp(meta.dialCode), "")
              );
              setValue("mobile", phone.replace(new RegExp(meta.dialCode), ""));

              if (errors.mobile?.type === "manual") {
                clearErrors("mobile");
              }
            }}
          />

          {errors.mobile?.message && (
            <FormHelperText>{errors.mobile?.message}</FormHelperText>
          )}
        </FormControl>
      </Stack>

      <FormControl size="md">
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            width: "100%",
            gap: "12px",
            py: "8px",
          }}
        >
          <Checkbox
            {...register("agreed")}
            required
            sx={{
              alignSelf: "start",
            }}
          />
          <Typography fontSize={14} fontWeight={400}>
            {t("landing.signup.acceptTerms")}
            <span
              // onClick={() => setShowTermsModal(true)}
              style={{
                margin: "0 3px",
                textDecoration: "none",
              }}
            >
              <a href={`${import.meta.env.VITE_PP_URL ?? "#"}`} target="_blank">
                <span className={"text-[#499873] cursor-pointer font-bold"}>
                  {t("landing.signup.terms")}
                </span>
              </a>
            </span>
            &
            <span
              // onClick={() => setPrivacyPolicy(true)}
              style={{
                marginLeft: "3px",
                textDecoration: "none",
              }}
            >
              <a href={`${import.meta.env.VITE_PP_URL ?? "#"}`} target="_blank">
                <span className={"text-[#499873] cursor-pointer font-bold"}>
                  {t("landing.signup.privacy")}
                </span>
              </a>
            </span>
            .
          </Typography>
        </Stack>
      </FormControl>

      <Button
        id="btn-create"
        className="mx-auto"
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", lg: "400px" },
          mt: "1rem",
        }}
        fullWidth
        size="lg"
        type="submit"
        disabled={isLoading || !isValid || !agreed}
      >
        {t("commons.createBtn")}
      </Button>
    </form>
  );
};

export default PhoneSignupForm;
