import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import {
  CheckCircle,
  KeyboardArrowDown,
  ViewSidebarOutlined,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Link,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useQueryClient } from "@tanstack/react-query";
import "flag-icons/css/flag-icons.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LogoImg from "../assets/linkz-logo-small.png";
import { BusinessSessionHelper } from "../helpers/business-session.helper";
import { BusinessService } from "../services/business.service";
import { animateSelectStyle } from "../styles/select";
import { IBusinessResponse } from "../types/business";
import FlagById from "./FlagById";
import HamburgerIcon from "./Icons/Hamburger";
import LanguageSelector from "./LanguageSelector";
import { openSidebar } from "./utils";
import KycSnackBar from "./kyc/KycSnackBar";
interface Props {
  paths: {
    name: string;
    path: string;
  }[];
}

const BreadcrumbHeader = ({ paths }: Props) => {
  const { t } = useTranslation();
  const [availableBusinesses, setAvailableBusinesses] = useState<
    IBusinessResponse[]
  >([]);
  const client = useQueryClient();
  const [activeBusiness, setActiveBusiness] = useState<IBusinessResponse>();
  const getAvailableBusinesses = () => {
    BusinessService.getBusiness({ paging: false }).then((data) => {
      setAvailableBusinesses(data);
      const activeBusiness = data.find(
        (b) => b.id === BusinessSessionHelper.GetBusinessId()
      );
      if (activeBusiness) setActiveBusiness(activeBusiness);
    });
  };
  useEffect(() => {
    getAvailableBusinesses();
  }, []);
  const { pathname } = useLocation();
  const isDashboardRoute = ["/client", "/client/"].includes(pathname);
  return (
    <div className="flex flex-col">
      <Stack
        direction={"row"}
        // justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
        }}
      >
        <span className="hidden xl:block">
          <ViewSidebarOutlined />
        </span>

        {/* <div className="min-[900px]:flex items-center hidden"> */}
        <div className="items-center hidden xl:flex">
          <Breadcrumbs
            aria-label="breadcrumbs"
            sx={{
              marginLeft: "1rem",
            }}
          >
            {paths.map((p, index) => {
              if (index === paths.length - 1) {
                return (
                  <Typography
                    key={p.name}
                    sx={{
                      marginLeft: "1rem",
                    }}
                    className="text-[#499873]"
                  >
                    {t(`breadcrumb.${p.name}`)}
                  </Typography>
                );
              }

              return (
                <Link
                  key={p.name}
                  color="neutral"
                  href={p.path}
                  sx={{
                    marginX: "1rem",
                  }}
                >
                  {t(`breadcrumb.${p.name}`)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
        <img
          src={LogoImg}
          alt="linkz-logo"
          width={84}
          height={40}
          className={"mr-auto xl:hidden"}
        />
        <div className="flex items-center justify-between gap-4 ml-auto">
          <Select
            className={`${
              isDashboardRoute ? "hidden lg:flex" : "hidden"
            } border border-[#98A2B3] text-sm `}
            key={activeBusiness?.id}
            value={activeBusiness}
            onChange={async (_, value) => {
              if (!value) return;
              await BusinessSessionHelper.SetActiveBusiness(
                value as IBusinessResponse
              );
              setActiveBusiness(value as IBusinessResponse);
              client.invalidateQueries({
                queryKey: ["business-credits", "kyc-payment-gateway-status"],
              });
            }}
            renderValue={(option) => (
              <Typography
                fontWeight={600}
                mr={"auto"}
                className="text-sm"
                startDecorator={
                  <span
                    className={`fi fi-${activeBusiness?.companyCountry.toLocaleLowerCase()} shadow-md`}
                  ></span>
                }
              >
                {option?.label}
              </Typography>
            )}
            variant="plain"
            size="sm"
            indicator={<KeyboardArrowDown />}
            sx={{ ...animateSelectStyle, backgroundColor: "white" }}
          >
            {availableBusinesses
              ?.sort(
                (a, b) =>
                  (a.id === activeBusiness?.id ? 0 : 1) -
                  (b.id === activeBusiness?.id ? 0 : 1)
              )
              .map((business) => (
                <Option
                  key={business.id}
                  value={business}
                  label={business.companyName}
                >
                  <Stack
                    sx={{ width: "100%" }}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={5}
                  >
                    <div>
                      <Typography
                        sx={{ flex: 1 }}
                        startDecorator={
                          <FlagById id={business.companyCountry} />
                        }
                      >
                        {business.companyName}
                      </Typography>
                      {business.id === activeBusiness?.id && (
                        <Typography fontSize={12} textColor="neutral.500">
                          {t("profile.business.currentLoggedInCompanyLabel")}
                        </Typography>
                      )}
                    </div>
                    {business.id === activeBusiness?.id && (
                      <CheckCircle color="primary" />
                    )}
                  </Stack>
                </Option>
              ))}
          </Select>
          <ShieldCheckIcon className="w-6 h-6 text-[#499873] hidden lg:block" />
          <LanguageSelector />
          <Box
            className="block cursor-pointer xl:hidden"
            onClick={() => openSidebar()}
          >
            <HamburgerIcon />
          </Box>
        </div>
      </Stack>
      <div className="flex items-center w-full gap-4 ml-auto lg:hidden">
        <Select
          className={`${
            ["/client", "/client/"].includes(pathname) ? "" : "hidden"
          } border border-[#98A2B3] text-sm flex-1`}
          key={activeBusiness?.id}
          value={activeBusiness}
          onChange={async (_, value) => {
            if (!value) return;
            await BusinessSessionHelper.SetActiveBusiness(
              value as IBusinessResponse
            );
            setActiveBusiness(value as IBusinessResponse);
            client.invalidateQueries({
              queryKey: ["business-credits", "kyc-payment-gateway-status"],
            });
          }}
          renderValue={(option) => (
            <Typography
              fontWeight={600}
              mr={"auto"}
              className="text-sm"
              startDecorator={
                <span
                  className={`fi fi-${activeBusiness?.companyCountry.toLocaleLowerCase()} shadow-md`}
                ></span>
              }
            >
              {option?.label}
            </Typography>
          )}
          variant="plain"
          size="sm"
          indicator={<KeyboardArrowDown />}
          sx={{ ...animateSelectStyle, backgroundColor: "white" }}
        >
          {availableBusinesses
            ?.sort(
              (a, b) =>
                (a.id === activeBusiness?.id ? 0 : 1) -
                (b.id === activeBusiness?.id ? 0 : 1)
            )
            .map((business) => (
              <Option
                key={business.id}
                value={business}
                label={business.companyName}
              >
                <Stack
                  sx={{ width: "100%" }}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={5}
                >
                  <div>
                    <Typography
                      sx={{ flex: 1 }}
                      startDecorator={<FlagById id={business.companyCountry} />}
                    >
                      {business.companyName}
                    </Typography>
                    {business.id === activeBusiness?.id && (
                      <Typography fontSize={12} textColor="neutral.500">
                        {t("profile.business.currentLoggedInCompanyLabel")}
                      </Typography>
                    )}
                  </div>
                  {business.id === activeBusiness?.id && (
                    <CheckCircle color="primary" />
                  )}
                </Stack>
              </Option>
            ))}
        </Select>
        <ShieldCheckIcon
          className={`w-6 h-6 text-[#499873] ${
            isDashboardRoute ? "block" : "hidden"
          }`}
        />
      </div>
      {!(
        window.location.href.endsWith("/profile") ||
        window.location.href.endsWith("/profile/kyc")
      ) &&
        !BusinessSessionHelper.IsValidatedKYCPaymentGateway() && (
          <KycSnackBar />
        )}
    </div>
  );
};

export default BreadcrumbHeader;
