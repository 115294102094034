import { Helmet } from "react-helmet";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import Shared from "../../layouts/Shared";
import useContainer from "./useContainer";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { If } from "../../components/Condition";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
  WhiteListedCountries,
} from "../../utils/constants";
import ReactFlagsSelect from "react-flags-select";
import { Controller } from "react-hook-form";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Password from "../../components/PasswordPolicy/PasswordPolicy";
import { Link as RouterLink } from "react-router-dom";
import Gauth from "../../components/Gauth";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import { matchesFormat } from "../../utils/misc";

const SignupCompanyProfile = () => {
  const { t } = useTranslation();

  const {
    // agreed,
    isValid,
    handleSubmit,
    onSubmit,
    onReferralCodeChange,
    register,
    toggleShowPassword,
    googleSignUpHandler,
    setError,
    clearErrors,
    trigger,
    errors,
    // trigger,
    setValue,
    isLoading,
    // countryName,
    country,
    password,
    showPassword,
    alrdyHasBusiness,
    token,
    signupMethod,
    control,
  } = useContainer();

  console.log("errors", errors);

  return (
    <>
      <Shared
        sxProps={{
          backgroundColor: "transparent",
        }}
        minWidth={"329px"}
        width={{
          md: "480px",
        }}
      >
        <Helmet
          link={[
            { href: "https://linkzasia.com/auth/register", rel: "canonical" },
          ]}
          meta={[{ content: "Register", name: "description" }]}
          title="Register - Linkz"
        />
        <Stack
          direction={"column"}
          flex={1}
          width={{ xs: "100%", lg: "480px" }}
          mx={"auto"}
        >
          <Box
            component="section"
            data-testid="register"
            id="register"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              py: "1rem",
            }}
          >
            <Typography
              textColor={"primary.solidActiveBg"}
              textAlign={"center"}
              fontSize={"18"}
              fontWeight={600}
              py={"1rem"}
            >
              {t("landing.companyInfoLabel")}
            </Typography>

            <Box
              component="section"
              data-testid="register"
              id="register"
              sx={{ py: "1rem" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  spacing={2}
                  alignItems={"start"}
                  justifyContent={"center"}
                  rowGap={"1rem"}
                >
                  <Grid xs={12} py={0}>
                    <FormControl error={!!errors.fullName}>
                      <If condition={!token}>
                        <FormLabel>
                          <Typography
                            className={`transition-opacity duration-300 opacity-100 h-auto`}
                            fontSize={"sm"}
                            fontWeight={400}
                            textColor={"text.secondary"}
                          >
                            {t("landing.signup.fullName")}
                          </Typography>
                        </FormLabel>
                      </If>
                      <Input
                        sx={{
                          fontSize: 14,
                          fontWeight: 600,
                          borderRadius: "8px",
                        }}
                        placeholder={t("landing.signup.fullName")}
                        size="lg"
                        required
                        {...register("fullName")}
                      />
                      {errors.fullName?.message ? (
                        <FormHelperText>
                          {errors.fullName?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <If
                    condition={(!!token && !alrdyHasBusiness) || !!!token}
                    children={
                      <Grid xs={12} py={0}>
                        <FormControl error={!!errors.companyName}>
                          <FormLabel>
                            <Typography
                              className={`transition-opacity duration-300 opacity-100 h-auto`}
                              fontSize={"sm"}
                              fontWeight={400}
                              textColor={"text.secondary"}
                            >
                              {t("landing.signup.companyName")}
                            </Typography>
                          </FormLabel>
                          <Input
                            sx={{
                              fontSize: 14,
                              fontWeight: 600,
                              borderRadius: "8px",
                            }}
                            placeholder={t("landing.signup.companyName")}
                            size="lg"
                            required={!!token}
                            {...register("companyName")}
                          />
                          {errors.companyName?.message ? (
                            <FormHelperText>
                              {errors.companyName?.message}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                    }
                  />
                  <If
                    condition={(!!token && !alrdyHasBusiness) || !!!token}
                    children={
                      <Grid xs={12} py={0}>
                        <Controller
                          name="countryName"
                          control={control}
                          rules={{ required: "Please select a country." }}
                          render={({ field: { onChange, value } }) => (
                            <FormControl error={!!errors.countryName}>
                              <FormLabel>
                                <Typography
                                  className={`transition-opacity duration-300 opacity-100 h-auto`}
                                  fontSize={"sm"}
                                  fontWeight={400}
                                  textColor={"text.secondary"}
                                >
                                  {t("landing.signup.country")}
                                </Typography>
                              </FormLabel>
                              <ReactFlagsSelect
                                id="select-flag"
                                placeholder="Select your country"
                                selected={value ?? ""}
                                onSelect={(code) => {
                                  onChange(code);
                                  setValue("countryName", code);
                                }}
                                selectButtonClassName="!p-2.5 !rounded-[8px] !text-sm font-semibold !text-[#555E68]"
                                countries={WhiteListedCountries}
                              />

                              {errors.countryName?.message && (
                                <FormHelperText>
                                  {errors.countryName?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                        {/* <FormControl error={!!errors.countryName}>
                          <FormLabel>
                            <Typography
                              className={`transition-opacity duration-300 opacity-100 h-auto`}
                              fontSize={"sm"}
                              fontWeight={400}
                              textColor={"text.secondary"}
                            >
                              {t("landing.signup.country")}
                            </Typography>
                          </FormLabel>
                          <ReactFlagsSelect
                            id={"select-flag"}
                            placeholder={t("landing.signup.country")}
                            selected={countryName ?? ""}
                            {...register("countryName")}
                            onSelect={(code) => {
                              setValue("countryName", code);
                            }}
                            selectButtonClassName="!p-2.5 !rounded-[8px] !text-sm font-semibold !text-[#555E68]"
                            countries={WhiteListedCountries}
                          />

                          {errors.countryName?.message && (
                            <FormHelperText>
                              {errors.countryName?.message}
                            </FormHelperText>
                          )}
                        </FormControl> */}
                      </Grid>
                    }
                  />
                  {/* <Grid xs={12} className="hidden">
                    <FormControl error={errors.mobile ? true : false}>
                      <FormLabel>
                        <Typography
                          fontSize={"sm"}
                          fontWeight={500}
                          className={`transition-opacity duration-300 ${
                            mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                          }`}
                          textColor={"text.secondary"}
                        >
                          {t("landing.signup.phoneNumber")}
                        </Typography>
                      </FormLabel>
                      <PhoneInput
                        inputProps={{
                          name: "input-phone",
                        }}
                        value={phoneNo}
                        onlyCountries={PhNoWhiteListedCountries}
                        masks={{
                          my: "..-....-....",
                          sg: "....-....",
                          th: "... ... ...",
                          id: "... ... ... ...",
                        }}
                        enableTerritories={false}
                        isValid={(inputNumber, meta: any, countries) => {
                          if (inputNumber === "") {
                            return true;
                          }
                          const result = (countries || []).some(
                            (country: any) => {
                              if (
                                startsWith(inputNumber, country.dialCode) ||
                                startsWith(country.dialCode, inputNumber)
                              ) {
                                const iso2: CountryCode =
                                  meta.iso2 as CountryCode;
                                const format = PhNoFormat[iso2];
                                if (!format) return false;
                                const isMatch = matchesFormat(
                                  inputNumber,
                                  format,
                                  country.countryCode
                                );
                                return isMatch;
                              }
                              return false;
                            }
                          );

                          if (!result && !!!errors.mobile?.message) {
                            setError("mobile", {
                              message: "invalid mobile number.",
                            });
                          } else if (result && !!errors.mobile?.message) {
                            setError("mobile", {
                              message: undefined,
                            });
                          }
                          return result;
                        }}
                        inputStyle={{
                          height: "42px",
                          width: "100%",
                          fontSize: "16px",
                        }}
                        onChange={(phone, meta: any) => {
                          console.log({ phone, meta });
                          setValue("country.code", meta.dialCode);
                          setValue("country.label", meta.name);
                          setValue(
                            "country.dial",
                            phone.replace(new RegExp(meta.dialCode), "")
                          );
                          setValue(
                            "mobile",
                            phone.replace(new RegExp(meta.dialCode), "")
                          );
                        }}
                      />

                      {errors.mobile?.message ? (
                        <FormHelperText>
                          {errors.mobile?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid> */}
                  <If
                    condition={
                      (!token && signupMethod === "signup-phone") ||
                      (!!token && signupMethod !== "signup-phone")
                    }
                  >
                    <Grid xs={12} py={0}>
                      <FormControl error={errors.email ? true : false}>
                        <If condition={!token}>
                          <FormLabel>
                            <Typography
                              className={`transition-opacity duration-300 opacity-100 h-auto`}
                              fontSize={"sm"}
                              fontWeight={400}
                              textColor={"text.secondary"}
                            >
                              {t("landing.signup.email", {
                                optional: `(${t("commons.optionalLabel")})`,
                              })}
                            </Typography>
                          </FormLabel>
                        </If>
                        <Input
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            borderRadius: "8px",
                          }}
                          disabled={!!token}
                          placeholder={t("landing.signup.email", {
                            optional: "",
                          })}
                          // required
                          size="lg"
                          type="email"
                          {...register("email")}
                        />
                        {errors.email?.message && (
                          <FormHelperText>
                            {errors.email?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </If>

                  <If condition={!!token && signupMethod !== "signup-phone"}>
                    <Grid xs={12} py={0}>
                      <FormControl error={!!errors.password}>
                        {/* <FormLabel>
                  <Typography
                    className={`transition-opacity duration-300 ${
                      password ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    fontSize={"sm"}
                    fontWeight={400}
                    textColor={"text.secondary"}
                  >
                    {t("landing.signup.password")}
                  </Typography>
                </FormLabel> */}
                        <Input
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            borderRadius: "8px",
                          }}
                          endDecorator={
                            <IconButton onClick={toggleShowPassword}>
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          }
                          placeholder={t("landing.signup.password")}
                          required
                          size="lg"
                          type={showPassword ? "text" : "password"}
                          {...register("password")}
                        />
                        {errors.password?.message && (
                          <FormHelperText>
                            {errors.password?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} py={0}>
                      <Password value={password} />
                    </Grid>
                  </If>

                  <If condition={!!token && signupMethod === "signup-phone"}>
                    <Grid xs={12} py={0}>
                      <FormControl
                        error={errors.mobile ? true : false}
                        className="w-full"
                      >
                        {/* <FormLabel>
                          <Typography
                            fontSize={"sm"}
                            fontWeight={500}
                            className={`transition-opacity duration-300 ${
                              mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                            }`}
                            textColor={"text.secondary"}
                          >
                            {t("landing.signup.phoneNumber")}
                          </Typography>
                        </FormLabel> */}
                        <PhoneInput
                          inputProps={{
                            name: "input-phone",
                          }}
                          value={country?.dial}
                          onlyCountries={PhNoWhiteListedCountries}
                          masks={{
                            my: "..-....-....",
                            sg: "....-....",
                            th: "... ... ...",
                            id: "... ... ... ...",
                          }}
                          enableTerritories={false}
                          disabled={!!token}
                          country={"id"}
                          isValid={(inputNumber, meta: any, countries) => {
                            if (inputNumber === "") {
                              return true;
                            }
                            const result = (countries || []).some(
                              (country: any) => {
                                if (
                                  startsWith(inputNumber, country.dialCode) ||
                                  startsWith(country.dialCode, inputNumber)
                                ) {
                                  const iso2: CountryCode =
                                    meta.iso2 as CountryCode;
                                  const format = PhNoFormat[iso2];
                                  if (!format) return false;
                                  const isMatch = matchesFormat(
                                    inputNumber,
                                    format,
                                    country.countryCode
                                  );

                                  return isMatch;
                                }
                                return false;
                              }
                            );

                            if (!result && !errors.mobile?.message) {
                              setError("mobile", {
                                message: t("commons.error.form.mobileInvalid"),
                              });
                            } else if (result && !!errors.mobile?.message) {
                              if (errors.mobile.type === "manual") {
                                return false;
                              }
                              clearErrors("mobile");
                              trigger("mobile");
                            }
                            return result;
                          }}
                          inputStyle={{
                            height: "56px",
                            width: "100%",
                            fontSize: "14px",
                            fontWeight: 500,
                            borderRadius: "8px",
                            paddingLeft: "5.5rem",
                          }}
                          buttonStyle={{
                            padding: "0 1rem",
                            borderRadius: "8px 0 0 8px",
                          }}
                          onChange={(phone, meta: any) => {
                            setValue("country.code", meta.dialCode);
                            setValue("country.label", meta.name);
                            setValue(
                              "country.dial",
                              phone.replace(new RegExp(meta.dialCode), "")
                            );
                            setValue(
                              "mobile",
                              phone.replace(new RegExp(meta.dialCode), "")
                            );

                            if (errors.mobile?.type === "manual") {
                              clearErrors("mobile");
                            }
                          }}
                        />

                        {errors.mobile?.message && (
                          <FormHelperText>
                            {errors.mobile?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </If>

                  <If condition={!alrdyHasBusiness}>
                    <Grid xs={12} py={0}>
                      <FormControl error={errors.referralCode ? true : false}>
                        <FormLabel>
                          <Typography
                            className={`transition-opacity duration-300 opacity-100 h-auto`}
                            fontSize={"sm"}
                            fontWeight={400}
                            textColor={"text.secondary"}
                          >
                            {t("landing.signup.referralCode", {
                              optional: `(${t("commons.optionalLabel")})`,
                            })}
                          </Typography>
                        </FormLabel>
                        <Input
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            borderRadius: "8px",
                          }}
                          placeholder={t("landing.signup.referralCode", {
                            optional: "",
                          })}
                          size="lg"
                          type="text"
                          {...register("referralCode")}
                          onChange={onReferralCodeChange}
                        />
                        {errors.referralCode?.message && (
                          <FormHelperText>
                            {errors.referralCode?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </If>

                  <Grid xs={12} textAlign={"center"}>
                    <Button
                      id="btn-create"
                      className=""
                      sx={{
                        width: "100%",
                        maxWidth: { xs: "100%", lg: "400px" },
                        mt: "1rem",
                      }}
                      fullWidth
                      size="lg"
                      type="submit"
                      disabled={isLoading || !isValid}
                    >
                      {t("commons.createBtn")}
                    </Button>

                    <If condition={!!token}>
                      <>
                        <Divider sx={{ my: 2 }}> {t("landing.or")}</Divider>
                        <Box
                          onClick={googleSignUpHandler}
                          sx={{
                            width: "100%",
                            maxWidth: { xs: "100%", lg: "400px" },
                            mx: "auto",
                          }}
                        >
                          <Gauth />
                        </Box>

                        <Stack
                          justifyContent={"center"}
                          paddingBottom={5}
                          flex={1}
                        >
                          <Typography fontSize="sm" mt={3} textAlign={"center"}>
                            {t("landing.signup.alreadyHaveAccount")}
                            <Link
                              component={RouterLink}
                              fontSize="sm"
                              marginLeft={1}
                              to={"/signin"}
                            >
                              {t("landing.loginBtn")}
                            </Link>
                          </Typography>
                        </Stack>
                      </>
                    </If>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Stack>
      </Shared>

      <LoadingModal isLoading={isLoading} />
    </>
  );
};

export default SignupCompanyProfile;
