import { BusinessSessionHelper } from "../helpers/business-session.helper";
import { privateAxios } from "../lib/api";
import { useNotification } from "../store/notification";
import { getLoggedInUserPhone } from "../store/session";
import { ApiConstants } from "../utils/constants";

export enum PaymentType {
  FINAL_PAYMENT = "final_payment",
  INITIAL_PAYMENT = "initial_payment",
}
export class PaymentService {
  static createPaymentRequestPayload({
    method,
    option,
    transactionType,
    transactionId,
    currency,
    amount,
    redirectUrl,
    paymentType,
  }: {
    method: string;
    option: string;
    transactionType: string;
    transactionId?: string;
    currency?: string | null;
    amount?: number | null;
    redirectUrl?: string;
    paymentType?: PaymentType;
  }) {
    var methodPayload = {};
    switch (method) {
      case "va":
        methodPayload = {
          va: {
            bankCode: option,
          },
        };
        break;
      case "e-wallet":
        methodPayload = {
          ewallet: {
            channel: option,
            phoneNumber: getLoggedInUserPhone() ?? "",
          },
        };
        break;
      case "retail":
        methodPayload = {
          retail: {
            channel: option,
            phoneNumber: getLoggedInUserPhone() ?? "",
          },
        };
        break;
      default:
        methodPayload = {
          selectedOption: option,
        };
        break;
    }
    console.log("methodPayload", methodPayload, amount);
    return {
      transactionId: transactionId,
      transactionType: transactionType,
      amount: amount,
      currency: currency,
      buyerName: BusinessSessionHelper.GetBusinessName() || "Guest User",
      redirectUrl: redirectUrl,
      paymentMethod: method,
      paymentType,
      ...methodPayload,
    };
  }

  static async enquiryPaymentStatus(
    transactionId: string,
    clientId: string,
    paymentType?: PaymentType
  ) {
    return privateAxios.get(`${ApiConstants.paymentUrl}/${transactionId}`, {
      params: {
        paymentType,
        clientId,
      },
    });
  }

  static async getAvailbleOnlinePaymentMethods(
    currency: string,
    clientId: string
  ) {
    return privateAxios.get(ApiConstants.paymentUrl + "/payment-method/list", {
      params: {
        currency,
        clientId,
      },
    });
  }

  static async requestPayment(payload: any, clientId: string) {
    const notifyToken = useNotification.getState().token;
    return privateAxios.post(ApiConstants.paymentUrl, payload, {
      headers: {
        "x-notification-token": notifyToken,
      },
      params: {
        clientId,
      },
    });
  }
}
