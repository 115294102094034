import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { RoleType } from "../types/role-type";
import { TrackerService } from "../services/tracker.service";

interface IProfile {
  id: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: string;
  fullName: string;
  countryCode: string;
  localNumber: string;
  isLocalNumberVerified: false;
  localNumberVerifiedDate: boolean;
}

export interface IAccount {
  id: string;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  uid: string;
  email?: string | null;
  phoneNumber?: string | null;
  lastLoginDate: any;
  authStrategy: string;
  isEmailVerified: boolean;
  emailVerifiedDate: any;
  isActive: boolean;
  isActiveLastUpdated: any;
}

interface IBusiness {
  id: string;
  companyName: string;
  role: RoleType;
  position: RoleType;
  countryCode: string;
  kycPaymentGatewayStatus: string;
  isOwner: boolean;
  companyCountry?: string | null;
  credits?: string | null;
  referralCode: string;
}

interface IBusinessRolePermissions {
  id: string;
  resource: {
    id: string;
    name: string;
    code: string;
    category: string;
  };
  permission: string[];
}

export interface IAuth {
  account: IAccount;
  profile: IProfile;
  accessToken: string;
  isKYCComplete: boolean;
  activeBusiness: IBusiness;
  rolePermissions: IBusinessRolePermissions[];
}

interface AuthState {
  session: IAuth | null;
  login: (user: AuthState["session"]) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        session: null,
        login: (user) => set(() => ({ session: user })),
        logout: () => set(() => ({ session: null })),
      }),
      { name: "userSession" }
    )
  )
);

export type ExtractState<S> = S extends {
  getState: () => infer T;
}
  ? T
  : never;

const accessTokenSelector = (state: ExtractState<typeof useAuthStore>) =>
  state.session?.accessToken;

export const getAccessToken = () => {
  return accessTokenSelector(useAuthStore.getState());
};

export const logout = () => useAuthStore.setState({ session: null });

export const setActiveBusiness = (business: IBusiness) => {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return;
  useAuthStore.setState({
    ...currentState,
    session: { ...currentState.session, activeBusiness: business },
  });
};

export const setAccessToken = (accessToken: string) => {
  const currentState = useAuthStore.getState();
  if (currentState.session !== null) return;
  useAuthStore.setState({
    ...currentState,
    session: { ...((currentState.session ?? {}) as IAuth), accessToken },
  });
};

export const setKYCComplete = () => {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return;
  useAuthStore.setState({
    ...currentState,
    session: { ...currentState.session, isKYCComplete: true },
  });
};

export const getActiveBusiness = () => {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return;
  return currentState.session.activeBusiness;
};

export function getLoggedInUserEmail() {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return;
  return currentState.session.account.email;
}

export function getLoggedInUserName() {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return;
  return currentState.session.profile?.fullName;
}

export function getLoggedInUserPhone() {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return;
  return currentState.session.profile?.localNumber;
}

export function getToken() {
  const currentState = useAuthStore.getState();
  if (currentState.session === null) return null;
  return currentState.session.accessToken;
}

export const setBusinessSession = (session: IAuth) => {
  const currentState = useAuthStore.getState();
  useAuthStore.setState({
    ...currentState,
    session: { ...currentState.session, ...session },
  });
  TrackerService.identify(
    session.account.email ?? session.account.phoneNumber ?? "unknown",
    mixpanelProfilePayload(session),
    true
  );
};

export const mixpanelProfilePayload = (session: IAuth | null) => {
  if (!session) return {};
  console.log("Set Session", session);
  return {
    Name: session?.profile.fullName,
    Email: session?.account.email,
    "Email Verified Date": session?.account.emailVerifiedDate,
    "Is Email Verified": session?.account.isEmailVerified,
    "Auth Strategy": session?.account.authStrategy,
    "Company Name": session?.activeBusiness.companyName,
    "Company Country": session?.activeBusiness.companyCountry,
    Credits: session?.activeBusiness.credits,
    "Is Owner": session?.activeBusiness.isOwner,
    Position: session?.activeBusiness.position,
    Role: session?.activeBusiness.role,
    "Is KYC Complete": session?.isKYCComplete,
    "Is Local Number Verified": session?.profile.isLocalNumberVerified,
    "Local Number":
      session?.profile.countryCode + " " + session?.profile.localNumber,
    "Local Number Verified Date": session?.profile.localNumberVerifiedDate,
  };
};
