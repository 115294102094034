import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PhoneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import showErrorToast, {
  ErrorToastConfig,
  SuccessToastConfig,
  showSuccessToast,
} from "../../../../components/Error";
import { BusinessContactService } from "../../../../services/business-contact.service";
import { useAuthStore } from "../../../../store/session";
import ShareContactModal from "./ShareContactModal";
import { BusinessSessionHelper } from "../../../../helpers/business-session.helper";

export interface IContactListProps {
  contacts: any[];
  refetch: () => void;
}
export function ContactList(props: IContactListProps) {
  const navigate = useNavigate();
  const session = useAuthStore();
  const [contactId, setContactId] = useState<string | undefined>(undefined);
  const [openShareModal, setOpenShareModal] = useState(false);

  const unshareContactMutation = useMutation({
    mutationFn: BusinessContactService.unshareContact,
    onSuccess: () => {
      props.refetch();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: BusinessContactService.deleteContact,
    onSuccess: () => {
      props.refetch();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const shareMutation = useMutation({
    mutationFn: BusinessContactService.shareContact,
    onSuccess: () => {
      toast(
        showSuccessToast("Contact shared successfully"),
        SuccessToastConfig
      );
      window.location.reload();
    },
  });

  const unshareContact = (ids: string[]) => {
    unshareContactMutation.mutate(ids);
  };

  const onFinishShare = (employeeIds: string[]) => {
    if (!contactId) return;
    setOpenShareModal(false);
    shareMutation.mutate({
      id: contactId,
      employeeIds,
    });
  };

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {props.contacts.map((contact) => (
        <div
          key={contact.id}
          className="border border-[#D0D5DD] p-4 rounded-2xl flex justify-between relative"
        >
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <UserCircleIcon className="w-12 h-12 text-[#98A2B3]" />
              <div className="flex flex-col">
                <Typography
                  style={{ wordBreak: "break-word" }}
                  className="text-[#101828] font-bold text-xl"
                >
                  {contact.nameOfContact}
                </Typography>
                <div className="flex items-center gap-2">
                  <Typography
                    style={{ wordBreak: "break-word" }}
                    className="text-[#98A2B3] font-normal text-xs break-words"
                  >
                    {contact.companyName}
                  </Typography>
                  <div className="border-[#499873] text-[#499873] border px-2 rounded-3xl hidden">
                    <p className="text-xs font-medium">Business admin</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1">
                <EnvelopeIcon className="w-3 h-3" />
                <p
                  className="text-[#101828] text-xs font-normal"
                  style={{ wordBreak: "break-word" }}
                >
                  {contact.companyEmail
                    ? contact.companyEmail
                    : contact.contactBusinessAccount?.email}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <PhoneIcon className="w-3 h-3" />
                <p className="text-[#101828] text-xs font-normal">
                  +{contact.companyNumberCountryCode}
                  {contact.companyNumber}{" "}
                </p>
              </div>
            </div>
            <Typography className="text-[#98A2B3] text-xs font-normal">
              Created by:{" "}
              <span className="text-[#101828]">
                {session.session?.account.id === contact.createdBy?.id
                  ? "You"
                  : contact.createdBy?.profile?.fullName}
              </span>
            </Typography>
          </div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ">
                <EllipsisVerticalIcon className="w-6 h-6" />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem>
                  <button
                    onClick={() => {
                      navigate(
                        `/client/business-contact/update/${contact.id}`,
                        {
                          state: { contact },
                        }
                      );
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none w-full text-left"
                  >
                    Edit
                  </button>
                </MenuItem>
                {(BusinessSessionHelper.IsOwner() ||
                  contact.createdBy.id ===
                  BusinessSessionHelper.Account()?.id) && (
                    <MenuItem>
                      <button
                        onClick={() => {
                          setOpenShareModal(true);
                          setContactId(contact.id);
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none w-full text-left"
                      >
                        Share
                      </button>
                    </MenuItem>
                  )}
                {(BusinessSessionHelper.IsOwner() ||
                  contact.createdBy.id ===
                  BusinessSessionHelper.Account()?.id) && (
                    <MenuItem>
                      <button
                        onClick={() => deleteMutation.mutate(contact.id)}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none w-full text-left"
                      >
                        Remove
                      </button>
                    </MenuItem>
                  )}
              </div>
            </MenuItems>
          </Menu>
        </div>
      ))}
      <ShareContactModal
        isLoading={shareMutation.isPending || unshareContactMutation.isPending}
        open={openShareModal && !!contactId}
        contactId={contactId}
        onClose={() => {
          setOpenShareModal(false);
          window.location.reload();
        }}
        onFinish={onFinishShare}
        unshareContact={unshareContact}
      />
    </div>
  );
}
