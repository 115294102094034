import React, { useEffect, useState } from "react";
import { ItemType } from "../../types/item";
import { ItemService } from "../../services/item.service";
import useContainer from "./useContainer";
import {
  FormLabel,
  Box,
  Button,
  FormControl,
  Input,
  Sheet,
  Stack,
  Typography,
  Table,
  Checkbox,
  IconButton,
  Divider,
} from "@mui/joy";
import PageTitleBox from "./components/PageTitleBox";
import { useTranslation } from "react-i18next";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { theme } from "../../components/Theme";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Percent } from "@mui/icons-material";
import { formatPrice } from "../../utils/formatPrice";
import { useSearchParams } from "react-router-dom";
import BatchUpdateAddItemsModal from "./components/BatchUpdateAddItemsModal";
import { SuccessModal } from "../../components/SuccessModal";

type BatchItemEditType = {
  data: {
    inventory: {
      inventoryId: string;
      price: number;
      catalogs: {
        catalogInventoryId: string;
        price: number;
      }[];
    }[];
  };
  settings: {
    price: number;
    discount: number;
  };
};

type Path<T, Prefix extends string = ""> = T extends object
  ? {
      [K in keyof T]: T[K] extends Array<infer U>
        ?
            | `${Prefix}${K & string}.${number}`
            | Path<U, `${Prefix}${K & string}.${number}.`>
        : Path<T[K], `${Prefix}${K & string}.`> | `${Prefix}${K & string}`;
    }[keyof T]
  : Prefix extends `${infer P}.`
  ? P
  : Prefix;

type BatchItemEditTypePath = Path<BatchItemEditType>;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter(props, ref) {
    const { register, setValue } = useFormContext();
    const { onChange, ...other } = props;
    const fieldName = props.name as BatchItemEditTypePath;
    const watchedValue = useWatch({ name: fieldName });

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        defaultValue={watchedValue}
        {...register(fieldName, {
          valueAsNumber: true,
          validate: (value) =>
            value === undefined ||
            value === null ||
            !isNaN(value as number) ||
            "Invalid Number",
        })}
        onValueChange={(values) => {
          setValue(fieldName, parseFloat(values.value));
        }}
        thousandSeparator=","
        valueIsNumericString
      />
    );
  }
);

const BatchEditInventory = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState<ItemType[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const {
    isLoading,
    setIsLoading,
    searchTerm,
    setSearchTerm,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
  } = useContainer();

  const methods = useForm<BatchItemEditType>();
  const { handleSubmit, register, setValue, getValues, reset } = methods;

  const fetchInventoryItemsById = (itemIds: string[]) => {
    setIsLoading(true);
    ItemService.getInventoryItemsByIds({ ids: itemIds })
      .then((res) => {
        setItems(res);

        const defaultData = res.map((i) => {
          const catalogData = i.catalogs?.map((cat) => {
            return {
              catalogInventoryId: cat.id ?? undefined,
              price: cat.price ?? undefined,
            };
          });

          return {
            inventoryId: i.id,
            price: i.price,
            catalogs: catalogData,
          };
        });
        reset({
          data: { inventory: defaultData },
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      if (document.referrer) {
        window.location.href = document.referrer;
      } else {
        window.location.href = "/";
      }
    }
  }, [window.innerWidth]);
  useEffect(() => {
    const itemIds = localStorage.getItem("batchEditItems");
    if (itemIds) {
      const parsedItemIds = JSON.parse(itemIds);
      if (parsedItemIds.length > 0) {
        fetchInventoryItemsById(parsedItemIds);
      }
    }
  }, []);

  const onSubmit = (data: BatchItemEditType) => {
    setIsLoading(true);

    const updateAll = selectedItems.length === 0;
    const payload = data.data.inventory.filter((i) => {
      if (updateAll || selectedItems.includes(i.inventoryId)) {
        return {
          inventoryId: i.inventoryId,
          price: Number(i.price),
          catalogs: i.catalogs.map((c) => {
            return {
              catalogInventoryId: c.catalogInventoryId,
              price: Number(c.price),
            };
          }),
        };
      }
    });

    ItemService.patchInventoryItemPrice({ updatePriceDto: payload })
      .then((res) => {
        if (res.length > 0) {
          setAlertMessage(
            t("catalog.inventory.batchEdit.successMessage", {
              items: res.length,
            })
          );
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        handleCancel();
      });
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    }
  };

  const handleSelectAll = () => {
    const ids = items.map((i) => i.id);
    setSelectedItems(ids);
  };

  const handleCancel = () => {
    localStorage.removeItem("batchEditItems");
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      window.location.href = "/";
    }
  };

  const handleUpdateAll = () => {
    const currentFormData = getValues("data.inventory");
    const price = getValues("settings.price");
    const discount = !isNaN(Number(getValues("settings.discount")))
      ? getValues("settings.discount")
      : 0;
    const updateAll = selectedItems.length === 0;

    const updatedFormData = currentFormData.map((i) => {
      if (updateAll || selectedItems.includes(i.inventoryId)) {
        const newPrice =
          price > 0
            ? price - price * (discount / 100)
            : i.price - i.price * (discount / 100);

        return {
          ...i,
          price: newPrice,
          catalogs: i.catalogs?.map((c) => {
            return {
              ...c,
              price:
                price > 0
                  ? newPrice
                  : c.price > 0
                  ? c.price - c.price * (discount / 100)
                  : newPrice,
            };
          }),
        };
      }

      return i;
    });

    console.log("BANANA UPDATED FORM DATA", updatedFormData);
    setValue("data.inventory", updatedFormData);
  };

  const handleRemoveItem = (id: string) => {
    const updatedItems = items.filter((i) => i.id !== id);

    setItems(updatedItems);

    const currentFormData = getValues("data.inventory");
    const updatedFormData = currentFormData.filter((i) => i.inventoryId !== id);

    setValue("data.inventory", updatedFormData);

    const ids = updatedItems.map((i) => i.id);
    localStorage.setItem("batchEditItems", JSON.stringify(ids));
  };

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        padding: "3rem 2rem",
      }}
    >
      {items && (
        <Box
          sx={{
            width: { xs: "92.4vw", lg: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          {/* page header */}
          <PageTitleBox
            title={t("catalog.inventory.batchEdit.pageTitle")}
            subtitle={t("catalog.inventory.batchEdit.pageSubtitle")}
          />

          {/* searchbar */}
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <Input
                // variant="linkz-input-search"
                size="sm"
                name="searchFormData.search"
                placeholder={t("catalog.inventory.searchPlaceholder")}
                startDecorator={<MagnifyingGlassIcon width={24} height={24} />}
                fullWidth
                sx={{
                  flexGrow: 1,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </Box>

            <Button
              variant="outlined"
              onClick={() => {
                setShowInventoryModal(true);
              }}
              startDecorator={<PlusIcon width={18} height={18} />}
            >
              {t("catalog.inventory.batchEdit.addItemBtn")}
            </Button>
          </Stack>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(event) => {
                // event.key === "Enter" && event.preventDefault();
                if (event.key === "Enter") {
                  if (!(event.target instanceof HTMLTextAreaElement)) {
                    event.preventDefault();
                  }
                }
              }}
            >
              <Stack direction={"column"} gap={"1.5rem"}>
                {/* update all settings fields */}
                <Stack
                  direction={"row"}
                  sx={{
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.5rem 1rem",
                    backgroundColor:
                      theme.colorSchemes.light.palette.others.neutral_light,
                  }}
                >
                  <Stack direction={"column"}>
                    <Typography fontWeight={700} fontSize={14}>
                      {t("catalog.inventory.batchEdit.bulkSettings")}
                    </Typography>
                    <Typography fontWeight={700} fontSize={14}>
                      {selectedItems.length}{" "}
                      <span className="font-medium">
                        {t("catalog.inventory.batchEdit.itemsSelected")}
                      </span>
                    </Typography>
                  </Stack>

                  <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
                    <FormLabel>
                      {t("catalog.inventory.batchEdit.pricePerUnit")}
                    </FormLabel>
                    <Input
                      size="sm"
                      placeholder={t(
                        "catalog.inventory.batchEdit.pricePerUnit"
                      )}
                      defaultValue={getValues("settings.price") ?? 0}
                      name="settings.price"
                      slotProps={{
                        input: {
                          component: NumericFormatAdapter,
                        },
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
                    <FormLabel
                      sx={{
                        whiteSpace: { xs: "break-spaces", lg: "nowrap" },
                      }}
                    >
                      {t("catalog.inventory.batchEdit.discountLabel")}
                    </FormLabel>
                    <Input
                      size="sm"
                      placeholder={t(
                        "catalog.inventory.batchEdit.discountPlaceholder"
                      )}
                      type="number"
                      slotProps={{ input: { min: 0, max: 100, step: "0.01" } }}
                      {...register(`settings.discount`, {
                        valueAsNumber: true,
                      })}
                      endDecorator={<Percent sx={{ width: 18, height: 18 }} />}
                      // onChange={(e) => {
                      //   const currentValue = e.target.value;
                      //   console.log("BANANA INPUT", currentValue);
                      // }}
                      onKeyDown={(e) => {
                        const currentValue = e.currentTarget.value;
                        const key = e.key;
                        const potentialValue = Number(currentValue + key);

                        if (!isNaN(potentialValue) && potentialValue > 100) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormControl>

                  <Button
                    variant="plain"
                    onClick={handleUpdateAll}
                    sx={{
                      color: "var(--joy-palette-primary-500)",
                      "&:hover": {
                        backgroundColor: "var(--joy-palette-primary-100)",
                      },
                      marginTop: "auto",
                    }}
                  >
                    {selectedItems.length > 0
                      ? t("catalog.inventory.batchEdit.updateSelectedBtn")
                      : t("catalog.inventory.batchEdit.updateAllBtn")}
                  </Button>
                </Stack>

                {/* items table */}
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    maxHeight: { lg: "27.5rem" },
                    overflow: "auto",
                    borderWidth: "2px",
                    position: "relative",
                  }}
                >
                  <Table
                    borderAxis="x"
                    sx={{ "& th": { textAlign: "center" }, minWidth: "100%" }}
                  >
                    <thead>
                      <tr key={"header"}>
                        <th key={"checkbox"} className="w-12">
                          <Checkbox
                            size="sm"
                            onChange={(event) => {
                              if (event.target.checked) {
                                handleSelectAll();
                              } else {
                                setSelectedItems([]);
                              }
                            }}
                          />
                        </th>
                        <th key={"index"} className="w-12">
                          {t("catalog.items.no")}
                        </th>
                        <th key={"picture"} className="w-24">
                          {t("catalog.items.picture")}
                        </th>
                        <th key={"sku"} className="w-24">
                          {t("catalog.items.sku")}
                        </th>
                        <th key={"productName"} className="w-24">
                          {t("catalog.items.productName")}
                        </th>
                        <th key={"currency"} className="w-24">
                          {t("catalog.items.currency")}
                        </th>
                        <th key={"price"} className="w-24">
                          {t("catalog.items.price")}
                        </th>
                        <th key={"quantity"} className="w-24">
                          {t("catalog.items.quantity")}
                        </th>
                        <th key={"unit_of_measure"} className="w-24">
                          {t("catalog.items.uom")}
                        </th>
                        <th key={"inventoryType"} className="w-24">
                          {t("catalog.items.category")}
                        </th>
                        <th key={"action"} colSpan={2} className="w-24">
                          {t("catalog.inventory.batchEdit.action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((i, index) => {
                        return (
                          <CollapsibleRow
                            key={i.id}
                            i={i}
                            index={index}
                            selectedItems={selectedItems}
                            handleChecked={handleChecked}
                            searchTerm={searchTerm}
                            handleRemove={handleRemoveItem}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </Box>

                <Stack
                  direction={"row"}
                  sx={{
                    gap: "0.75rem",
                    justifyContent: "right",
                    alignItems: "center",
                    padding: "0.75rem",
                  }}
                >
                  <Button
                    type="button"
                    variant="plain-gray"
                    sx={{
                      minWidth: "8rem",
                      textWrap: "nowrap",
                    }}
                    onClick={handleCancel}
                  >
                    {t("commons.cancelBtn")}
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      minWidth: "8rem",
                      textWrap: "nowrap",
                    }}
                  >
                    {t("commons.saveChangesBtn")}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      )}

      {/* modal */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <LoadingModal isLoading={isLoading} />
      <BatchUpdateAddItemsModal
        show={showInventoryModal}
        onClose={() => {
          setShowInventoryModal(false);
        }}
        /**
         * 3 things to update: @items state, @localStorage batchEditItems, @setValues from react-hook-form
         */
        onConfirm={(newItems: ItemType[]) => {
          const ids = items.map((i) => i.id);
          const newIds = newItems.map((i) => i.id);
          localStorage.setItem(
            "batchEditItems",
            JSON.stringify([...ids, ...newIds])
          );

          setItems((prev) => [...prev, ...newItems]);
          setShowInventoryModal(false);
        }}
        existingItems={items.map((i) => i.id)}
      />
    </Sheet>
  );
};

const CollapsibleRow = ({
  i,
  index,
  selectedItems,
  handleChecked,
  searchTerm = "",
  handleRemove,
}: {
  i: ItemType;
  index: number;
  selectedItems: string[];
  handleChecked: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  searchTerm: string;
  handleRemove: (id: string) => void;
}) => {
  const isSelected = selectedItems.includes(i.id);

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const sourcePage = searchParams.get("catalog");

  const [open, setOpen] = useState(false);
  const { control, register } = useFormContext();
  const inventoryPriceValue = useWatch({
    name: `data.inventory.${index}.price`,
  });

  const matchesSearch =
    searchTerm.length > 0
      ? i.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
        i.productName.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

  return (
    <React.Fragment>
      <tr
        className={`${isSelected ? "bg-primary-50 " : ""} ${
          matchesSearch ? "" : "hidden"
        }`}
      >
        <td className="text-center">
          <Checkbox
            size="sm"
            checked={isSelected}
            onChange={(e) => handleChecked(e, i.id)}
          />
        </td>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">
          <div className="relative w-full flex justify-center py-3 cursor-pointer">
            {i.thumbnail ? (
              <img
                src={i.thumbnail}
                crossOrigin="anonymous"
                width={30}
                height={30}
                className="object-contain"
              />
            ) : (
              <img
                src="/inventory-img-placeholder.png"
                width={30}
                height={30}
                className="object-contain"
              />
            )}
          </div>
        </td>
        <td className="text-center break-all">{i.sku}</td>
        <td className="text-center break-all">{i.productName}</td>
        <td className="text-center break-all">{i.currency}</td>
        <td className="text-center break-all">
          {formatPrice(i.price, i.currency)}
        </td>
        <td className="text-center break-all">{i.quantity}</td>
        <td className="text-center break-all">{i.uom1}</td>
        <td className="text-center break-all">{i.inventoryType}</td>
        <td className="text-center break-all">
          <Button
            variant="outlined-gray"
            className="p-1 min-w-fit min-h-fit border-[#D0D5DD]"
            onClick={() => {
              handleRemove(i.id);
            }}
          >
            <TrashIcon color="#98A2B3" width={18} height={18} />
          </Button>
        </td>
        <td className="text-center break-all border-px border-l">
          <IconButton
            aria-label="expand row"
            variant="plain"
            color="neutral"
            size="sm"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <ChevronUpIcon width={20} height={20} />
            ) : (
              <ChevronDownIcon width={20} height={20} />
            )}
          </IconButton>
        </td>
      </tr>
      <tr className={`${matchesSearch ? "" : "hidden"}`}>
        <td
          style={{
            height: 0,
            padding: 0,
          }}
          colSpan={12}
        >
          <Sheet
            sx={{
              paddingY: open ? "1rem" : 0,
              paddingX: "1.5rem",
              maxHeight: open ? "1000px" : 0,
              transition: "all 0.3s ease-in-out",
              overflow: "hidden",
            }}
          >
            <Stack
              direction={"column"}
              divider={<Divider orientation="horizontal" />}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "1rem",
                  paddingY: "0.5rem",
                  paddingX: "1rem",
                  alignItems: "center",
                  backgroundColor: !sourcePage
                    ? theme.colorSchemes.light.palette.primary[50]
                    : "transparent",
                }}
              >
                <Typography fontWeight={700} fontSize={14} minWidth={"8rem"}>
                  {t("breadcrumb.inventory")}
                </Typography>
                <Stack direction={"column"} gap={"0.25rem"} minWidth={"10rem"}>
                  <Typography fontWeight={400} fontSize={12}>
                    {i.sku}
                  </Typography>
                  <Typography fontWeight={700} fontSize={14}>
                    {i.productName}
                  </Typography>
                </Stack>
                <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
                  <FormLabel>
                    {t("catalog.inventory.batchEdit.basePrice")}
                  </FormLabel>
                  <Controller
                    control={control}
                    name={`data.inventory.${index}.price`}
                    defaultValue={inventoryPriceValue ?? i.price}
                    render={({ field }) => {
                      const { ref, onChange, ...rest } = field;

                      return (
                        <NumericFormat
                          {...rest}
                          customInput={Input}
                          autoComplete="off"
                          size="sm"
                          placeholder={t(
                            "catalog.inventory.batchEdit.basePrice"
                          )}
                          startDecorator={
                            <Typography fontWeight={600} fontSize={14}>
                              {i.currency}
                            </Typography>
                          }
                          onValueChange={(target) => {
                            onChange(target.floatValue);
                          }}
                          allowLeadingZeros={false}
                          thousandSeparator=","
                          valueIsNumericString
                        />
                      );
                    }}
                  />
                  <input
                    type="hidden"
                    value={i.id}
                    {...register(`data.inventory.${index}.inventoryId`)}
                  />
                </FormControl>
              </Box>
              {i.catalogs?.map((cat, idx) => {
                const catalogPriceValue = useWatch({
                  name: `data.inventory.${index}.catalogs.${idx}.price`,
                });

                return (
                  cat.catalog && (
                    <Box
                      key={cat.id}
                      sx={{
                        display: "flex",
                        width: "100%",
                        gap: "1rem",
                        paddingY: "0.5rem",
                        paddingX: "1rem",
                        alignItems: "center",
                        backgroundColor:
                          sourcePage === cat.catalog?.id
                            ? theme.colorSchemes.light.palette.primary[50]
                            : "transparent",
                      }}
                    >
                      <Stack
                        direction={"column"}
                        gap={"0.25rem"}
                        minWidth={"8rem"}
                      >
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                          minWidth={"8rem"}
                        >
                          {cat.catalog.catalogName}
                        </Typography>
                        <Typography fontWeight={700} fontSize={14}>
                          {i.productName}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={"0.25rem"}
                        minWidth={"10rem"}
                      >
                        <Typography fontWeight={400} fontSize={12}>
                          {i.sku}
                        </Typography>
                        <Typography fontWeight={700} fontSize={14}>
                          {i.productName}
                        </Typography>
                      </Stack>
                      <FormControl
                        sx={{ flex: "1", height: "100%", flexGrow: 1 }}
                      >
                        <FormLabel>
                          {t("catalog.inventory.batchEdit.basePrice")}
                        </FormLabel>
                        <Controller
                          control={control}
                          name={`data.inventory.${index}.catalogs.${idx}.price`}
                          defaultValue={
                            catalogPriceValue ?? cat.price ?? i.price
                          }
                          render={({ field }) => {
                            const { ref, onChange, ...rest } = field;

                            return (
                              <NumericFormat
                                {...rest}
                                customInput={Input}
                                autoComplete="off"
                                size="sm"
                                placeholder={t(
                                  "catalog.inventory.batchEdit.catalogPrice"
                                )}
                                startDecorator={
                                  <Typography fontWeight={600} fontSize={14}>
                                    {cat.currency ?? i.currency}
                                  </Typography>
                                }
                                onValueChange={(target) => {
                                  onChange(target.floatValue);
                                }}
                                allowLeadingZeros={false}
                                thousandSeparator=","
                                valueIsNumericString
                              />
                            );
                          }}
                        />
                        <input
                          type="hidden"
                          value={cat.id}
                          {...register(
                            `data.inventory.${index}.catalogs.${idx}.catalogInventoryId`
                          )}
                        />
                      </FormControl>
                    </Box>
                  )
                );
              })}
            </Stack>
          </Sheet>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default BatchEditInventory;
