import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { countries } from "../../components/Countries";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import i18next from "../../i18n/config";
import { AuthService } from "../../services/auth.service";
import { TrackerService } from "../../services/tracker.service";
import {
  BrowserRouterConstants,
  countryMap,
  getCountryCodeFromCountryName,
} from "../../utils/constants";
import { RecaptchaVerifier, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../lib/firebase";
// import { InvitationType } from "../../services/account.service";

export const passwordValidation = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-.])[A-Za-z\d!@#$%&*-.]{8,}$/
);

const generalSchema = z.object({
  agreed: z.boolean().default(false),
  companyName: z.string().min(1),
  fullName: z.string().min(1).max(100),
  countryName: z.string(),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  mobile: z
    .string()
    .min(
      1,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .max(
      100,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .optional(),
  email: z
    .string()
    .email({
      message: i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.email", {
          optional: "",
        }),
      }),
    })
    .or(z.literal("")),
  password: z
    .string()
    .min(8)
    .regex(passwordValidation, i18next.t("commons.error.form.password"))
    .optional(),
  referralCode: z.string().optional(),
  referrerUserId: z.string().optional(),
  // companyCountryCode: z.string(),
});
const invitationSchema = generalSchema.merge(
  z.object({
    companyName: generalSchema.shape.companyName.optional(),
    countryName: generalSchema.shape.countryName.optional(),
    country: generalSchema.shape.country.optional(),
  })
);
generalSchema.refine(
  () => {
    if (localStorage.getItem("method") === "signup-email") return true;
    return false;
  },
  { path: ["country"] }
);

const useContainer = () => {
  //#region ----- local states
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const token = urlSearchParams.get("token");
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // TODO: detect invitation token, run mutation and fill in invitation data
  // const [invitationType, setInvitationType] = useState<InvitationType | null>(
  //   null
  // );
  // TODO: update to use snake_case
  const callbackUrl = urlSearchParams.get("callbackURL");
  const resource = urlSearchParams.get("resource");
  const channel_type = urlSearchParams.get("channel_type");
  const referralCode = urlSearchParams.get("referral_code");
  const referrerUserId = urlSearchParams.get("referrer_user_id") || null;
  const { state } = useLocation();
  const isFromSSO = state?.origin === "sso" || false;
  const signupMethod = localStorage.getItem("method") || "signup-email";
  const navigate = useNavigate();
  const schema = token ? invitationSchema : generalSchema;
  const {
    control,
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    register,
    setValue,
    clearErrors,
    trigger,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const { password } = watch();
  const formData = watch();
  console.log(formData);

  const invitationTokenMutation = useMutation({
    mutationFn: AuthService.invitationTokenCheck,
    onSuccess: ({ data }) => {
      console.log(data);
      if (data.redirectURL) {
        navigate(data.redirectURL);
      } else {
        // setInvitationType(data.invitationType);
        setValue("email", data.email || "");
        setValue("companyName", undefined);
        if (data.phoneNumber) {
          setValue("mobile", data.phoneNumber);
          // setValue("country", {
          //   code: data.phoneNumber.countryCode,
          //   dial: data.phoneNumber.countryCode + `${data.phoneNumber.mobile}`,
          //   label:
          //     countries.find((c) => c.code === data.phoneNumber.countryCode)
          //       ?.label ?? "",
          // });
          setValue("country", {
            code: data.phoneNumberCountryCode,
            dial: data.phoneNumberCountryCode + `${data.phoneNumber}`,
            label:
              countries.find((c) => c.code === data.phoneNumberCountryCode)
                ?.label ?? "",
          });
        }
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      navigate("/signup");
    },
  });

  const referralCodeMutation = useMutation({
    mutationFn: AuthService.referralCodeCheck,
    onSuccess: ({ data }) => {
      return data;
    },
    onError: (error: any) => {
      setIsLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
      setError("referralCode", error.message);
    },
  });

  const companyNameMutation = useMutation({
    mutationFn: AuthService.verifyCompanyName,
    onSuccess: ({ data }) => {
      return data;
    },
    onError: (error: any) => {
      setIsLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const verifyEmailMutation = useMutation({
    mutationFn: AuthService.verifyEmail,
    onSuccess: ({ data }) => {
      return data;
    },
    onError: (error: any) => {
      setIsLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const { email, country, countryName, fullName, companyName } = watch();

  // const mutation = useMutation({
  //   // mutationFn: !token
  //   //   ? AuthService.signup
  //   //   : invitationTokenMutation.data?.data.hasBusinessProfile
  //   //   ? AuthService.signupInvitationWithRole
  //   //   : invitationTokenMutation.data?.data.hasAccount
  //   //   ? AuthService.signupInvitationWithoutRole
  //   //   : invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION
  //   //   ? AuthService.signupInvitationBusinessConnect
  //   //   : AuthService.signup,
  //   mutationFn: AuthService.verifyCompanyName,
  //   onSuccess: async ({ data }) => {
  //     if (!data.isExistCompanyName) {
  //       // localStorage.setItem("phoneVerificationToken", data.token);
  //       // localStorage.setItem("email", formData.email || "");
  //       // localStorage.setItem(
  //       //   "countryCode",
  //       //   formData.country ? formData.country.code.toString() || ""
  //       // );
  //       // localStorage.setItem("mobile", mobile);

  //       if (signupMethod === "signup-phone" && mobile) {
  //         const sendOtpResult = await phoneSignUpHandler({ mobile });

  //         if (sendOtpResult?.status === 200) {
  //           sessionStorage.setItem(
  //             "signupData",
  //             JSON.stringify({
  //               fullName: fullName,
  //               companyName: companyName!,
  //               countryCode: country!.code,
  //               mobile: mobile,
  //               companyCountryCode: countryName!,
  //               referralCode: referralCode,
  //               referrerUserId: referrerUserId,
  //             })
  //           );
  //           setShowModal(true);
  //           setTimeout(() => {
  //             setShowModal(false);
  //             if (callbackUrl) {
  //               navigate(
  //                 `/verify?callbackURL=${encodeURIComponent(
  //                   callbackUrl
  //                 )}&method=signup-phone`
  //               );
  //             } else {
  //               navigate("/verify?method=signup-phone");
  //             }
  //           }, 4000);
  //         }
  //       } else {
  //         if (invitationTokenMutation.data?.data.hasBusinessProfile) {
  //           sessionStorage.setItem(
  //             "signupPayload",
  //             JSON.stringify({
  //               fullName: fullName,
  //               email: email,
  //               password: password,
  //               referralCode: referralCode,
  //               referrerUserId: referrerUserId,
  //             })
  //           );
  //         } else {
  //           sessionStorage.setItem(
  //             "signupPayload",
  //             JSON.stringify({
  //               fullName: fullName,
  //               companyName: companyName!,
  //               countryCode: country!.code,
  //               email: email,
  //               password: password,
  //               companyCountryCode: countryName!,
  //               referralCode: referralCode,
  //               referrerUserId: referrerUserId,
  //             })
  //           );
  //         }
  //       }
  //     }
  //   },
  //   onError: (error: any) => {
  //     toast(showErrorToast(error), ErrorToastConfig);
  //   },
  // });

  //#region ----- handler functions
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data) => {
    setIsLoading(true);

    // return to /signup if there's no identifier
    const identifier = data.email || data.mobile;
    if (!identifier) {
      return navigate("/signup");
    }

    // verify referral code
    if (
      referralCode &&
      !invitationTokenMutation.data?.data.hasBusinessProfile
    ) {
      const isReferralCodeValid = await referralCodeMutation.mutateAsync({
        email: data.email ?? null,
        phoneNumber: data.country?.dial ?? null,
        phoneNumberCountryCode: data.country?.code ?? null,
        referralCode,
        referrerUserId,
      });

      if (!isReferralCodeValid) {
        return;
      }
    }

    // only do these validations when no invite token
    if (!token) {
      // verify company name
      const isCompanyRegistered = await companyNameMutation.mutateAsync({
        companyName: companyName || "",
      });

      if (isCompanyRegistered.data.isExistCompanyName) {
        setIsLoading(false);
        setError("companyName", {
          message: i18next.t("commons.error.form.companyNameNotAvailable"),
          type: "manual",
        });
        return;
      }

      // verify email
      if (email) {
        const isEmailRegistered = await verifyEmailMutation.mutateAsync({
          email: email,
        });

        if (isEmailRegistered.data.isExistEmail) {
          setIsLoading(false);
          setError("email", {
            message: i18next.t("commons.error.form.emailNotAvailable"),
            type: "manual",
          });
          return;
        }
      }
    }

    const currentParams = new URLSearchParams(window.location.search);
    const updatedParams = new URLSearchParams(currentParams);

    if (callbackUrl) {
      updatedParams.set("callbackURL", encodeURIComponent(callbackUrl));
    }
    if (token) {
      updatedParams.set("token", encodeURIComponent(token));
    }

    if (signupMethod === "signup-phone" && data.mobile && data.country) {
      try {
        const sendOtpResult = await AuthService.sendFirebaseOtp({
          phoneNumber: data.mobile,
          phoneNumberCountryCode: data.country.code,
        });

        if (sendOtpResult?.status === 200) {
          localStorage.setItem("mobile", data.mobile);
          localStorage.setItem("countryCode", data.country.code);
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            localStorage.setItem("signUpData", JSON.stringify(data));
            navigate(`/verify?${updatedParams.toString()}`);
          }, 3000);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error sending OTP", error);
      }
    } else {
      navigate(
        `${
          BrowserRouterConstants.Signup
        }/phone-input?${updatedParams.toString()}`,
        { state: data }
      );
    }

    // mutation.mutate(payload);
    // if (invitationTokenMutation.data?.data.hasBusinessProfile) {
    //   payload = {
    //     fullName: data.fullName,
    //     email: data.email,
    //     password: data.password,
    //     referralCode: data.referralCode,
    //     referrerUserId: data.referrerUserId,
    //   };
    // } else {
    //   payload = {
    //     fullName: data.fullName,
    //     companyName: data.companyName!,
    //     countryCode: data.country!.code,
    //     email: data.email,
    //     password: data.password,
    //     companyCountryCode: data.countryName!,
    //     referralCode: data.referralCode,
    //     referrerUserId: data.referrerUserId,
    //   };
    // }

    // if (invitationType == InvitationType.BUSINESS_CONNECTION_INVITATION) {
    //   payload.token = token;
    // }
    // payload.email = data.email.toLowerCase();
    // const currentParams = new URLSearchParams(window.location.search);

    // const updatedParams = new URLSearchParams(currentParams);

    // if (callbackUrl) {
    //   updatedParams.set("callbackURL", encodeURIComponent(callbackUrl));
    // }

    // if (token) {
    //   updatedParams.set("token", encodeURIComponent(token));
    // }

    // if (
    //   referralCode &&
    //   !invitationTokenMutation.data?.data.hasBusinessProfile
    // ) {
    //   const isReferralCodeValid = await referralCodeMutation.mutateAsync({
    //     email: data.email,
    //     referralCode: referralCode,
    //     referrerUserId: referrerUserId ?? null,
    //   });

    //   if (!isReferralCodeValid) {
    //     return;
    //   }
    // }

    // const basePath = "/signup/phone-input";

    // const url = `${basePath}?${updatedParams.toString()}`;

    // localStorage.removeItem("userSub");
    // navigate(url, { state: payload });
  };

  const onReferralCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newParams = new URLSearchParams(urlSearchParams);
    if (event.target.value) {
      newParams.set("referral_code", event.target.value);
    } else {
      newParams.delete("referral_code");
    }

    setSearchParams(newParams);
  };

  async function googleSignUpHandler() {
    try {
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.UserProceedWithSignUp,
        {
          "Signup method": "Google signup",
        }
      );
      const result = (await signInWithPopup(auth, googleProvider)) as any;
      const accessToken = result.user.accessToken;
      localStorage.setItem("email", result.user.email);
      // if (referral) {
      //   referralCodeMutation.mutate({
      //     email: "eastmarch@mailinator.com",
      //     referralCode: referral,
      //   });
      // }

      const queryParams = new URLSearchParams();

      if (token) {
        queryParams.set("token", token);
      }

      if (referralCode) {
        queryParams.set("referral_code", referralCode);
      }

      if (referrerUserId) {
        queryParams.set("referrer_user_id", referrerUserId);
      }

      navigate(`/signup/sso?${queryParams.toString()}`, {
        state: { accessToken },
      });
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  }
  //#endregion

  //#region life cycle method
  useEffect(() => {
    if (token) {
      invitationTokenMutation.mutate({ token });
    }
  }, [token]);

  useEffect(() => {
    if (referralCode) {
      setValue("referralCode", referralCode);
    }

    if (referrerUserId) {
      setValue("referrerUserId", referrerUserId);
    }
  }, [referralCode, referrerUserId]);

  useEffect(() => {
    if (countryName) {
      const countryCode: any = countryMap[countryName];
      if (countryCode) {
        // setValue("country", countryCode);
      }
    }
  }, [countryName]);

  useEffect(() => {
    if (country?.code) {
      const countryName: any = getCountryCodeFromCountryName[country?.code];
      if (countryName) {
        setValue("countryName", countryName);
        trigger();
      }
    }
  }, [country?.code]);

  useEffect(() => {
    setTimeout(() => {
      if (resource === "catalog" && channel_type === "email") {
        TrackerService.track(
          TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.SignupPageVisited,
          {
            "Signup Source": "Email catalog invitation",
          }
        );
      } else if (resource === "Landing Page") {
        TrackerService.track(
          TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.SignupPageVisited,
          {
            "Signup Source": resource,
          }
        );
      }
    }, 2500);
  }, []);

  useEffect(() => {
    if (isFromSSO) {
      if (!state) {
        return;
      }

      setValue("password", state.password);
      // setValue("companyCountryCode", state.companyCountryCode);
      setValue("fullName", state.fullName);
      setValue("companyName", state.companyName);
      setValue("email", state.email);
      // setValue("email", "pepe@mailinator.com");
      setValue("referralCode", state.referralCode);
      setValue("referrerUserId", state.referrerUserId);
      trigger();
    } else {
      if (!token) {
        const signupCredentials = JSON.parse(
          localStorage.getItem("signUpCredentials") || "{}"
        );

        if (signupMethod === "signup-phone") {
          setValue("country", signupCredentials.country);
          setValue("mobile", signupCredentials.country.dial);
        } else {
          setValue("password", signupCredentials.password);
          setValue("email", signupCredentials.email);
        }
      }

      setValue("agreed", true);
      trigger();
    }
  }, []);

  // setup recaptcha
  useEffect(() => {
    if (!window.recaptchaVerifier) {
      // create element if not found
      let recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        recaptchaContainer = document.createElement("div");
        recaptchaContainer.id = "recaptcha-container";
        document.body.append(recaptchaContainer);
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );

      window.recaptchaVerifier.verify();
    }
  }, []);

  // useEffect(() => {
  //   const countryCode = localStorage.getItem("countryCode") || "+65";
  //   const mobile = localStorage.getItem("mobile") || "9444054610";

  //   setValue("agreed", true);
  //   if (signupMethod === "signup-phone") {
  //     setValue("country.code", countryCode);
  //     setValue("mobile", `${countryCode}${mobile}`);
  //   }
  // }, []);

  //#endregion
  return {
    // functions
    handleSubmit,
    onSubmit,
    onReferralCodeChange,
    register,
    clearErrors,
    errors,
    control,
    showPassword,
    toggleShowPassword,
    setValue,
    setError,
    trigger,
    setShowTermsModal,
    googleSignUpHandler,
    privacyPolicy: showPrivacyPolicy,
    setPrivacyPolicy: setShowPrivacyPolicy,
    // states
    // agreed,
    showTermsModal,
    // password,
    token,
    isLoading: invitationTokenMutation.isPending || isLoading,
    fullName,
    showModal,
    companyName,
    // email,
    // mobile,
    country,
    countryName,
    password,
    referralCode,
    isValid,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
    alrdyHasBusiness: invitationTokenMutation.isPending
      ? {}
      : invitationTokenMutation.data?.data.hasBusinessProfile,
    signupMethod,
  };
};

export default useContainer;
