import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid";
import { Add, Search } from "@mui/icons-material";
import { Stack } from "@mui/joy";
import { debounce } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import { BusinessContactService } from "../../../services/business-contact.service";
import { ContactList } from "./components/ContactList";

const types = [
  { value: "Linkz User", name: "linkz_user" },
  { value: "Non-linkz user", name: "non_linkz_user" },
  { value: "All Type", name: "all" },
];

const sortings = [
  { value: "Descending", name: "DESC" },
  { value: "Ascending", name: "ASC" },
];

export default function BusinessContact() {
  const [contacts, setContacts] = useState<any[]>([]);
  const [paginate, setPaginate] = useState<any>({
    total: 0,
    limit: 100,
    offset: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = searchParams.get("q");
  const sort = searchParams.get("sort");
  const user_type = searchParams.get("user_type");

  const getContact = (params: any) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    BusinessContactService.getContacts(params).then((data) => {
      setContacts(data.contacts);
      setPaginate(data.paginate);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (query) getContact({ ...paginate, search: query, sort, user_type });
    else getContact({ ...paginate, sort, user_type });
  }, []);

  const handleSearch = useCallback(
    debounce((value) => {
      if (value.length > 1) {
        searchParams.set("q", value);
        getContact({ ...paginate, search: value, sort, user_type });
      } else {
        searchParams.delete("q");
        getContact({ ...paginate, sort, user_type });
      }
      setSearchParams(searchParams);
    }, 300), // debounce delay in milliseconds
    [paginate]
  );

  const [filterType, setFilterType] = useState(() => types[2]);
  const [sortingType, setSortingType] = useState(() => {
    const idx = sortings.findIndex((s) => s.name === sort);
    return sortings[idx > -1 ? idx : 0];
  });

  return (
    <>
      <Stack gap={1} className="relative">
        <Stack
          className="mt-4 lg:my-8"
          justifyContent={"space-between"}
          direction={{
            md: "row",
            xs: "column",
          }}
          gap={1}
        >
          <div className="flex items-center border border-[#98A2B3] rounded-3xl overflow-hidden px-4 w-full lg:w-[326px] gap-4 text-sm min-h-10">
            <Search />
            <input
              placeholder="Search contact"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              defaultValue={query ?? ""}
              type="text"
              className=""
            />
          </div>
          <button
            className="flex items-center bg-[#499873] text-white px-4 gap-2 py-[10px] rounded-3xl justify-center"
            onClick={() => navigate("/client/business-contact/add")}
          >
            <Add />
            <p className="text-sm">
              {t("manage.businessContact.addNewContact")}
            </p>
          </button>
        </Stack>
        <div className="flex items-center justify-between">
          <Listbox
            value={filterType}
            onChange={(data) => {
              setFilterType(data);
              if (data.name !== "all") {
                searchParams.set("user_type", data.name);
              } else {
                searchParams.delete("user_type");
              }

              const q = searchParams.get("q");
              setSearchParams(searchParams);
              getContact({
                ...paginate,
                search: q,
                sort: sort,
                user_type: data.name !== "all" ? data.name : undefined,
              });
            }}
          >
            <div className="relative mt-2">
              <ListboxButton className="relative w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#499873] sm:text-sm/6">
                <span className="flex items-center">
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold  text-sm text-[#101828]">
                    {filterType.value}
                  </span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                  <ChevronUpDownIcon
                    aria-hidden="true"
                    className="text-gray-400 size-5"
                  />
                </span>
              </ListboxButton>
              <ListboxOptions
                transition
                className="absolute z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
              >
                {types.map((user_type) => (
                  <ListboxOption
                    key={user_type.name}
                    value={user_type}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-[#499873] data-[focus]:text-white"
                  >
                    <div className="flex items-center">
                      <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold text-sm text-[#101828]">
                        {user_type.value}
                      </span>
                    </div>

                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-[#499873] group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                      <CheckIcon aria-hidden="true" className="size-5" />
                    </span>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Listbox>
          <Listbox
            value={filterType}
            onChange={(data) => {
              setSortingType(data);
              searchParams.set("sort", data.name);
              const q = searchParams.get("q");
              setSearchParams(searchParams);
              getContact({
                ...paginate,
                search: q,
                sort: data.name,
                user_type,
              });
            }}
          >
            <div className="relative mt-2">
              <ListboxButton className="relative w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#499873] sm:text-sm/6">
                <span className="flex items-center gap-4">
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold  text-sm text-[#101828]">
                    {sortingType.value}
                  </span>
                  {sortingType.name === "descending" ? (
                    <BarsArrowUpIcon className="w-6 h-6" />
                  ) : (
                    <BarsArrowDownIcon className="w-6 h-6" />
                  )}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                  <ChevronUpDownIcon
                    aria-hidden="true"
                    className="text-gray-400 size-5"
                  />
                </span>
              </ListboxButton>
              <ListboxOptions
                transition
                className="absolute z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
              >
                {sortings.map((sorting) => (
                  <ListboxOption
                    key={sorting.name}
                    value={sorting}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-[#499873] data-[focus]:text-white"
                  >
                    <div className="flex items-center gap-4">
                      <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold  text-sm text-[#101828]">
                        {sorting.value}
                      </span>
                      {sorting.name === "descending" ? (
                        <BarsArrowUpIcon className="w-6 h-6" />
                      ) : (
                        <BarsArrowDownIcon className="w-6 h-6" />
                      )}
                    </div>

                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-[#499873] group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                      <CheckIcon aria-hidden="true" className="size-5" />
                    </span>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
        <ContactList
          refetch={() => getContact({ ...paginate })}
          contacts={contacts}
        />
      </Stack>
      <LoadingModal isLoading={isLoading} />
    </>
  );
}
