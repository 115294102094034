import { Stack, Tab, tabClasses, TabList, TabPanel, Tabs } from "@mui/joy";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { Link as RouterLink } from "react-router-dom";
import Gauth from "../../components/Gauth";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import Shared from "../../layouts/Shared";
import { privacyPolicyTitle, termsTitle } from "../../utils/constants";
import TermsAndPoliciesModal from "./components/TermsAndPoliciesModal";
import useContainer from "./useContainer";
import PhoneSignupForm from "./components/PhoneSignup/PhoneSignupForm";
import EmailSignupForm from "./components/EmailSignup/EmailSignupForm";
import { IfElse } from "../../components/Condition";

export default function Signup() {
  const { t } = useTranslation();

  const {
    privacyPolicy,
    setPrivacyPolicy,
    googleSignUpHandler,
    isLoading,
    showTermsModal,
    token,
    setShowTermsModal,
  } = useContainer();

  return (
    <>
      <Shared
        sxProps={{
          backgroundColor: "transparent",
        }}
        minWidth={"329px"}
        width={{
          md: "480px",
        }}
      >
        <Helmet
          link={[
            { href: "https://linkzasia.com/auth/register", rel: "canonical" },
          ]}
          meta={[{ content: "Register", name: "description" }]}
          title="Register - Linkz"
        />
        <Stack
          direction={"column"}
          flex={1}
          width={{ xs: "100%", lg: "480px" }}
          mx={"auto"}
        >
          <IfElse
            condition={!token}
            ifBlock={
              <Tabs aria-label="signup-method" defaultValue={0}>
                <TabList
                  sx={{
                    [`&& .${tabClasses.root}`]: {
                      "&:hover": {
                        bgcolor: "transparent",
                      },
                      [`&.${tabClasses.selected}`]: {
                        "&::after": {
                          bgcolor: "primary.500",
                          borderTopLeftRadius: 3,
                          borderTopRightRadius: 3,
                          height: 2,
                        },
                        color: "primary.plainColor",
                      },
                      bgcolor: "transparent",
                      flex: "initial",
                      paddingY: 2,
                    },
                    justifyContent: "space-evenly",
                    paddingTop: 1,
                  }}
                >
                  <Tab sx={{ width: "50%" }}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={"primary"}
                    >
                      {t("landing.signup.methods.email")}
                    </Typography>
                  </Tab>
                  <Tab sx={{ width: "50%" }}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={"primary"}
                    >
                      {t("landing.signup.methods.phone")}
                    </Typography>
                  </Tab>
                </TabList>

                <Box
                  component="section"
                  data-testid="register"
                  id="register"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    py: "1rem",
                  }}
                >
                  <Typography
                    textColor={"primary.solidActiveBg"}
                    textAlign={"center"}
                    fontSize={"18"}
                    fontWeight={600}
                    py={"1rem"}
                  >
                    {t("landing.createAccountLabel")}
                  </Typography>
                  <TabPanel
                    value={0}
                    sx={{
                      p: 0,
                    }}
                  >
                    <EmailSignupForm />
                  </TabPanel>
                  <TabPanel
                    value={1}
                    sx={{
                      p: 0,
                    }}
                  >
                    <PhoneSignupForm />
                  </TabPanel>

                  <Divider sx={{ my: 2 }}>{t("landing.or")}</Divider>
                  <Box
                    onClick={googleSignUpHandler}
                    sx={{
                      width: "100%",
                      maxWidth: { xs: "100%", lg: "400px" },
                      margin: "auto",
                    }}
                  >
                    <Gauth />
                  </Box>
                </Box>
                <Stack justifyContent={"center"} paddingBottom={5} flex={1}>
                  <Typography fontSize="sm" mt={3} textAlign={"center"}>
                    {t("landing.signup.alreadyHaveAccount")}
                    <Link
                      component={RouterLink}
                      fontSize="sm"
                      marginLeft={1}
                      to={"/signin"}
                    >
                      {t("landing.loginBtn")}
                    </Link>
                  </Typography>
                </Stack>
              </Tabs>
            }
            elseBlock={<></>}
          />
        </Stack>
      </Shared>

      <LoadingModal isLoading={isLoading} />
      <TermsAndPoliciesModal
        title={t(termsTitle)}
        content={"t&c"}
        isOpen={showTermsModal}
        close={() => setShowTermsModal(false)}
      />
      <TermsAndPoliciesModal
        title={t(privacyPolicyTitle)}
        content={"privacyPolicy"}
        isOpen={privacyPolicy}
        close={() => setPrivacyPolicy(false)}
      />
    </>
  );
}
