import {
  Select,
  Option,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Button,
} from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Chip } from "@mui/joy";
import { formatCurrencyByCode } from "../../../utils/formatPrice";
import { ConfirmationModal } from "../../ConfirmationModal";
import { DecimalInput } from "../../inputs/DecimalInput";
import RequestFullPaymentModal from "./RequestFullPaymentModal";
import { OrderStatusEnum } from "../../../types/order";

export default function MultiplePaymentPlan({ container }: { container: any }) {
  const {
    orderId,
    orderStatus,
    grandTotal,
    setValue,
    getValues,
    watch,
    currency,
    isReadOnly,
    selectedPaymentPlan,
    setSelectedPaymentPlan,
    onConfirmChangePaymentType,
    handleChangePaymentPlan,
    handleCancelChangePaymentPlan,
    allowRequestFullPayment,
    onRequestFullPayment,
    setOnRequestFullPayment,
    getPaidPaymentAmount,
    isSeller,
    cancelRequestFullPayment,
    isFullPaymentRequested,
    getFullPaymentAmount,
  } = container;

  const paymentStatusLabel = (status: string | any) => {
    switch (status) {
      case "PENDING":
        return "Pending";
      case "SUCCESS":
        return "Paid";
      case "FAILED":
        return "Failed";
      case "CANCELED":
        return "Canceled";
      default:
        return "Pending";
    }
  };

  const paymentStatusColor = (status: string | any) => {
    switch (status) {
      case "PENDING":
        return "warning";
      case "SUCCESS":
        return "primary";
      case "FAILED":
        return "danger";
      case "CANCELED":
        return "danger";
      default:
        return "warning";
    }
  };
  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "var(--joy-palette-primary-50)",
        borderRadius: "xl",
        boxShadow: "sm",
        maxWidth: "800px",
      }}
    >
      <Typography
        component="label"
        level="h4"
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        Payment Plan
        <Typography color="danger">*</Typography>
      </Typography>

      <FormControl sx={{ mb: 3 }}>
        <FormLabel>Payment Times</FormLabel>
        <Select
          disabled={!!isReadOnly}
          value={watch("paymentTimes")}
          onChange={(_, value) => {
            setValue("paymentTimes", value as number);
            setValue(
              "paymentHistory",
              Array.from({ length: value as number }).map((_, num) => ({
                time: num + 1,
                amount: 0,
                percentage: 0,
                status: "PENDING",
              }))
            );
          }}
          indicator={<KeyboardArrowDown />}
          sx={{
            minWidth: 200,
          }}
        >
          {Array.from({ length: 12 }).map(
            (_, num) =>
              num > 1 && (
                <Option key={num + 1} value={num + 1}>
                  {num + 1} Payments
                </Option>
              )
          )}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 3 }}>
        <FormLabel>Payment value type</FormLabel>
        <Select
          disabled={!!isReadOnly}
          value={selectedPaymentPlan}
          defaultValue={"AMOUNT"}
          onChange={(_, value) => setSelectedPaymentPlan(value)}
          indicator={<KeyboardArrowDown />}
          sx={{
            minWidth: 200,
          }}
        >
          <Option value="AMOUNT">Amount</Option>
          <Option value="PERCENTAGE">Percentage</Option>
        </Select>
      </FormControl>

      {(getValues("paymentHistory") ?? []).map((payment: any) => (
        <Box
          key={payment.time}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            gap: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormLabel>Payment {payment.time}</FormLabel>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                mt: "0.5rem",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Box sx={{ flex: 1 }} maxWidth={300}>
                <DecimalInput
                  startDecorator={
                    (getValues("paymentTypes") ?? selectedPaymentPlan) ===
                      "AMOUNT" && currency
                  }
                  endDecorator={
                    (getValues("paymentTypes") ?? selectedPaymentPlan) ===
                      "PERCENTAGE" && "%"
                  }
                  disabled={!!isReadOnly}
                  defaultValue={
                    (getValues("paymentTypes") ?? selectedPaymentPlan) ===
                    "AMOUNT"
                      ? getValues(`paymentHistory.${payment.time - 1}.amount`)
                      : getValues(
                          `paymentHistory.${payment.time - 1}.percentage`
                        )
                  }
                  onChange={(value: string) => {
                    console.log("PARSE", value, `${parseFloat(value)}`);
                    if (`${parseFloat(value)}` !== "NaN") {
                      if (
                        (getValues("paymentTypes") ?? selectedPaymentPlan) ===
                        "AMOUNT"
                      ) {
                        setValue(
                          `paymentHistory.${payment.time - 1}.amount`,
                          parseFloat(value)
                        );
                        setValue(
                          `paymentHistory.${payment.time - 1}.percentage`,
                          Math.round((parseFloat(value) / grandTotal) * 100)
                        );
                      } else {
                        setValue(
                          `paymentHistory.${payment.time - 1}.percentage`,
                          parseFloat(value)
                        );
                        setValue(
                          `paymentHistory.${payment.time - 1}.amount`,
                          (parseFloat(value) / 100) * grandTotal
                        );
                      }
                    }
                  }}
                />
              </Box>
              {isReadOnly && (
                <Chip
                  sx={{
                    height: "2rem",
                  }}
                  variant="outlined"
                  color={
                    paymentStatusColor(
                      getValues(`paymentHistory.${payment.time - 1}.status`)
                    ) as any
                  }
                >
                  {
                    paymentStatusLabel(
                      getValues(`paymentHistory.${payment.time - 1}.status`)
                    ) as any
                  }
                </Chip>
              )}
            </Box>
          </Box>

          <Typography
            level="body-md"
            sx={{
              minWidth: "100px",
              textAlign: "right",
            }}
          >
            {formatCurrencyByCode(
              getValues(`paymentHistory.${payment.time - 1}.amount`),
              currency,
              currency
            )}
          </Typography>
        </Box>
      ))}
      {(watch("paymentHistory") as any[])?.reduce(
        (acc, curr) => acc + curr.amount,
        0
      ) !== grandTotal && (
        <Typography color="danger" fontSize={"sm"}>
          Total Payment{" "}
          {formatCurrencyByCode(
            (getValues("paymentHistory") ?? [])?.reduce(
              (acc: number, curr: any) => acc + curr.amount,
              0
            ),
            currency,
            currency
          )}{" "}
          must be equal to{" "}
          {formatCurrencyByCode(grandTotal, currency, currency)}
        </Typography>
      )}
      {isFullPaymentRequested() && (
        <Typography
          mb={"1rem"}
          color="warning"
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          Full payment requested by seller with amount{" "}
          {formatCurrencyByCode(getFullPaymentAmount(), currency, currency)}
        </Typography>
      )}
      <Box>
        {orderStatus !== OrderStatusEnum.COMPLETE &&
          allowRequestFullPayment() &&
          isSeller &&
          !isFullPaymentRequested() && (
            <Button onClick={() => setOnRequestFullPayment(true)}>
              Request Full Payment
            </Button>
          )}
        {orderStatus !== OrderStatusEnum.COMPLETE &&
          isFullPaymentRequested() &&
          isSeller && (
            <Button
              sx={{ bgcolor: "white" }}
              variant="outlined"
              onClick={() => cancelRequestFullPayment()}
            >
              Cancel Full Payment
            </Button>
          )}
      </Box>
      {!isReadOnly && (
        <ConfirmationModal
          open={onConfirmChangePaymentType}
          title="Change Payment Plan"
          content="Changing Payment plan will erase all filled-in information. Are you sure you want to continue?"
          onCancel={() => handleCancelChangePaymentPlan()}
          onConfirm={() => handleChangePaymentPlan()}
          btnConfirmText="Proceed"
        />
      )}
      <RequestFullPaymentModal
        orderId={orderId}
        open={onRequestFullPayment}
        grandTotal={grandTotal}
        paidAmount={getPaidPaymentAmount()}
        currency={currency}
        onClose={() => setOnRequestFullPayment(false)}
      />
    </Box>
  );
}
