import { listItemButtonClasses } from "@mui/joy";
import { extendTheme } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
  // No custom tokens found, you can skip the theme augmentation.
}

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        common: {
          black: "#101828",
          white: "#FFF",
        },
        background: {
          surface: "#FFF",
        },
        primary: {
          50: "#F1F8F4",
          100: "#DDEEE3",
          200: "#BDDDCA",
          300: "#91C4A9",
          400: "#62A583",
          500: "#499873",
          600: "#2F6C51",
          700: "#265641",
          800: "#204536",
          900: "#1B392D",
        },
        success: {
          50: "#f0fdf4",
          100: "#dcfce7",
          200: "#bbf7d0",
          300: "#86efac",
          400: "#4ade80",
          500: "#22c55e",
          600: "#16a34a",
          700: "#15803d",
          800: "#166534",
          900: "#14532d",
        },
        warning: {
          50: "#FEFAF6",
          100: "#FDF0E1",
          200: "#FCE1C2",
          300: "#F3C896",
          400: "#EA9A3E",
          500: "#9A5B13",
          600: "#72430D",
          700: "#492B08",
          800: "#2E1B05",
          900: "#1D1002",
        },
        danger: {
          50: "#FEF6F6",
          100: "#FCE4E4",
          200: "#F7C5C5",
          300: "#F09898",
          400: "#E47474",
          500: "#C41C1C",
          600: "#A51818",
          700: "#7D1212",
          800: "#430A0A",
          900: "#240505",
        },
        neutral: {
          // 50: "#FBFCFE",
          50: "#f00",
          100: "#F0F4F8",
          200: "#DDE7EE",
          300: "#CDD7E1",
          400: "#9FA6AD",
          500: "#636B74",
          600: "#555E68",
          700: "#32383E",
          800: "#171A1C",
          900: "#0B0D0E",
        },
        others: {
          // This is for light theme.
          order_sales: "#B9EEEB",
          order_purchase: "#FCE1C2",
          order_draft: "#F0F4F8",
          disabled: "#636B744D",
          success: "#E3FBE3",
          neutral_dark: "#475467",
          neutral_light: "#F9FAFB",
          secondary_text: "#667085",
        },
        text: {
          secondary: "#555E68",
        },
      },
    },
    dark: {
      palette: {
        common: {
          black: "#000",
          white: "#FFF",
        },
        primary: {
          50: "#F1F8F4",
          100: "#DDEEE3",
          200: "#BDDDCA",
          300: "#91C4A9",
          400: "#62A583",
          500: "#499873",
          600: "#2F6C51",
          700: "#265641",
          800: "#204536",
          900: "#1B392D",
        },
        success: {
          50: "#f0fdf4",
          100: "#dcfce7",
          200: "#bbf7d0",
          300: "#86efac",
          400: "#4ade80",
          500: "#22c55e",
          600: "#16a34a",
          700: "#15803d",
          800: "#166534",
          900: "#14532d",
        },
        warning: {
          50: "#fff7ed",
          100: "#ffedd5",
          200: "#fed7aa",
          300: "#fdba74",
          400: "#fb923c",
          500: "#f97316",
          600: "#ea580c",
          700: "#c2410c",
          800: "#9a3412",
          900: "#7c2d12",
        },
        others: {
          // This is for dark theme.
          order_sales: "#B9EEEB",
          order_purchase: "#FCE1C2",
          order_draft: "#F0F4F8",
          disabled: "#636B744D",
          success: "#E3FBE3",
          neutral_dark: "#475467",
          neutral_light: "#F9FAFB",
          secondary_text: "#667085",
        },
        text: {
          secondary: "#555E68",
        },
      },
    },
  },
  focus: {},
  fontFamily: {
    display: "Plus Jakarta Sans", // applies to `h1`–`h4`
    body: "Plus Jakarta Sans", // applies to `title-*` and `body-*`
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "4px 16px",
          border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
          borderRadius: "8px",
          minHeight: "56px",
          fontSize: "16px",
          ...(ownerState.size === "sm" && {
            minHeight: "40px",
            fontSize: "14px",
          }),
          ...(ownerState.variant === "linkz-input-search" && {
            borderRadius: "999px",
            padding: "4px 16px",
            // backgroundColor: theme.vars.palette.common.white + " !important",
          }),
          ...(ownerState.color === "danger" && {
            border: `1px solid ${theme.vars.palette.danger[500]}`,
            "&:hover, &:not([data-skip-inverted-colors])": {
              "--Input-focusedHighlight": `${theme.vars.palette.danger[500]}`,
              "--Input-focusedThickness": `1px`,
            },
          }),
        }),
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          padding: "4px 16px",
          border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
          borderRadius: "8px",
          minHeight: "56px",
          fontSize: "16px",
          ...(ownerState.size === "sm" && {
            minHeight: "40px",
            height: "40px",
            fontSize: "14px",
          }),
          ...(ownerState.variant === "linkz-autocomplete-rounded" && {
            borderRadius: "999px",
            padding: "16px 24px",
          }),
          ...(ownerState.color === "danger" && {
            border: `1px solid ${theme.vars.palette.danger[500]}`,
            "&:hover, &:not([data-skip-inverted-colors])": {
              "--Input-focusedHighlight": `${theme.vars.palette.danger[500]}`,
              "--Input-focusedThickness": `1px`,
            },
          }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: "999px",
          padding: "10px 16px",
          minWidth: "101px",
          minHeight: "40px",
          fontSize: 14,
          transition: "all 0.1s ease-in-out",
          ...(ownerState.size === "lg" && {
            minHeight: "48px",
            fontSize: 16,
          }),
          ...(ownerState.variant === "outlined" && {
            borderWidth: "2px",
            "&:hover": {
              backgroundColor: theme.vars.palette.primary[500],
              borderColor: theme.vars.palette.primary[500],
              color: "white",
            },
          }),
          ...(ownerState.variant === "plain-gray" && {
            borderWidth: "2px",
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[700],
            },
          }),
          ...(ownerState.variant === "outlined-gray" && {
            borderColor: theme.vars.palette.neutral[700],
            border: "2px solid",
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[700],
            },
          }),
          ...(ownerState.variant === "primary-light" && {
            backgroundColor: theme.vars.palette.primary[200],
            color: theme.vars.palette.common.black,
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.primary[300],
              color: theme.vars.palette.common.black,
            },
          }),
          ...(ownerState.variant === "filter-outlined" && {
            color: theme.vars.palette.others.neutral_dark,
            borderColor: `${theme.vars.palette.others.neutral_dark} !important`,
            border: "1px solid",
            borderRadius: 8,
            maxHeight: 40,
            gap: "8px",
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[700],
            },
          }),
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "4px 16px",
          borderRadius: "8px",
          minHeight: "56px",
          fontSize: "16px",
          ...(ownerState.size === "sm" && {
            minHeight: "40px",
            height: "40px",
            fontSize: "14px",
          }),
          ...(ownerState.variant === "linkz-select-rounded" && {
            borderRadius: "999px",
            padding: "16px 24px",
          }),
        }),
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === "linkz-sidebar" && {
            "&": {
              backgroundColor: theme.vars.palette.primary[50],
            },
            [`& .${listItemButtonClasses.root}:hover`]: {
              backgroundColor: `${theme.vars.palette.primary[100]} !important`,
            },
            [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]:
              {
                backgroundColor: `${theme.vars.palette.primary[400]} !important`,
                color: "white",
              },
            [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected} p, 
          & .${listItemButtonClasses.root}.${listItemButtonClasses.selected} svg`]:
              {
                // color: theme.vars.palette.primary[500],
                color: "white",
              },
          }),
        }),
      },
    },
    JoyDrawer: {
      styleOverrides: {
        content: ({ ownerState }) => ({
          ...(ownerState.size === "lg" && {
            height: "100%",
            position: "fixed",
          }),
        }),
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === "danger" && {
            color: theme.vars.palette.danger[500],
          }),
        }),
      },
    },
  },
});

/* Variant Declarations */
declare module "@mui/joy/Sheet" {
  interface SheetPropsVariantOverrides {
    "linkz-sidebar": true;
  }
}

declare module "@mui/joy/Input" {
  interface InputPropsVariantOverrides {
    "linkz-input-search": true;
  }
}

declare module "@mui/joy/Select" {
  interface SelectPropsVariantOverrides {
    "linkz-select-rounded": true;
  }
}

declare module "@mui/joy/Autocomplete" {
  interface AutocompletePropsVariantOverrides {
    "linkz-autocomplete-rounded": true;
  }
}

declare module "@mui/joy/Button" {
  interface ButtonPropsVariantOverrides {
    "plain-gray": true;
  }

  interface ButtonPropsVariantOverrides {
    "outlined-gray": true;
  }

  interface ButtonPropsVariantOverrides {
    "primary-light": true;
  }

  interface ButtonPropsVariantOverrides {
    "filter-outlined": true;
  }
}

declare module "@mui/joy/styles" {
  interface Palette {
    others: {
      order_sales: string;
      order_purchase: string;
      order_draft: string;
      disabled: string;
      success: string;
      neutral_dark: string;
      neutral_light: string;
      secondary_text: string;
    };
  }
}
