import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import Buildings from "../../../components/Icons/Buildings";
import CurrencyDolar from "../../../components/Icons/CurrencyDolar";
import Puzzle from "../../../components/Icons/Puzzle";
import Shipping from "../../../components/Icons/Shipping";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { KeyboardArrowDown } from "@mui/icons-material";
import startsWith from "lodash.startswith";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { If } from "../../../components/Condition";
import { countries } from "../../../components/Countries";
import FlagById from "../../../components/FlagById";
import CoinStack from "../../../components/Icons/CoinStack";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../../components/SuccessModal";
import UploadImage from "../../../components/UploadImage";
import { SnackBarInfo } from "../../../components/snackbar";
import { animateSelectStyle } from "../../../styles/select";
import {
  APPLICABLE_ADMIN_FEE_OPTIONS,
  ApplicableAdminFeeTypes,
} from "../../../types/applicable-admin-fee.enum";
import {
  DefaultCurrencies,
  PhNoWhiteListedCountries,
  WhiteListedCountries,
} from "../../../utils/constants";
import { matchesFormat } from "../../../utils/misc";
import { TaxItem } from "./TaxItem";
import useBusinessContainer from "./businessContainer";

export default function BusinessForm({ isEditMode = false }) {
  const {
    imgExistanceCheckQuery,
    business,
    defaultTaxId,
    taxOptions,
    errors,
    companyIcon,
    loading,
    isSuccess,
    editProfileOnly,
    register,
    handleSetCompanyIcon,
    watch,
    handleSubmit,
    onSubmit,
    setValue,
    setError,
    selectedTaxes,
    setSelectedTaxes,
    companyCountry,
    setDefaultTaxId,
    remoteImage,
  } = useBusinessContainer({ isEditMode });

  const { t } = useTranslation();
  const defaultCurrency = DefaultCurrencies.find(
    (cur) => cur.country === business?.companyCountry
  );
  const defaultCompanyIcon = companyIcon || remoteImage || null;
  return (
    <form
      id="businessForm"
      onSubmit={handleSubmit(onSubmit)}
      className="w-[90vw] lg:w-[100%]"
    >
      <Stack direction={"column"} spacing={5}>
        {/* Company Icon/Logo */}
        <If condition={!editProfileOnly || !!imgExistanceCheckQuery.data}>
          <UploadImage
            file={defaultCompanyIcon}
            handleFile={handleSetCompanyIcon}
          />
        </If>
        {/* Company Info */}
        <Typography startDecorator={<Buildings />} fontWeight={"lg"}>
          {t("profile.business.companyInfo")}
        </Typography>
        <FormControl error={!!errors.companyName}>
          <FormLabel>{t("profile.business.companyName")}</FormLabel>
          <Input
            placeholder={t("profile.business.companyName")}
            sx={{ backgroundColor: "white" }}
            {...register("companyName")}
          />
          {errors.companyName?.message && (
            <FormHelperText>{errors.companyName?.message}</FormHelperText>
          )}
        </FormControl>
        {/* <FormControl error={!!errors.companyEmail}>
          <FormLabel>{t("profile.business.companyEmail")}</FormLabel>
          <Input
            placeholder={t("profile.business.companyEmail")}
            sx={{ backgroundColor: "white" }}
            {...register("companyEmail")}
          />
          {errors.companyEmail?.message && (
            <FormHelperText>{errors.companyEmail?.message}</FormHelperText>
          )}
        </FormControl> */}
        <FormControl error={!!errors.companyNumber}>
          <FormLabel>{t("profile.business.companyNumber")}</FormLabel>
          <PhoneInput
            value={
              isEditMode
                ? watch("companyNumber") &&
                  `+${watch("companyNumberCountryCode")}${watch(
                    "companyNumber"
                  )}`
                : ""
            }
            onlyCountries={PhNoWhiteListedCountries}
            enableTerritories={false}
            masks={{
              my: "..-....-....",
              sg: "....-....",
              th: "... ... ...",
              id: "... ... ... ...",
            }}
            isValid={(inputNumber, _: any, countries: any) => {
              if (inputNumber === "") {
                return true;
              }
              const result = (countries || []).some((country: any) => {
                return (
                  (startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)) &&
                  matchesFormat(
                    inputNumber,
                    country.format,
                    country.countryCode
                  )
                );
              });
              if (!result && errors.companyNumber?.message === null) {
                setError("companyNumber", {
                  message: "invalid mobile number.",
                });
              }
              return result;
            }}
            inputStyle={{
              height: "42px",
              width: "100%",
              fontSize: "16px",
            }}
            onChange={(phone, meta: any) => {
              setValue("companyNumberCountryCode", meta.dialCode);
              setValue(
                "companyNumber",
                phone.replace(new RegExp(meta.dialCode), "")
              );
            }}
          />
          {errors.companyNumber?.message && (
            <FormHelperText>{errors.companyNumber?.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl error={!!errors.companyWebsite}>
          <FormLabel>{t("profile.business.companyWebsite")}</FormLabel>
          <Input
            placeholder={t("profile.business.companyWebsite")}
            sx={{ backgroundColor: "white" }}
            {...register("companyWebsite")}
          />
          {errors.companyWebsite?.message && (
            <FormHelperText>{errors.companyWebsite?.message}</FormHelperText>
          )}
        </FormControl>
        {/* Company Address */}
        <Typography startDecorator={<Shipping />} fontWeight={"lg"}>
          {t("profile.business.companyAddress")}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", lg: "row" }}
          gap={2}
        >
          <FormControl sx={{ flex: 1 }} error={!!errors.companyCountry}>
            <FormLabel>{t("profile.business.country")}</FormLabel>

            <Select
              placeholder={t("profile.business.country")}
              indicator={<KeyboardArrowDown />}
              onChange={(_, value) => {
                if (value) setValue("companyCountry", value);
              }}
              renderValue={(option) => (
                <Typography
                  startDecorator={<FlagById id={option?.value ?? ""} />}
                >
                  {option?.label}
                </Typography>
              )}
              value={watch("companyCountry")}
              sx={{ ...animateSelectStyle, backgroundColor: "white" }}
            >
              {countries
                .filter((country) =>
                  WhiteListedCountries.includes(country.code)
                )
                .map((country) => (
                  <Option
                    key={country.code}
                    value={country.code}
                    label={country.label}
                  >
                    <Typography startDecorator={<FlagById id={country.code} />}>
                      {country.label}
                    </Typography>
                  </Option>
                ))}
            </Select>
            {errors.companyCountry?.message && (
              <FormHelperText>{errors.companyCountry?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={!!errors.companyProvince}>
            <FormLabel>{t("profile.business.province")}</FormLabel>
            <Input
              placeholder={t("profile.business.province")}
              sx={{ backgroundColor: "white" }}
              {...register("companyProvince")}
            />
            {/* <Select>
                        </Select> */}
            {errors.companyProvince?.message && (
              <FormHelperText>{errors.companyProvince?.message}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <FormControl error={!!errors.companyAddress}>
          <FormLabel>{t("profile.business.address")}</FormLabel>
          <Input
            placeholder={t("profile.business.address")}
            sx={{ backgroundColor: "white" }}
            {...register("companyAddress")}
          />
          {errors.companyAddress?.message && (
            <FormHelperText>{errors.companyAddress?.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl error={!!errors.companyPostalCode}>
          <FormLabel>{t("profile.business.postalCode")}</FormLabel>
          <Input
            placeholder={t("profile.business.postalCode")}
            sx={{ backgroundColor: "white" }}
            {...register("companyPostalCode")}
          />
          {errors.companyPostalCode?.message && (
            <FormHelperText>{errors.companyPostalCode?.message}</FormHelperText>
          )}
        </FormControl>
        {/* Payment Info */}
        <Typography startDecorator={<CurrencyDolar />} fontWeight={"lg"}>
          {t("profile.business.paymentInfo")}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", lg: "row" }}
          gap={2}
        >
          <FormControl
            className="hidden"
            sx={{ flex: 1 }}
            error={!!errors.companyCurrency}
          >
            <FormLabel>{t("profile.business.companyCurrency")}</FormLabel>
            <Select
              disabled={true}
              required={false}
              placeholder={t("profile.business.companyCurrency")}
              indicator={<KeyboardArrowDown />}
              onChange={(_, value) => {
                if (value) setValue("companyCurrency", value);
              }}
              value={watch("companyCurrency") ?? defaultCurrency?.id}
              sx={{ ...animateSelectStyle, backgroundColor: "white" }}
            >
              {DefaultCurrencies.map((currency) => (
                <Option key={currency.id} value={currency.id}>
                  {currency.label}
                </Option>
              ))}
            </Select>
            {errors.companyCurrency?.message && (
              <FormHelperText>{errors.companyCurrency?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={!!errors.companyBankName}>
            <FormLabel>{t("profile.business.companyBankName")}</FormLabel>
            <Input
              placeholder={t("profile.business.companyBankName")}
              sx={{ backgroundColor: "white" }}
              {...register("companyBankName")}
            />
            {errors.companyBankName?.message && (
              <FormHelperText>{errors.companyBankName?.message}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <FormControl error={!!errors.companyBankAccountNo}>
          <FormLabel>{t("profile.business.companyBankAccountNo")}</FormLabel>
          <Input
            placeholder={t("profile.business.companyBankAccountNo")}
            sx={{ backgroundColor: "white" }}
            {...register("companyBankAccountNo")}
          />
          {errors.companyBankAccountNo?.message && (
            <FormHelperText>
              {errors.companyBankAccountNo?.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={!!errors.companyBankAccountHolderName}>
          <FormLabel>
            {t("profile.business.companyBankAccountHolderName")}
          </FormLabel>
          <Input
            placeholder={t("profile.business.companyBankAccountHolderName")}
            sx={{ backgroundColor: "white" }}
            {...register("companyBankAccountHolderName")}
          />
          {errors.companyBankAccountHolderName?.message && (
            <FormHelperText>
              {errors.companyBankAccountHolderName?.message}
            </FormHelperText>
          )}
        </FormControl>
        {/* Admin Fee */}
        <Typography startDecorator={<CoinStack />} fontWeight={"lg"}>
          {t("profile.business.adminFee")}
        </Typography>
        <FormControl error={!!errors.applicableAdminFee}>
          <Card>
            <Stack gap={1}>
              {!isEditMode && (
                <SnackBarInfo message="Choose who pays the admin fee. By default, it's charged to the Seller." />
              )}
              <RadioGroup
                value={
                  watch("applicableAdminFee") ??
                  ApplicableAdminFeeTypes.CHARGE_TO_SELLER
                }
                onChange={(_event) =>
                  setValue("applicableAdminFee", _event.target.value)
                }
                sx={{ display: "flex", gap: 1 }}
              >
                {APPLICABLE_ADMIN_FEE_OPTIONS.map((option) => (
                  <Radio
                    sx={{ padding: 1 }}
                    value={option.value}
                    label={t(option.labelKey)}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </Card>
          <FormHelperText>{errors.applicableAdminFee?.message}</FormHelperText>
        </FormControl>
        {/* Payment Terms */}
        <Typography startDecorator={<Puzzle />} fontWeight={"lg"}>
          {t("profile.business.paymentTerms")}
        </Typography>
        {(watch("companyPaymentTerms") ?? []).map((term, index) => (
          <div key={index}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography key={index} fontWeight={"lg"}>
                {t("profile.business.paymentTerms")} {index + 1}
              </Typography>
              <Button
                color="danger"
                variant="plain"
                onClick={() => {
                  setValue(
                    "companyPaymentTerms",
                    watch("companyPaymentTerms")?.filter(
                      (_, i) => i !== index
                    ) ?? []
                  );
                }}
              >
                {t("profile.business.removePaymentTrmBtn")}
              </Button>
            </Stack>
            <FormControl key={index}>
              <FormLabel>{t("profile.business.paymentTerms")}</FormLabel>
              <Input
                placeholder={t("profile.business.paymentTerms")}
                sx={{ backgroundColor: "white" }}
                value={term}
                onChange={(e) =>
                  setValue(`companyPaymentTerms.${index}`, e.target.value)
                }
              />
            </FormControl>
          </div>
        ))}
        {errors.companyPaymentTerms?.message && (
          <FormHelperText>{errors.companyPaymentTerms?.message}</FormHelperText>
        )}
        <Button
          variant="outlined"
          sx={{ height: 40, maxHeight: 40, width: 150, maxWidth: 150 }}
          onClick={() => {
            setValue("companyPaymentTerms", [
              ...(watch("companyPaymentTerms") ?? []),
              "",
            ]);
          }}
        >
          {t("profile.business.addPaymentTrmBtn")}
        </Button>
        <If condition={!editProfileOnly}>
          <Stack gap={6} direction="column">
            <Typography startDecorator={<Puzzle />} fontWeight={"lg"}>
              {t("profile.business.taxSettings.title")}
            </Typography>

            <Stack
              gap={"20px"}
              padding={4}
              borderRadius={8}
              border={"1px solid #D0D5DD"}
              boxShadow={"0px 1px 3px 0px #1018281A"}
            >
              {companyCountry && taxOptions.length > 0 && (
                <Stack
                  padding={"12px"}
                  gap="10px"
                  borderRadius={"8px"}
                  flexDirection={"row"}
                  className="bg-[#CFE5DA]"
                >
                  <ExclamationCircleIcon width={24} height={24} />
                  <Typography>You can add more than one tax options</Typography>
                </Stack>
              )}

              {!companyCountry ? (
                <Typography className="w-full" textAlign={"center"}>
                  {t("profile.business.taxSettings.countryPrompt")}
                </Typography>
              ) : companyCountry && taxOptions.length === 0 ? (
                <Typography className="w-full text-center">
                  {t("profile.business.taxSettings.adminPrompt")}
                </Typography>
              ) : (
                <Stack
                  rowGap={1}
                  flexWrap={"wrap"}
                  direction={{ xs: "column", lg: "row" }}
                >
                  {taxOptions.map((tax, index) => (
                    <TaxItem
                      defaultTaxId={defaultTaxId}
                      index={index}
                      key={tax.id}
                      tax={tax}
                      selectedTaxes={selectedTaxes}
                      setSelectedTaxes={setSelectedTaxes}
                      setDefaultTaxId={setDefaultTaxId}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </If>
        <Stack justifyContent={"center"} direction={"row"}>
          <Button
            variant="solid"
            type="submit"
            sx={{ height: 48, maxHeight: 48, width: 300, maxWidth: 300 }}
          >
            {isEditMode
              ? t("profile.business.editCompanyBtnSubmit")
              : t("profile.business.addCompanyBtnSubmit")}
          </Button>
        </Stack>
      </Stack>
      <SuccessModal
        open={isSuccess}
        title={t("commons.successModalTitle")}
        content={`${
          isEditMode
            ? t("profile.business.editBusinessSuccess")
            : t("profile.business.addBusinessSuccess")
        }`}
      />
      <LoadingModal isLoading={loading} />
    </form>
  );
}
