import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Divider,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import CopyLinkButton from "../../components/CopyLinkButton";
import ReferralCards from "../../components/Referrals/ReferralCards";
import ReferralTable from "../../components/Referrals/ReferralTable";
import { ReferralsService } from "../../services/referrals.service";
import { useAuthStore } from "../../store/session";
import { referralStatuses } from "../../types/referral";
import { useTranslation } from "react-i18next";

export default function Referrals() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [selectedReferralStatusFilter, setSelectedReferralStatusFilter] =
    useState("");
  const [sortByDate, setSortByDate] = useState("DESC");
  const { data: referralsList } = useQuery({
    queryKey: ["referrals"],
    queryFn: () => ReferralsService.getReferrals(),
  });
  const { session } = useAuthStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const referralUrl = `${
    import.meta.env.VITE_LANDING_URL
  }/signup?referrer_user_id=${session?.account.id}&referral_code=${
    session?.activeBusiness.referralCode
  }`;

  const paramStatus = searchParams.get("status");
  const paramSortByDate = searchParams.get("sortByDate");

  const handleOnChangeReferralStatusFilter = (newValue: any) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("status", newValue);
    } else {
      newParams.delete("status");
    }
    setSearchParams(newParams);
    setSelectedReferralStatusFilter(newValue);
  };

  const handleOnChangeSortByDate = (newValue: any) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("sortByDate", newValue);
    } else {
      newParams.delete("sortByDate");
    }
    setSearchParams(newParams);
    setSortByDate(newValue);
  };

  useEffect(() => {
    if (paramStatus) {
      setSelectedReferralStatusFilter(paramStatus);
    }
    if (paramSortByDate) {
      setSortByDate(paramSortByDate);
    }
  }, [searchParams]);

  const filteredReferralList = referralsList
    ?.filter((referral: any) => {
      return selectedReferralStatusFilter
        ? referral.status === selectedReferralStatusFilter
        : true;
    })
    .sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt) as any;
      const dateB = new Date(b.updatedAt) as any;
      if (sortByDate === "DESC") {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });

  return (
    <Fragment>
      {/* Upper Part */}
      <Stack direction={"row"} gap={4} flexWrap={{ xs: "wrap", lg: "nowrap" }}>
        {/* Left Column */}
        <Stack spacing={2} flex={{ xs: "1 1 100%", lg: "1 1 50%" }}>
          <Typography level="h4">{t("referral.title")}</Typography>
          <Typography>{t("referral.subTitle")}</Typography>
          {/* <Typography>
            <Typography fontWeight={"bold"}>
              {t("referral.referrerUser")}:
            </Typography>{" "}
            {session?.profile?.fullName}
          </Typography> */}
          {/* <Typography>
            <Typography fontWeight={"bold"}>
              {t("referral.referrerCode")}:
            </Typography>{" "}
            {session?.activeBusiness.referralCode}
          </Typography> */}
          <Input
            value={referralUrl}
            // disabled={true}
            readOnly={true}
          />
          <Stack direction={"row"} spacing={2} justifyContent={"center"}>
            <CopyLinkButton
              url={referralUrl}
              variant="solid"
              // isFullWidth
            />
            {/* <Button variant="solid" startDecorator={<WhatsApp />} fullWidth onClick={() => {}}>
              Share via Whatsapp
            </Button> */}
          </Stack>
        </Stack>

        <Divider
          sx={{
            flex: isMobile ? "1 1 100%" : "1 1 auto",
            minHeight: "1px",
          }}
          orientation={isMobile ? "horizontal" : "vertical"}
        ></Divider>

        {/* Right Column  */}
        <Stack spacing={4} flex={{ xs: "1 1 100%", lg: "1 1 50%" }}>
          <Stack direction={"row"} gap={2}>
            <Stack>
              <Box
                component={"img"}
                sx={{ minWidth: 40, aspectRatio: "1/1" }}
                src="/img-referral-share-link.png"
              />
            </Stack>
            <Stack>
              <Typography level="title-lg">
                {t("referral.share.title")}
              </Typography>
              <Typography>{t("referral.share.subTitle")}</Typography>
            </Stack>
          </Stack>

          <Stack direction={"row"} gap={2}>
            <Stack>
              <Box
                component={"img"}
                sx={{ minWidth: 40, aspectRatio: "1/1" }}
                src="/img-referral-sign-up-with-code.png"
              />
            </Stack>
            <Stack>
              <Typography level="title-lg">
                {t("referral.signup.title")}
              </Typography>
              <Typography>{t("referral.signup.subTitle")}</Typography>
            </Stack>
          </Stack>

          <Stack direction={"row"} gap={2}>
            <Stack>
              <Box
                component={"img"}
                sx={{ minWidth: 40, aspectRatio: "1/1" }}
                src="/img-referral-success.png"
              />
            </Stack>
            <Stack>
              <Typography level="title-lg">
                {t("referral.success.title")}
              </Typography>
              <Typography>{t("referral.success.subTitle")}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider sx={{ my: 4 }}></Divider>

      {/* Lower Part - Referral History */}
      <Stack spacing={2}>
        <Typography level="h4">{t("referral.history")}</Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Select
            value={selectedReferralStatusFilter}
            size="sm"
            placeholder={t("order.filters.status.all")}
            onChange={(_, newValue) =>
              handleOnChangeReferralStatusFilter(newValue)
            }
          >
            <Option value="">{t("order.filters.status.all")}</Option>
            {referralStatuses.map((status) => (
              <Option value={status.value}>{status.label}</Option>
            ))}
          </Select>

          <Select
            size="sm"
            placeholder={"Sort By Date"}
            indicator={null}
            endDecorator={
              sortByDate === "DESC" ? (
                <BarsArrowDownIcon width={20} height={20} />
              ) : (
                <BarsArrowUpIcon width={20} height={20} />
              )
            }
            value={sortByDate}
            // renderValue={(option) => (isMobile ? "" : option?.label)}
            onChange={(_, value) => {
              handleOnChangeSortByDate(value);
            }}
          >
            <Option value="DESC">
              {t("order.sort.date.DESC")}{" "}
              <BarsArrowDownIcon width={20} height={20} />
            </Option>
            <Option value="ASC">
              {t("order.sort.date.ASC")}{" "}
              <BarsArrowUpIcon width={20} height={20} />
            </Option>
          </Select>
        </Stack>

        {isMobile ? (
          <ReferralCards referralsList={filteredReferralList} />
        ) : (
          <ReferralTable referralsList={filteredReferralList} />
        )}
      </Stack>
    </Fragment>
  );
}
