import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { Button, Input, Stack, Typography } from "@mui/joy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../store/session";
import { CartDataType, CartItemDataType } from "../../../types/cart";
import { CatalogType } from "../../../types/catalog";
import { CatalogInventoryItemType } from "../../../types/item";
import {
  calculatePrice,
  getConversionRate,
  getMinimumQuantity,
  updateCartQty,
  validateOnKeyDown,
} from "../../../utils/cart";
import { formatPrice } from "../../../utils/formatPrice";
import { theme } from "../../Theme";

interface Props {
  caller?: string;
  item: CatalogInventoryItemType;
  hidePerItemPrice?: boolean;
  hidePrice?: boolean;
  withAddToCart?: boolean;
  isDisabled?: boolean;
  catalog: CatalogType;
  updateQtyCallback?: (cartItems: CartDataType) => void;
  quantity?: number;
  updateCart?: boolean;
  defaultUOM?: "uom1" | "uom2" | "uom3";
  setToCart?: Dispatch<SetStateAction<CartItemDataType[]>>;
  resetFlag?: boolean;
}

const CartQuantityManager = ({
  caller,
  item,
  hidePrice = false,
  withAddToCart = false,
  isDisabled = false,
  catalog,
  updateQtyCallback,
  quantity = 0,
  updateCart = false,
  defaultUOM = "uom1",
  setToCart,
  resetFlag = false,
}: Props) => {
  const currency = item.currency ?? item.inventoryItem.currency;
  const { t } = useTranslation();
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");

  const [count, setCount] = useState(quantity);
  const [inputValue, setInputValue] = useState(quantity.toString());
  const [selectedUOM, setSelectedUOM] = useState<"uom1" | "uom2" | "uom3">(
    defaultUOM
  );
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setSelectedUOM(defaultUOM);
  }, [defaultUOM]);

  useEffect(() => {
    if (selectedUOM && setToCart) {
      setToCart((prev) => {
        if (realCount(count, defaultUOM) < getMinimumQuantity(item)) {
          return prev.filter((p) => p.cid !== item.id);
        }

        const cartItem = {
          cid: item.id,
          quantity: count,
          uom: selectedUOM,
          initPrice: calculatePrice(item, selectedUOM),
        };
        const currentIndex = prev.findIndex((p) => p.cid === item.id);
        if (currentIndex !== -1) {
          return [
            ...prev.slice(0, currentIndex),
            cartItem,
            ...prev.slice(currentIndex + 1),
          ];
        } else {
          return [...prev, cartItem];
        }
      });
    }
  }, [selectedUOM, setToCart]);

  useEffect(() => {
    if (withAddToCart) {
      setInputValue("0");
      setCount(0);
    }
  }, [withAddToCart, resetFlag]);

  const { session } = useAuthStore.getState();

  const realCount = (currentCount: number, uom: "uom1" | "uom2" | "uom3") => {
    console.log(
      "BANANA COUNT VALUE",
      currentCount * getConversionRate(uom, item.inventoryItem)
    );
    return currentCount * getConversionRate(uom, item.inventoryItem);
  };

  const handleSetToCartIncrease = (decreaseValue = 1) => {
    if (setToCart) {
      setToCart((prev) => {
        if (realCount(count + decreaseValue, defaultUOM) < getMinimumQuantity(item)) {
          return prev.filter((p) => p.cid !== item.id);
        }

        const cartItem = {
          cid: item.id,
          quantity: count + decreaseValue,
          uom: selectedUOM,
          initPrice: calculatePrice(item, selectedUOM),
        };
        const currentIndex = prev.findIndex((p) => p.cid === item.id);
        if (currentIndex !== -1) {
          return [
            ...prev.slice(0, currentIndex),
            cartItem,
            ...prev.slice(currentIndex + 1),
          ];
        } else {
          return [...prev, cartItem];
        }
      });
    }
  };
  const handleIncrease = () => {
    setInputValue((prev) => String(Number(prev) + 1));
    setCount((prev) => prev + 1);
    handleSetToCartIncrease();
    if (updateCart) {
      updateCartQty({
        catalogId: catalog.id,
        accountId: session?.account.id || "wildcart",
        override: false,
        quantityChange: 1,
        inventoryItem: item,
        direct: session?.account.id ? false : true,
        activeUOM: selectedUOM,
        storageType: session?.account.id ? "local" : "session",
        callback: updateQtyCallback && updateQtyCallback,
      });
    }
  };
  const isProductCard = caller === "product-card";

  const handleSetToCartDecrease = (increaseValue = 1) => {
    if (setToCart) {
      setToCart((prev) => {
        if (
          count - increaseValue < 1 ||
          realCount(count, defaultUOM) < getMinimumQuantity(item)
        ) {
          return prev.filter((p) => p.cid !== item.id);
        }

        const cartItem = {
          cid: item.id,
          quantity: count - increaseValue,
          uom: selectedUOM,
          initPrice: calculatePrice(item, selectedUOM),
        };
        const currentIndex = prev.findIndex((p) => p.cid === item.id);
        if (currentIndex !== -1) {
          return [
            ...prev.slice(0, currentIndex),
            cartItem,
            ...prev.slice(currentIndex + 1),
          ];
        } else {
          return [...prev, cartItem];
        }
      });
    }
  };

  const handleDecrease = () => {
    if (count > 0) {
      setInputValue((prev) => String(Number(prev) - 1));
      setCount((prev) => prev - 1);

      if (updateCart) {
        updateCartQty({
          catalogId: catalog.id,
          accountId: session?.account.id || "wildcart",
          override: false,
          quantityChange: -1,
          inventoryItem: item,
          direct: session?.account.id ? false : true,
          activeUOM: selectedUOM,
          storageType: session?.account.id ? "local" : "session",
          callback: updateQtyCallback && updateQtyCallback,
        });
      }
    }
    handleSetToCartDecrease();
  };

  return isMobile ? (
    <div className="flex flex-col justify-center mx-auto">
      <Stack gap={2} direction="row" alignItems={"center"}>
        <Button
          className="p-1 min-w-fit min-h-fit"
          onClick={(event) => {
            event.stopPropagation();
            handleDecrease();
          }}
          disabled={isDisabled}
        >
          <RemoveOutlined />
        </Button>
        <Input
          disabled={isDisabled}
          // type="number"
          className="w-full text-center lg:w-24"
          placeholder={"0"}
          value={inputValue}
          slotProps={{
            input: { min: 0, onWheel: (e) => e.preventDefault() },
          }}
          onFocus={(_e) => {
            if (inputValue === "0") {
              setInputValue("");
            }
          }}
          sx={{
            [`&& .MuiInput-input`]: {
              textAlign: "center",
            },
          }}
          onBlur={(_e) => {
            if (!withAddToCart && count === 0) {
              updateCartQty({
                catalogId: catalog.id,
                accountId: session?.account.id || "wildcart",
                override: true,
                quantityChange: 0,
                inventoryItem: item,
                direct: session?.account.id ? false : true,
                activeUOM: selectedUOM,
                storageType: session?.account.id ? "local" : "session",
                callback: updateQtyCallback && updateQtyCallback,
              });
            }
          }}
          onChange={(e) => {
            let newValue = e.target.value;
            newValue = newValue.replace(",", ".");
            if (
              newValue.startsWith("0") &&
              !newValue.startsWith("0.") &&
              newValue.length > 1
            ) {
              newValue = newValue.replace(/^0+/, "");
            }

            if (!isNaN(Number(newValue)) || newValue === "") {
              if (newValue.includes(".")) {
                const [integerPart, decimalPart] = newValue.split(".");
                if (decimalPart.length > 2) {
                  newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                }
              }
              const diff = Number(newValue) - count;
              console.log("QTYMANAGER::count", count);
              console.log("QTYMANAGER::newValue", newValue);
              console.log("QTYMANAGER::diff", diff);

              setInputValue(newValue);
              setCount(Number(newValue));
              if (Number(newValue) > 0 && diff > 0) handleSetToCartIncrease(Math.abs(diff));
              else handleSetToCartDecrease(Math.abs(diff));
            }

            if (!withAddToCart) {
              updateCartQty({
                catalogId: catalog.id,
                accountId: session?.account.id || "wildcart",
                override: true,
                quantityChange: Number(newValue),
                inventoryItem: item,
                direct: session?.account.id ? false : true,
                activeUOM: selectedUOM,
                storageType: session?.account.id ? "local" : "session",
                deleteOnNull: false,
                callback: updateQtyCallback && updateQtyCallback,
              });
            }
          }}
          onKeyDown={(e) => {
            if (validateOnKeyDown(e.key)) {
              e.preventDefault();
            }
          }}
        />
        <Button
          className="p-1 min-w-fit min-h-fit"
          onClick={(event) => {
            event.stopPropagation();
            handleIncrease();
          }}
          disabled={isDisabled}
        >
          <AddOutlined />
        </Button>
      </Stack>
      {realCount(count, defaultUOM) < getMinimumQuantity(item) && count > 0 && (
        <Typography
          fontSize={12}
          mt={1}
          textAlign={"left"}
          sx={{
            color: theme.palette.danger[500],
          }}
        >
          {t("catalog.minimumQuantity")}
        </Typography>
      )}

      {/* {!isDisabled && (
        <Typography
          fontSize={16}
          fontWeight={600}
          my={2}
          textAlign={"left"}
          sx={{
            color: "black",
            wordBreak: "break-all",
          }}
        >
          <span className="mr-4 text-sm font-normal">
            {t("catalog.totalPrice")}
          </span>{" "}
          {`${currency} ${
            isGuestMode
              ? catalog.catalogType === "PRIVATE"
                ? "-"
                : hidePrice
                ? "-"
                : formatPrice(
                    count * calculatePrice(item, selectedUOM),
                    currency
                  )
              : hidePrice
              ? "-"
              : formatPrice(count * calculatePrice(item, selectedUOM), currency)
          }`}
        </Typography>
      )} */}

      {/* // TODO: Simplify this: */}
      {/* {withAddToCart &&
        realCount(count, defaultUOM) >= getMinimumQuantity(item) &&
        setCartUpdated &&
        !hidePrice && (
          <div className="flex justify-end pr-4 mt-3">
            <AddToCartBtn
              catalog={catalog}
              inventoryItem={item}
              setCartUpdated={setCartUpdated}
              quantity={count}
              selectedUOM={selectedUOM as "uom1" | "uom2" | "uom3"}
              unsavedOrderData={unsavedOrderData}
              orderDataCallback={orderDataCallback}
            />
          </div>
        )} */}
    </div>
  ) : (
    <div
      className={`flex flex-col my-auto justify-end gap-2 ${
        isProductCard ? "w-full" : "ml-auto"
      } `}
    >
      <Stack
        gap={"8px"}
        ml={caller !== "product-card" ? "auto" : ""}
        direction="row"
        alignItems={"center"}
      >
        <Button
          className="p-1 min-w-fit min-h-fit"
          onClick={(event) => {
            event.stopPropagation();
            handleDecrease();
          }}
          disabled={isDisabled}
        >
          <RemoveOutlined />
        </Button>
        <Input
          disabled={isDisabled}
          // type="number"
          className={`text-center ${
            isProductCard ? "w-full" : "w-full lg:w-36"
          } `}
          placeholder={"0"}
          value={inputValue}
          onFocus={(_e) => {
            if (inputValue === "0") {
              setInputValue("");
            }
          }}
          sx={{
            [`&& .MuiInput-input`]: {
              textAlign: "center",
            },
          }}
          onBlur={(_e) => {
            if (!withAddToCart && count < 0.1) {
              updateCartQty({
                catalogId: catalog.id,
                accountId: session?.account.id || "wildcart",
                override: true,
                quantityChange: 0,
                inventoryItem: item,
                direct: session?.account.id ? false : true,
                activeUOM: selectedUOM,
                storageType: session?.account.id ? "local" : "session",
                callback: updateQtyCallback && updateQtyCallback,
              });
            }
          }}
          onChange={(e) => {
            let newValue = e.target.value;
            newValue = newValue.replace(",", ".");
            if (
              newValue.startsWith("0") &&
              !newValue.startsWith("0.") &&
              newValue.length > 1
            ) {
              newValue = newValue.replace(/^0+/, "");
            }

            if (!isNaN(Number(newValue)) || newValue === "") {
              if (newValue.includes(".")) {
                const [integerPart, decimalPart] = newValue.split(".");
                if (decimalPart.length > 2) {
                  newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                }
              }
              const diff = Number(newValue) - count;
              console.log("QTYMANAGER::count", count);
              console.log("QTYMANAGER::newValue", newValue);
              console.log("QTYMANAGER::diff", diff);
              setInputValue(newValue);
              setCount(Number(newValue));
              if (Number(newValue) > 0 && diff > 0) handleSetToCartIncrease(Math.abs(diff));
              else handleSetToCartDecrease(Math.abs(diff));
            }

            if (!withAddToCart) {
              updateCartQty({
                catalogId: catalog.id,
                accountId: session?.account.id || "wildcart",
                override: true,
                quantityChange: Number(newValue),
                inventoryItem: item,
                direct: session?.account.id ? false : true,
                activeUOM: selectedUOM,
                storageType: session?.account.id ? "local" : "session",
                deleteOnNull: false,
                callback: updateQtyCallback && updateQtyCallback,
              });
            }
          }}
          onKeyDown={(e) => {
            if (validateOnKeyDown(e.key)) {
              e.preventDefault();
            }
          }}
        />
        <Button
          className="p-1 min-w-fit min-h-fit"
          onClick={(event) => {
            event.stopPropagation();
            handleIncrease();
          }}
          disabled={isDisabled}
        >
          <AddOutlined />
        </Button>
      </Stack>
      {realCount(count, defaultUOM) <
        (item.inventoryItem.minimumQuantity ?? 0) &&
        count > 0 && (
          <Typography
            fontSize={12}
            mt={1}
            textAlign={"right"}
            sx={{
              color: theme.palette.danger[500],
            }}
          >
            {t("catalog.minimumQuantity")}
          </Typography>
        )}

      <div
        className={`flex ${
          caller === "cart" ? "flex-col gap-[4px]" : "flex-row gap-4"
        } align-middle`}
      >
        <Typography
          fontSize={14}
          fontWeight={400}
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {t("catalog.totalPrice")}
        </Typography>
        <Typography
          fontSize={18}
          fontWeight={700}
          textAlign={"left"}
          sx={{
            color: "black",
            wordBreak: "break-all",
          }}
        >
          {`${currency} ${
            isGuestMode
              ? catalog?.catalogType === "PRIVATE"
                ? "-"
                : hidePrice
                ? "-"
                : formatPrice(
                    count * calculatePrice(item, selectedUOM),
                    currency
                  )
              : hidePrice
              ? "-"
              : formatPrice(count * calculatePrice(item, selectedUOM), currency)
          }`}
        </Typography>
      </div>

      {/* // TODO: Simplify this: */}
      {/* {withAddToCart &&
        realCount(count, defaultUOM) >= getMinimumQuantity(item) &&
        setCartUpdated &&
        !hidePrice && (
          <div className="flex justify-end w-full mt-3">
            <AddToCartBtn
              catalog={catalog}
              inventoryItem={item}
              setCartUpdated={setCartUpdated}
              quantity={count}
              selectedUOM={selectedUOM as "uom1" | "uom2" | "uom3"}
              unsavedOrderData={unsavedOrderData}
              orderDataCallback={orderDataCallback}
            />
          </div>
        )} */}
    </div>
  );
};

export default CartQuantityManager;
