import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Option,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { ItemService } from "../../../services/item.service";
import { ItemType } from "../../../types/item";
import { getActiveBusiness } from "../../../store/session";
import { formatPrice } from "../../../utils/formatPrice";
import TablePagination from "./TablePagination";
import useContainer from "../useContainer";
import { useSearchParams } from "react-router-dom";
import { InventoryService } from "../../../services/inventory.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "90%",
  px: "1.5rem",
  py: "1.5rem",
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
  overflow: "auto",
};

interface Props {
  show?: boolean;
  onClose: () => void;
  onConfirm: (newItems: ItemType[]) => void;
  existingItems: string[];
}

const BatchUpdateAddItemsModal = ({
  show = false,
  onClose,
  onConfirm,
  existingItems,
}: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const sourcePage = searchParams.get("catalog");

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryItems, setInventoryItems] = useState<ItemType[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currency, setCurrency] = useState<string | undefined>();
  const { pagination, setPagination } = useContainer();

  const initiateInventoryItems = useCallback((businessId: string) => {
    setIsLoading(true);

    if (sourcePage) {
      InventoryService.getCatalogItems({
        catalogId: sourcePage,
        paging: true,
        limit: 50,
        offset: 0,
        sortBy: "updatedAt",
      })
        .then((res) => {
          const processedData = res.data.map((d) => d.inventoryItem);
          setInventoryItems(processedData);

          if (res.pagination) {
            setPagination(res.pagination);
          }

          // set to catalog currency
          setCurrency(res.data[0].currency);

          return ItemService.getInventoryItemField({
            fields: ["inventoryType"],
            businessId,
          });
        })
        .then((filterRes) => {
          const cats: string[] = [];
          for (const element of filterRes) {
            cats.push(element.inventoryType);
          }

          const uniqueValues = cats.filter(
            (item, index) => cats.indexOf(item) === index
          );
          setCategories(uniqueValues);

          return ItemService.getInventoryItemField({
            fields: ["id", "sku", "productName"],
            businessId,
            isDistinct: false,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    } else {
      ItemService.getInventoryItems({
        businessId: businessId,
        paging: true,
        limit: 50,
        offset: 0,
        sortBy: "updatedAt",
      })
        .then((itemRes) => {
          setInventoryItems(itemRes.data);
          if (itemRes.pagination) {
            setPagination(itemRes.pagination);
          }

          return ItemService.getInventoryItemField({
            fields: ["inventoryType"],
            businessId,
          });
        })
        .then((filterRes) => {
          const cats: string[] = [];
          for (const element of filterRes) {
            cats.push(element.inventoryType);
          }

          const uniqueValues = cats.filter(
            (item, index) => cats.indexOf(item) === index
          );
          setCategories(uniqueValues);

          return ItemService.getInventoryItemField({
            fields: ["id", "sku", "productName"],
            businessId,
            isDistinct: false,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const fetchInventoryItems = useCallback(
    ({
      businessId,
      paging = true,
      limit = 50,
      offset = 0,
      searchTerm,
      inventoryType,
    }: {
      businessId: string;
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      inventoryType?: string | null;
    }) => {
      setIsLoading(true);
      ItemService.getInventoryItems({
        businessId: businessId,
        paging: paging,
        limit: limit,
        offset: offset,
        sortBy: "updatedAt",
        searchTerm,
        inventoryType,
      })
        .then((itemRes) => {
          setInventoryItems(itemRes.data);
          if (itemRes.pagination) {
            setPagination(itemRes.pagination);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    },
    [selectedFilter, searchTerm]
  );

  const fetchAllItems = useCallback(
    ({
      catalogId,
      paging = true,
      limit = 50,
      offset = 0,
      searchTerm,
      inventoryType,
    }: {
      catalogId: string;
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      inventoryType?: string | null;
    }) => {
      // console.log("BANANA IS THIS BEING TRIGGERED", sortBy);
      InventoryService.getCatalogItems({
        catalogId,
        paging: paging,
        limit: limit,
        offset: offset,
        sortBy: "updatedAt",
        searchTerm,
        inventoryType,
      })
        .then((res) => {
          const processedData = res.data.map((d) => d.inventoryItem);
          setInventoryItems(processedData);

          if (res.pagination) {
            setPagination(res.pagination);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    },
    []
  );

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      initiateInventoryItems(businessId);
    }
  }, [getActiveBusiness()?.id]);

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      ItemService.getInventoryItemField({
        fields: ["id"],
        businessId,
        inventoryType: selectedFilter,
        searchTerm,
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids.filter((i) => !existingItems.includes(i)));
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    }
  };

  const handleConfirm = () => {
    setIsLoading(true);
    ItemService.getInventoryItemsByIds({ ids: selectedItems })
      .then((res) => {
        onConfirm(res);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        setSelectedItems([]);
        setSelectedFilter("");
        setSearchTerm("");
        setShowModal(false);
      });
  };

  const handleCancel = () => {
    onClose();
    setSelectedItems([]);
    setSelectedFilter("");
    setSearchTerm("");
    setShowModal(false);
  };

  const handlePaging = (action: string | number) => {
    const pagingConfig = {
      limit: pagination.limit,
      offset: 0,
    };

    if (typeof action === "string") {
      switch (action) {
        case "first":
          pagingConfig.offset = 0;
          break;

        case "previous":
          pagingConfig.offset = pagination.limit * (pagination.current - 2);
          break;

        case "next":
          pagingConfig.offset = pagination.limit * pagination.current;
          break;

        case "last":
          pagingConfig.offset =
            pagination.max * pagination.limit - pagination.limit;
          break;
      }
    } else {
      pagingConfig.offset = (action - 1) * pagination.limit;
    }

    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      try {
        if (sourcePage) {
          fetchAllItems({
            catalogId: sourcePage,
            paging: true,
            ...pagingConfig,
          });
        } else {
        }
        fetchInventoryItems({
          businessId: businessId,
          paging: true,
          ...pagingConfig,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={"1rem"}
          mb={"1.5rem"}
          textAlign={"center"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.inventory.batchEdit.addItemsModal.title")}
        </Typography>

        <Box
          sx={{
            border: `1px solid #EAECF0`,
            borderRadius: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "1.5rem",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "end",
              gap: "1rem",
              justifyContent: "center",
            }}
          >
            <FormControl sx={{ flex: "1" }}>
              <FormLabel>
                {t("catalog.inventory.editForm.inventoryType")}
              </FormLabel>
              <Select
                onChange={(
                  _event: React.SyntheticEvent | null,
                  newValue: string | null
                ) => {
                  if (newValue || newValue === "") {
                    setSelectedFilter(newValue);
                  }
                }}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                size="sm"
                defaultValue={""}
                sx={{
                  paddingY: "10px",
                  paddingX: "16px",
                  gap: "8px",
                }}
                indicator={<ChevronDownIcon width={18} height={18} />}
              >
                <Option value={""}>
                  {t("catalog.inventory.allFilterBtn")}
                </Option>
                {categories.map((cat) => (
                  <Option key={cat} value={cat}>
                    {cat}
                  </Option>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ flex: "1" }}>
              <FormLabel>
                {t("catalog.inventory.editForm.productName")}
              </FormLabel>
              <Input
                size="sm"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value) {
                    setSearchTerm(event.target.value);
                  }
                }}
                placeholder={t("catalog.inventory.editForm.productName")}
                aria-label="productName"
              />
            </FormControl>

            <Button
              variant="outlined"
              onClick={() => {
                const businessId = getActiveBusiness()?.id;

                if (businessId) {
                  if (sourcePage) {
                    fetchAllItems({
                      catalogId: sourcePage,
                      paging: true,
                      limit: 50,
                      offset: 0,
                      searchTerm,
                      inventoryType: selectedFilter,
                    });
                  } else {
                    fetchInventoryItems({
                      businessId,
                      paging: true,
                      limit: 50,
                      offset: 0,
                      searchTerm,
                      inventoryType: selectedFilter,
                    });
                  }
                }
              }}
              startDecorator={<MagnifyingGlassIcon width={18} height={18} />}
            >
              {t("commons.searchBtn")}
            </Button>
          </Stack>

          {/* items table */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: { lg: "20rem" },
              overflow: "auto",
              borderWidth: "2px",
              position: "relative",
            }}
          >
            <Table
              borderAxis="x"
              sx={{
                "& th": { textAlign: "center" },
                minWidth: "100%",
              }}
            >
              <thead>
                <tr key={"header"}>
                  <th key={"checkbox"} className="w-12">
                    <Checkbox
                      size="sm"
                      onChange={(event) => {
                        if (event.target.checked) {
                          handleSelectAll();
                        } else {
                          setSelectedItems([]);
                        }
                      }}
                    />
                  </th>
                  <th key={"index"} className="w-12">
                    {t("catalog.items.no")}
                  </th>
                  <th key={"picture"} className="w-24">
                    {t("catalog.items.picture")}
                  </th>
                  <th key={"sku"} className="w-24">
                    {t("catalog.items.sku")}
                  </th>
                  <th key={"productName"} className="w-24">
                    {t("catalog.items.productName")}
                  </th>
                  <th key={"currency"} className="w-24">
                    {t("catalog.items.currency")}
                  </th>
                  <th key={"price"} className="w-24">
                    {t("catalog.items.price")}
                  </th>
                  <th key={"quantity"} className="w-24">
                    {t("catalog.items.quantity")}
                  </th>
                  <th key={"unit_of_measure"} className="w-24">
                    {t("catalog.items.uom")}
                  </th>
                  <th key={"inventoryType"} className="w-24">
                    {t("catalog.items.category")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {inventoryItems.map((i, index) => {
                  const isSelected = selectedItems.includes(i.id);
                  const isExisting = existingItems.includes(i.id);

                  return (
                    <tr
                      className={`${isSelected ? "bg-primary-50 " : ""} ${
                        isExisting ? "opacity-70" : ""
                      }`}
                    >
                      <td className="text-center">
                        <Checkbox
                          size="sm"
                          disabled={isExisting}
                          checked={isSelected || isExisting}
                          onChange={(e) => handleChecked(e, i.id)}
                        />
                      </td>
                      <td className="text-center">
                        {(pagination.current - 1) * pagination.limit +
                          (index + 1)}
                      </td>
                      <td className="text-center">
                        <div className="relative w-full flex justify-center py-3 cursor-pointer">
                          {i.thumbnail ? (
                            <img
                              src={i.thumbnail}
                              crossOrigin="anonymous"
                              width={30}
                              height={30}
                              className="object-contain"
                            />
                          ) : (
                            <img
                              src="/inventory-img-placeholder.png"
                              width={30}
                              height={30}
                              className="object-contain"
                            />
                          )}
                        </div>
                      </td>
                      <td className="text-center break-all">{i.sku}</td>
                      <td className="text-center break-all">{i.productName}</td>
                      <td className="text-center break-all">
                        {currency ?? i.currency}
                      </td>
                      <td className="text-center break-all">
                        {formatPrice(i.price, i.currency)}
                      </td>
                      <td className="text-center break-all">{i.quantity}</td>
                      <td className="text-center break-all">{i.uom1}</td>
                      <td className="text-center break-all">
                        {i.inventoryType}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>

          <TablePagination
            handlePaging={handlePaging}
            pagination={pagination}
            setPagination={setPagination}
            justifyContent="center"
          />
        </Box>

        <Stack
          direction={"row"}
          sx={{
            marginTop: "1.5rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={400} fontSize={14}>
            {selectedItems.length}{" "}
            {t("catalog.inventory.batchEdit.itemsSelected")}
          </Typography>

          <Stack direction={"row"} gap={"1.25rem"}>
            <Button
              type="button"
              variant="plain-gray"
              sx={{
                width: { xs: "100%", lg: "8rem" },
                textWrap: "nowrap",
              }}
              onClick={handleCancel}
            >
              {t("commons.cancelBtn")}
            </Button>
            <Button
              sx={{
                width: "100%",
                textWrap: "nowrap",
              }}
              onClick={handleConfirm}
            >
              {t(
                "catalog.inventory.batchEdit.addItemsModal.addItemSelectedBtn"
              )}
            </Button>
          </Stack>
        </Stack>

        <LoadingModal isLoading={isLoading} />
      </Box>
    </Modal>
  );
};

export default BatchUpdateAddItemsModal;
