import { PaperAirplaneIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Box, Button, Card, Stack } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { If } from "../../../components/Condition";
import showErrorToast, {
  ErrorToastConfig,
  SuccessToastConfig,
  showSuccessToast,
} from "../../../components/Error";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { BusinessContactService } from "../../../services/business-contact.service";
import { useAuthStore } from "../../../store/session";
import ContactForm from "./components/ContactForm";
import ShareContactModal from "./components/ShareContactModal";

export function EditBusinessContact() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuthStore();
  const { state } = useLocation();
  const [openShareModal, setOpenShareModal] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: BusinessContactService.deleteContact,
    onSuccess: () => {
      navigate(-1);
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const unshareContactMutation = useMutation({
    mutationFn: BusinessContactService.unshareContact,
    onSuccess: () => {
      navigate(-1);
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  const shareMutation = useMutation({
    mutationFn: BusinessContactService.shareContact,
    onSuccess: () => {
      toast(
        showSuccessToast("Contact shared successfully"),
        SuccessToastConfig
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });

  const isCreator = state?.contact?.createdBy?.id === auth.session?.account.id;

  const deleteContact = () => {
    if (isCreator || BusinessSessionHelper.IsOwner()) {
      deleteMutation.mutate(state?.contact.id);
    } else {
      unshareContact([state.contact.id]);
    }
  };

  const unshareContact = (ids: string[]) => {
    unshareContactMutation.mutate(ids);
  };

  const onFinishShare = (employeeIds: string[]) => {
    setOpenShareModal(false);
    shareMutation.mutate({
      id: state?.contact.id,
      employeeIds: employeeIds,
    });
  };

  if (!state?.contact) {
    return <Navigate to="/client/business-contact" />;
  }
  const isOwner = BusinessSessionHelper.IsOwner();
  return (
    <>
      <Stack gap={1} className="py-8">
        <If condition={isOwner || true}>
          <Box
            display={"flex"}
            justifyContent={{
              md: "flex-end",
              xs: "space-between",
            }}
            gap={1}
            alignItems={"center"}
          >
            {(BusinessSessionHelper.IsOwner() || isCreator) && (
              <Button
                onClick={deleteContact}
                size="sm"
                startDecorator={<TrashIcon className="w-[18px] h-[18px]" />}
                variant="outlined"
                color="neutral"
                className="text-[#B41F1C] text-sm border-[#B41F1C] border"
                sx={{ borderRadius: "10px", height: "40px" }}
              >
                {t("commons.removeBtn")}
              </Button>
            )}
            {(isOwner || isCreator) && (
              <Button
                onClick={() => {
                  setOpenShareModal(true);
                }}
                size="sm"
                className="text-sm"
                startDecorator={
                  <PaperAirplaneIcon className="w-[18px] h-[18px] -rotate-45" />
                }
                variant="outlined"
                color="primary"
                sx={{ borderRadius: "10px", height: "40px" }}
              >
                {t("commons.shareBtn")}
              </Button>
            )}
          </Box>
        </If>
        <Card>
          <ContactForm editMode={true} />
        </Card>
      </Stack>
      <ShareContactModal
        isLoading={shareMutation.isPending || unshareContactMutation.isPending}
        open={openShareModal}
        onClose={() => {
          setOpenShareModal(false);
        }}
        onFinish={onFinishShare}
        unshareContact={unshareContact}
      />
    </>
  );
}
