import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { OrderService } from "../../../services/order.service";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../Error";
import { OrderStatusEnum } from "../../../types/order";

export const useMultiPaymentContainer = ({
  orderId,
  orderStatus,
  currency,
  grandTotal,
  multiPaymentsData,
  isReadOnly,
  isSeller,
}: {
  orderId: string;
  orderStatus: OrderStatusEnum;
  currency: string;
  grandTotal: number;
  multiPaymentsData?: any;
  isReadOnly?: boolean;
  isSeller?: boolean;
}) => {
  const paymentHistorySchema = z.object({
    time: z.number(),
    amount: z.number(),
    percentage: z.number(),
    status: z.enum(["PENDING", "SUCCESS", "FAILED"]), // assuming these are the possible statuses
  });

  const paymentSchema = z.object({
    paymentTimes: z.number().default(2),
    paymentTypes: z.string().default("AMOUNT"), // assuming these are the possible payment types
    paymentHistory: z.array(paymentHistorySchema),
  });

  const { setValue, getValues, watch } = useForm<z.infer<typeof paymentSchema>>(
    {
      resolver: zodResolver(paymentSchema),
      defaultValues: {
        paymentTimes: 2,
        paymentTypes: "AMOUNT",
        paymentHistory: [
          { time: 1, amount: 0, percentage: 0, status: "PENDING" },
          { time: 2, amount: 0, percentage: 0, status: "PENDING" },
        ],
      },
    }
  );

  const [onRequestFullPayment, setOnRequestFullPayment] = useState(false);

  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(
    getValues("paymentTypes") ?? "AMOUNT"
  );
  const [onConfirmChangePaymentType, setOnConfirmChangePaymentType] =
    useState(false);

  const handleChangePaymentPlan = () => {
    setOnConfirmChangePaymentType(false);
    setValue("paymentTypes", selectedPaymentPlan);
    const currentPaymentTimes = getValues("paymentTimes");
    setValue("paymentTimes", 0);
    setValue(
      "paymentHistory",
      Array.from({ length: currentPaymentTimes }, (_, index) => ({
        time: index + 1,
        amount: 0,
        percentage: 0,
        status: "PENDING",
      }))
    );
    setValue("paymentTimes", currentPaymentTimes);
  };

  const handleCancelChangePaymentPlan = () => {
    setOnConfirmChangePaymentType(false);
    setSelectedPaymentPlan(getValues("paymentTypes"));
  };

  useEffect(() => {
    if (getValues("paymentTypes") !== selectedPaymentPlan)
      setOnConfirmChangePaymentType(true);
  }, [selectedPaymentPlan]);

  useEffect(() => {
    if (multiPaymentsData) {
      setValue("paymentHistory", multiPaymentsData.paymentHistory);
      setValue("paymentTimes", multiPaymentsData.paymentTimes);
      setValue("paymentTypes", multiPaymentsData.paymentTypes);
    }
  }, [multiPaymentsData]);

  const getNextPayment = () => {
    const paymentHistory = getValues("paymentHistory");
    const nextPayment = paymentHistory
      ?.sort((a, b) => a.time - b.time)
      ?.filter((payment) => payment.status === "PENDING")[0];
    return nextPayment;
  };

  const allowRequestFullPayment = () => {
    const paymentHistory = getValues("paymentHistory");
    const allowed = paymentHistory
      ?.map((payment) => payment.status)
      .includes("SUCCESS");
    return allowed;
  };

  const getPaidPaymentAmount = () => {
    const paymentHistory = getValues("paymentHistory");
    const paidAmount = paymentHistory?.reduce((total, payment: any) => {
      if (payment.status === "SUCCESS") {
        return total + payment.amount;
      }
      return total;
    }, 0);
    return paidAmount;
  };

  const cancelRequestFullPayment = () => {
    OrderService.cancelRequestFullPayment(orderId)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(showErrorToast(err), ErrorToastConfig);
      });
  };

  const isFullPaymentRequested = () => {
    return multiPaymentsData?.isFullPaymentRequested;
  };

  const getFullPaymentAmount = () => {
    console.log(
      "FULLAMOUNT",
      multiPaymentsData?.fullPaymentRequestData?.payableAmount
    );
    return multiPaymentsData?.fullPaymentRequestData?.payableAmount;
  };

  return {
    orderId,
    orderStatus,
    grandTotal,
    setValue,
    getValues,
    watch,
    currency,
    isReadOnly,
    getNextPayment,
    selectedPaymentPlan,
    setSelectedPaymentPlan,
    onConfirmChangePaymentType,
    handleChangePaymentPlan,
    handleCancelChangePaymentPlan,
    allowRequestFullPayment,
    onRequestFullPayment,
    setOnRequestFullPayment,
    getPaidPaymentAmount,
    isSeller,
    cancelRequestFullPayment,
    isFullPaymentRequested,
    getFullPaymentAmount,
  };
};
