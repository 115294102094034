import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import Shared from "../../layouts/Shared";
import { BrowserRouterConstants } from "../../utils/constants";
import useContainer from "./useContainer";

export default function Recover() {
  const { t } = useTranslation();

  const { email, errors, register, handleSubmit, onSubmit, isLoading } =
    useContainer();
  return (
    <>
      <Shared
        sxProps={{ backgroundColor: "transparent" }}
        // title="Forgot Password"
      >
        <Helmet
          link={[{ href: "https://linkzasia.com/forgot", rel: "canonical" }]}
          meta={[{ content: "Forgot", name: "description" }]}
          title="Forgot - Linkz"
        />
        <Box padding={4}>
          <Typography
            textAlign={"center"}
            fontSize={"18px"}
            fontWeight={600}
            py={2}
            textColor={"primary.solidActiveBg"}
          >
            {t("landing.forgotPassword.title")}
          </Typography>
          <Typography
            fontSize={"16px"}
            fontWeight={400}
            textColor={"text.primary"}
            marginY={"30px"}
          >
            {t("landing.forgotPassword.prompt")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack rowGap={1}>
              <Typography
                className={`transition-opacity duration-300 ${
                  email ? "opacity-100 h-auto" : "opacity-0 h-0"
                }`}
                fontSize={"md"}
                fontWeight={"500"}
              >
                {t("landing.signup.email", {
                  optional: "",
                })}
              </Typography>
              <Input
                placeholder={t("landing.signup.email", {
                  optional: "",
                })}
                required
                size="lg"
                sx={{ my: 2 }}
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email?.message ? (
                <Typography fontSize={"sm"} color="danger">
                  {errors.email?.message}
                </Typography>
              ) : null}
            </Stack>
            {/* <Input
          /> */}
            <Stack direction="row" marginTop={2} spacing={1}>
              <Typography fontSize="sm">
                {t("landing.forgotPassword.rememberPassword")}
              </Typography>
              <Link
                component={RouterLink}
                fontSize="sm"
                to={BrowserRouterConstants.Signin}
              >
                {t("landing.loginBtn")}
              </Link>
            </Stack>
            <Button
              disabled={isLoading}
              fullWidth
              size="lg"
              sx={{ mt: 7 }}
              type="submit"
            >
              {t("landing.forgotPassword.sendCodeBtn")}
            </Button>
          </form>
        </Box>
      </Shared>
      <LoadingModal isLoading={isLoading} />
    </>
  );
}
