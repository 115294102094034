import { Chip, Sheet, Table } from "@mui/joy";
import { referralStatuses } from "../../types/referral";
import { useAuthStore } from "../../store/session";
import { useTranslation } from "react-i18next";

export default function ReferralTable({ referralsList }: any) {
  const { session } = useAuthStore();
  const { t } = useTranslation();
  return (
    <Sheet>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader={true}
        hoverRow
        sx={{
          "--TableCell-headBackground": "var(--joy-palette-background-level1)",
          "--Table-headerUnderlineThickness": "1px",
          "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
          "--TableCell-paddingY": "4px",
          "--TableCell-paddingX": "8px",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr>
            {session?.activeBusiness.isOwner && (
              <th>{t("referral.inviter")}</th>
            )}
            <th>{t("referral.invitee")}</th>
            <th>{t("referral.companyName")}</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {referralsList &&
            referralsList?.map((referral: any) => (
              <tr>
                {session?.activeBusiness.isOwner && (
                  <td>{referral.referrerUser?.profile?.fullName}</td>
                )}
                <td>{referral.referredUser?.profile?.fullName}</td>
                <td>{referral.referredBusiness?.companyName}</td>
                <td>
                  <Chip
                    variant="soft"
                    size="sm"
                    sx={{
                      textWrap: "wrap",
                      padding: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {
                      referralStatuses.find(
                        (status) => status.value === referral.status
                      )?.label
                    }
                  </Chip>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Sheet>
  );
}
