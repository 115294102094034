import { privateAxios } from "../lib/api";
import { CreateEmployee } from "../types/employee";
import { ApiConstants } from "../utils/constants";

export class EmployeeService {
  static async create({
    payload,
    businessId,
  }: {
    payload: CreateEmployee;
    businessId: string;
  }) {
    const response = await privateAxios.post(
      ApiConstants.manageEmployeesURL,
      payload,
      { params: { businessId } }
    );
    return response.data;
  }

  static async getAll({ search }: { search?: string }) {
    const response = await privateAxios.get(ApiConstants.manageEmployeesURL, {
      params: { search },
    });
    return response.data;
  }

  static async remove({
    employeeId,
    businessId,
  }: {
    employeeId: string;
    businessId: string;
  }) {
    const response = await privateAxios.delete(
      ApiConstants.manageEmployeesURL + `/${employeeId}`,
      { params: { businessId } }
    );
    return response.data;
  }

  static async update({
    employeeId,
    businessId,
    payload,
  }: {
    employeeId: string;
    businessId: string;
    payload: CreateEmployee;
  }) {
    const response = await privateAxios.patch(
      ApiConstants.manageEmployeesURL + `/${employeeId}`,
      payload,
      { params: { businessId } }
    );
    return response.data;
  }

  static async cancelInvitation({
    employeeId,
    businessId,
  }: {
    employeeId: string;
    businessId: string;
  }) {
    const response = await privateAxios.patch(
      ApiConstants.manageEmployeesURL + `/${employeeId}/invitation-cancel`,
      {},
      { params: { businessId } }
    );
    return response.data;
  }

  static async resendInvitation({ email }: { email: string }) {
    const response = await privateAxios.post(
      ApiConstants.accountURL + `/invite`,
      { email }
    );
    return response.data;
  }

  static async resendInvitationByPhone({
    phoneNumber,
    phoneNumberCountryCode,
  }: {
    phoneNumber?: string;
    phoneNumberCountryCode?: string;
  }) {
    const response = await privateAxios.post(
      ApiConstants.accountURL + `/invite-by-phone`,
      { phoneNumber, phoneNumberCountryCode }
    );
    return response.data;
  }
}
