import { Input } from "@mui/joy";
import { FormControl, FormHelperText } from "@mui/joy";
import { useEffect, useState } from "react";

export const DecimalInput = ({
  disabled,
  startDecorator,
  onChange,
  endDecorator,
  defaultValue,
}: {
  disabled?: boolean;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  defaultValue?: string;
  onChange: (value: string) => void;
}) => {
  const [value, setValue] = useState(defaultValue ?? "");
  const [error, setError] = useState("");

  const handleInputChange = (event: any) => {
    let inputValue = event.target.value;
    if (inputValue?.[inputValue.length - 1] === ".") {
      inputValue = `${inputValue}0`;
    }
    const regex = /^[0-9]+(\.[0-9]+)?$/;
    if (regex.test(inputValue)) {
      setValue(inputValue);
      onChange(inputValue);
      setError("");
    } else {
      setError("Invalid decimal input");
    }
  };

  useEffect(() => {
    console.log("Profile value", value);
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  return (
    <FormControl error={!!error} sx={{ width: "100%" }}>
      <Input
        disabled={disabled}
        startDecorator={startDecorator}
        endDecorator={endDecorator}
        value={value}
        onChange={handleInputChange}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
