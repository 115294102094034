import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../../../components/Error";
// import { TRACKER_CONSTANTS } from "../../../../constants/tracker.events";
import { AuthService } from "../../../../services/auth.service";
// import { TrackerService } from "../../../../services/tracker.service";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../../../lib/firebase";

const generalSchema = z.object({
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  mobile: z
    .string()
    .min(
      1,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .max(
      100,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    ),
});

const useContainer = () => {
  // const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [urlSearchParams] = useSearchParams();
  // const token = urlSearchParams.get("token");
  // const callbackUrl = urlSearchParams.get("callbackURL");
  const navigate = useNavigate();

  const schema = generalSchema;

  const {
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    setValue,
    trigger,
    register,
    clearErrors,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const { mobile, country, agreed } = watch() as any;
  const mutation = useMutation({
    mutationFn: AuthService.sendFirebaseOtp,
    onSuccess: (data) => {
      if (data?.status === 200) {
        localStorage.setItem("countryCode", `+${country.code}`);
        localStorage.setItem("mobile", country.dial);
        localStorage.setItem("method", "signin-phone");

        setTimeout(() => {
          navigate(`/verify?${urlSearchParams.toString()}`);
        }, 4000);
      } else {
        setError(
          "mobile",
          {
            message: i18next.t("commons.error.form.mobileNotAvailable"),
            type: "manual",
          },
          { shouldFocus: true }
        );
        setIsLoading(false);
      }
      // if (data.token) {
      //   localStorage.setItem("phoneVerificationToken", data.token);
      //   localStorage.setItem("userSub", data.id);
      //   localStorage.setItem("email", email);
      //   localStorage.setItem("countryCode", country?.code.toString() ?? "");
      //   localStorage.setItem("mobile", mobile);
      //   setShowModal(true);
      //   setTimeout(() => {
      //     setShowModal(false);
      //     if (callbackUrl) {
      //       navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
      //     } else {
      //       navigate("/verify");
      //     }
      //   }, 4000);
      // }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data: any) => {
    // let payload = {
    //   mobile: `${data.country.code}${data.country.dial}`,
    // };
    setIsLoading(true);
    let payload = {
      phoneNumber: data.country.dial,
      phoneNumberCountryCode: data.country.code,
    };

    // check if phone number exist in account_entity
    const isAccountExist = await AuthService.verifyPhoneNumber(payload);

    if (!isAccountExist.data.isRegisteredPhone) {
      setError("mobile", {
        message: i18next.t("commons.error.form.invalidCredentials"),
        type: "manual",
      });

      return;
    }

    mutation.mutate(payload);
  };

  // setup recaptcha
  useEffect(() => {
    if (!window.recaptchaVerifier) {
      // create element if not found
      let recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        recaptchaContainer = document.createElement("div");
        recaptchaContainer.id = "recaptcha-container";
        document.body.append(recaptchaContainer);
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );

      window.recaptchaVerifier.verify();
    }
  }, []);

  return {
    isValid,
    agreed,
    errors,
    mobile,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
    setError,
    setValue,
    isLoading: mutation.isPending || isLoading,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
    register,
    // showModal,
  };
};

export default useContainer;
