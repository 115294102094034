import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  FormControl,
  Grid,
  Input,
  Menu,
  MenuButton,
  Option,
  Select,
  Sheet,
  Snackbar,
  Stack,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SuccessModal } from "../../components/SuccessModal";
import { InventoryService } from "../../services/inventory.service";
import { formatDate } from "../../utils/formatDate";
import useContainer from "./useContainer";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { KeyboardArrowDown, SearchOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { If } from "../../components/Condition";
import CopyLinkButton from "../../components/CopyLinkButton";
import ConfirmDeleteModal from "../../components/Inventory/ConfirmDeleteModal";
import ConfirmDuplicateModal from "../../components/Inventory/ConfirmDuplicateModal";
import EditCatalogueItemModal from "../../components/Inventory/Items/EditCatalogueItemModal";
import ShareModal from "../../components/Inventory/ShareModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { theme } from "../../components/Theme";
import { CatalogService } from "../../services/catalog.service";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness } from "../../store/session";
import { CatalogInventoryItemType } from "../../types/item";
import { BrowserRouterConstants } from "../../utils/constants";
import ConfirmDeleteItemsModal from "../Inventory/components/ConfirmDeleteItemsModal";
import { currencyList } from "../Order/EditOrders";
import { ProductCard } from "./components/ProductCard";
// import { getCountryInfo } from "../../utils/country";

const sortOptions = [
  { value: "", label: "Sort By" },
  { value: "id_asc", label: "Newest first" },
  { value: "id_desc", label: "Latest first" },
  { value: "productName_asc", label: "A to Z (ascending)" },
  { value: "productName_desc", label: "Z to A (descending)" },
  { value: "price_desc", label: "Highest Price" },
  { value: "price_asc", label: "Lowest Price" },
];

const ListCatalogueItems = () => {
  const { t } = useTranslation();
  const { catalogId } = useParams();
  // const currentBusiness = getActiveBusiness() as any;
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CatalogInventoryItemType>();
  // const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [isHidePrice, setIsHidePrice] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [isEditCatalogName, setIsEditCatalogName] = useState(false);
  const inventoryType = searchParams.get("inventoryType");
  const query = searchParams.get("q");
  const sortBy = searchParams.get("sortBy");
  const sortDirection = searchParams.get("sortDirection") ?? "";
  const loaderRef = useRef(null);
  // const [openProductDetailsModal, setOpenProductDetailsModal] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [errorUpdateName, setErrorUpdateName] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedCatalogItems, setSelectedCatalogItems] = useState<string[]>(
    []
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    isPending,
    fetchAllItems,
    deleteInventoryItemBatch,
    items,
    setItems,
    catalog,
    setCatalog,
    isLoading,
    setIsLoading,
    showAlert,
    showError,
    setShowAlert,
    showToast,
    unauthorized,
    setUnauthorized,
    alertMessage,
    setAlertMessage,
    confirmDelete,
    setConfirmDelete,
    showShareModal,
    setShowShareModal,
    confirmDuplicate,
    setConfirmDuplicate,
    fillerItems,
    pagination,
    hasMore,
    currency,
    setCurrency,
    imgExistanceCheckQuery,
  } = useContainer();
  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          if (res.business.id !== getActiveBusiness()?.id) {
            setUnauthorized(true);

            return;
          }

          setCatalog(res);
          // setIsHidePrice(res.hidePrice);
          return fetchAllItems({
            catalogId,
            paging: true,
            limit: 9,
            offset: 0,
            isReset: true,
            inventoryType: inventoryType !== "all" ? inventoryType : "",
            searchTerm: query,
            sortDirection: (["asc", "desc"].includes(sortDirection)
              ? sortDirection
              : "desc") as "asc" | "desc",
            sortBy: sortBy ? sortBy : "updatedAt",
          });
        })
        .then(() => {
          // getting all inventoryType for categories
          const businessId = getActiveBusiness()?.id;
          if (businessId) {
            ItemService.getInventoryItemField({
              fields: ["inventoryType"],
              businessId,
            }).then((res) => {
              const cats = res.map(
                (type: { inventoryType: string }) => type.inventoryType
              );
              setCategories(cats);
            });
          }

          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [catalogId, query, inventoryType, sortDirection, sortBy]);

  const handleRemove = () => {
    setIsLoading(true);
    InventoryService.removeCatalog({
      catalogId: catalogId || "",
    })
      .then((res) => {
        if (res) {
          setAlertMessage(t("catalog.catalogRemoved"));
          setShowAlert(true);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
        window.location.href = "/client/catalogues/list";
      });
  };

  const handleRemoveItem = (id: string) => {
    InventoryService.removeCatalogItem({
      catalogInventoryItemId: id,
    })
      .then((res) => {
        if (res && catalogId) {
          setAlertMessage(t("catalog.inventory.catalogItemRemoved"));
          setShowAlert(true);

          fetchAllItems({
            catalogId,
            paging: true,
            limit: 9,
            offset: 0,
            isReset: true,
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDuplicate = () => {
    setIsLoading(true);

    const businessId = getActiveBusiness()?.id;

    if (catalogId && businessId) {
      InventoryService.duplicateCatalog({
        catalogId,
      })
        .then((res) => {
          if (res.id) {
            setShowAlert(true);
            setAlertMessage(
              t("catalog.inventory.duplicateCatalog.successMessage")
            );

            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          window.location.href = `/client/catalogues/list?type=${
            catalog?.catalogType.toLowerCase() ?? "public"
          }`;
        });
    }
  };

  const handleUpdateName = async (value: string) => {
    setIsLoading(true);

    const isDuplicate = await CatalogService.validateCatalogName(value);
    console.log("BANANA ISDUPLICATE", isDuplicate);
    if (isDuplicate) {
      setErrorUpdateName(t("catalog.duplicateCatalogError"));
      setIsLoading(false);
      return;
    }

    if (catalog) {
      InventoryService.patchCatalog({
        catalog: {
          id: catalog.id,
          catalogType: catalog.catalogType,
          catalogName: value,
          business: catalog.business.id,
          hidePrice: catalog.hidePrice,
        },
      })
        .then((res) => {
          setCatalog(res);
          setErrorUpdateName(undefined);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
          setIsEditCatalogName(false);
        });
    }
  };

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("q");
      } else {
        searchParams.set("q", value);
      }
      setSearchParams(searchParams);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    catalogItemId: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
      setSelectedCatalogItems((prev) => [...prev, catalogItemId]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
      setSelectedCatalogItems(
        selectedCatalogItems.filter((i) => i !== catalogItemId)
      );
    }
  };

  const handleSelectAll = () => {
    if (catalogId) {
      ItemService.getCatalogInventoryItemField({
        fields: ["id", "inventoryItem"],
        catalogId,
        inventoryType: searchParams.get("inventoryType") ?? "",
        searchTerm: searchParams.get("q") ?? "",
      })
        .then((res) => {
          const ids: string[] = res.map(
            (i: { inventoryItem: string }) => i.inventoryItem
          );
          setSelectedItems(ids);

          const catalogItemIds: string[] = res.map((i: { id: string }) => i.id);
          setSelectedCatalogItems(catalogItemIds);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleBatchEdit = () => {
    if (selectedItems.length > 0) {
      localStorage.setItem("batchEditItems", JSON.stringify(selectedItems));

      window.location.href = `/client/${BrowserRouterConstants.InventoryBatchEdit}?catalog=${catalogId}`;
    }
  };

  const CatalogTypeButton = () => {
    return (
      <button
        className={`py-3 px-4 rounded-md ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlinedIcon
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlinedIcon
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  if (unauthorized) {
    return <> {t("catalog.noAccessCatalog")}</>;
  }

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <Box
        sx={{
          width: { xs: "90vw", lg: "100%" },
        }}
      >
        {/* page header */}
        <Grid container spacing={"2px"}>
          <Grid xs={12} lg={6} display={"flex"} position={"relative"}>
            <Stack alignItems={"center"} direction={"row"} gap={2}>
              {imgExistanceCheckQuery.data && (
                <img
                  style={{ width: "60px", height: "60px", objectFit: "cover" }}
                  src={imgExistanceCheckQuery.data}
                  alt=""
                />
              )}
              {!isEditCatalogName ? (
                <h3
                  className="text-2xl font-bold"
                  onClick={() => setIsEditCatalogName(true)}
                >
                  {catalog?.catalogName}
                </h3>
              ) : (
                <Stack direction={"column"}>
                  <Input
                    placeholder={t(
                      "catalog.inventory.createCatalog.placeholder"
                    )}
                    defaultValue={catalog?.catalogName}
                    type="text"
                    className="text-2xl font-bold"
                    fullWidth
                    autoFocus
                    onBlur={(event) => {
                      if (event.target.value !== catalog?.catalogName) {
                        handleUpdateName(event.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        if (
                          event.currentTarget.value !== catalog?.catalogName
                        ) {
                          handleUpdateName(event.currentTarget.value);
                        }
                      }
                    }}
                  />
                  <If condition={errorUpdateName ? true : false}>
                    <p className="text-[#C31D1D] text-sm">{errorUpdateName}</p>
                  </If>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "left", lg: "right" },
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              <span className="text-xs lg:text-sm">
                {t("commons.lastUpdated")}:{" "}
                <span className="ml-2">
                  {formatDate(new Date(catalog?.updatedAt ?? ""))}
                </span>
              </span>
            </Typography>
          </Grid>
        </Grid>

        {/* toolbar */}
        <div className="flex items-center my-6">
          <CatalogTypeButton />
          {/* // TODO: fix ui */}
          <Select
            indicator={<KeyboardArrowDown />}
            placeholder={t("catalog.inventory.editForm.currency")}
            slotProps={{
              button: { sx: { whiteSpace: "nowrap" } },
            }}
            onChange={(_event, newValue: string | null) => {
              // TODO put currency change logic here
              if (!newValue || !catalogId) {
                return;
              }

              setCurrency(newValue);
              setIsLoading(true);
              ItemService.patchCatalogCurrency({
                catalogId,
                updateCatalogCurrencyDto: { newCurrency: newValue },
              })
                .then((res) => {
                  if (res.length > 0) {
                    window.location.reload();
                  }
                })
                .catch((error) => console.log(error));
            }}
            value={currency}
            className="mt-auto ml-3"
          >
            {currencyList.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>

          <span className="flex ml-auto">
            <Stack
              width={40}
              sx={{
                display: { lg: "none" },
              }}
            >
              <Dropdown
                open={mobileMenuOpen}
                onOpenChange={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <MenuButton
                  fullWidth
                  slots={{ root: Button }}
                  slotProps={{
                    root: {
                      variant: "plain",
                      color: "neutral",
                      sx: {
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                        "&:focus": {
                          bgcolor: "transparent",
                        },
                        padding: 0,
                        minWidth: 0,
                      },
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    className="min-w-[40px] text-nowrap p-0"
                  >
                    <EllipsisVerticalIcon width={18} height={18} />
                  </Button>
                </MenuButton>
                <Menu>
                  <Link to={`/client/catalogues/inventory/${catalogId}/manage`}>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                    >
                      <PlusIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("commons.addItemBtn")}
                    </MenuItem>
                  </Link>
                  <MenuItem
                    sx={{
                      gap: "1rem",
                    }}
                    onClick={() => {
                      setShowShareModal(true);
                    }}
                  >
                    <SendOutlinedIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                    {t("catalog.sendBtn")}
                  </MenuItem>
                  <CopyLinkButton
                    url={t("catalog.copyLinkPrompt", {
                      catalogueLink: `${
                        window.location.href.split("/client")[0]
                      }/catalog/${catalogId}/items`,
                    })}
                    variant={"dropdown"}
                  />
                  <MenuItem
                    sx={{
                      gap: "1rem",
                    }}
                    onClick={() => {
                      setConfirmDuplicate(true);
                    }}
                  >
                    <ContentCopyOutlinedIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                    {t("catalog.inventory.items.duplicateBtn")}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      gap: "1rem",
                    }}
                    onClick={() => {
                      setConfirmDelete(true);
                    }}
                  >
                    <TrashIcon width={18} height={18} />
                    {t("catalog.inventory.removeCatalogBtn")}
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Stack>

            <Stack
              direction={"row"}
              sx={{
                gap: "0.75rem",
                display: { xs: "none", lg: "flex" },
              }}
            >
              <Link to={`/client/catalogues/inventory/${catalogId}/manage`}>
                <Button
                  startDecorator={
                    <PlusIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  }
                  className="text-nowrap"
                >
                  {t("commons.addItemBtn")}
                </Button>
              </Link>

              <Button
                variant="outlined"
                startDecorator={
                  <SendOutlinedIcon
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
                className="w-full mt-4 text-sm lg:mt-0 text-nowrap"
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                {t("catalog.sendBtn")}
              </Button>

              <CopyLinkButton
                url={t("catalog.copyLinkPrompt", {
                  catalogueLink: `${
                    window.location.href.split("/client")[0]
                  }/catalog/${catalogId}/items`,
                })}
                variant={"outlined"}
              />

              <Stack width={40}>
                <Dropdown
                  open={menuOpen}
                  onOpenChange={() => setMenuOpen(!menuOpen)}
                >
                  <MenuButton
                    fullWidth
                    slots={{ root: Button }}
                    slotProps={{
                      root: {
                        variant: "plain",
                        color: "neutral",
                        sx: {
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                          "&:focus": {
                            bgcolor: "transparent",
                          },
                          padding: 0,
                          minWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      className="min-w-[40px] text-nowrap p-0"
                    >
                      <EllipsisVerticalIcon width={18} height={18} />
                    </Button>
                  </MenuButton>
                  <Menu>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDuplicate(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.items.duplicateBtn")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      <TrashIcon width={18} height={18} />
                      {t("catalog.inventory.removeCatalogBtn")}
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </Stack>
            </Stack>
          </span>
        </div>

        <Stack direction={"column"} gap={"1rem"}>
          <Input
            defaultValue={query ?? ""}
            variant="linkz-input-search"
            size="sm"
            name="searchFormData.search"
            placeholder={t("catalog.searchItemPlaceholder")}
            startDecorator={<SearchOutlined />}
            sx={{ flexGrow: 1, width: { xs: "100%", lg: "37%" } }}
            onChange={handleChange}
          />

          <Stack
            direction={"row"}
            sx={{
              gap: "1rem",
              width: { xs: "90vw", lg: "100%" },
              alignItems: "center",
              overflow: "auto",
              paddingX: "1rem",
            }}
            className={`hide-scrollbar`}
          >
            {/* checkbox select all */}
            {/* // TODO: checked when all items are checked one by one */}
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  handleSelectAll();
                } else {
                  setSelectedItems([]);
                  setSelectedCatalogItems([]);
                }
              }}
            />
            {/* category filter */}
            <FormControl size="sm">
              <Select
                onChange={(
                  _event: React.SyntheticEvent | null,
                  value: string | null
                ) => {
                  if (!value && value !== "") {
                    return;
                  }

                  if (value.length > 1) {
                    searchParams.set("inventoryType", value);
                  } else {
                    searchParams.delete("inventoryType");
                  }
                  setSearchParams(searchParams);
                }}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                size="sm"
                defaultValue={""}
                sx={{
                  paddingY: "10px",
                  paddingX: "16px",
                  fontWeight: 600,
                  gap: "8px",
                  color: theme.colorSchemes.light.palette.others.neutral_dark,
                  borderColor:
                    theme.colorSchemes.light.palette.others.neutral_dark,
                }}
                indicator={<ChevronDownIcon width={18} height={18} />}
              >
                <Option value={""}>
                  {t("catalog.inventory.allFilterBtn")}
                </Option>
                {categories.map((cat) => {
                  return (
                    <Option
                      color="primary"
                      value={cat}
                      label={cat.toUpperCase()}
                      sx={{
                        color: theme.colorSchemes.dark.palette.common.black,
                      }}
                    >
                      {cat}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
            {/* sort by */}
            <FormControl size="sm">
              <Select
                defaultValue={
                  sortBy && sortDirection ? sortBy + "_" + sortDirection : ""
                }
                onChange={(
                  _event: React.SyntheticEvent | null,
                  value: string | null
                ) => {
                  if (!value) {
                    return;
                  }

                  if (value.length > 1) {
                    const [field, order] = value.split("_");
                    searchParams.set("sortBy", field);
                    searchParams.set("sortDirection", order);
                  } else {
                    searchParams.delete("sortBy");
                    searchParams.delete("sortDirection");
                  }
                  setSearchParams(searchParams);
                }}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                size="sm"
                sx={{
                  paddingY: "10px",
                  paddingX: "16px",
                  fontWeight: 600,
                  gap: "8px",
                  color: theme.colorSchemes.light.palette.others.neutral_dark,
                  borderColor:
                    theme.colorSchemes.light.palette.others.neutral_dark,
                }}
                indicator={<ChevronDownIcon width={18} height={18} />}
              >
                {sortOptions.map((opt) => {
                  if (catalog?.hidePrice && opt.value.includes("price")) {
                    return;
                  }

                  return (
                    <Option
                      key={opt.value}
                      value={opt.value}
                      color="primary"
                      sx={{
                        color: theme.colorSchemes.dark.palette.common.black,
                      }}
                    >
                      {t(`catalog.sortOptions.${opt.label}`)}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        {/* items table */}

        <InfiniteScroll
          pageStart={0}
          // initialLoad
          loadMore={(_page: number) => {
            console.log("BANANA PAGINATION MAX", pagination);
            fetchAllItems({
              catalogId: catalogId ?? "",
              paging: true,
              limit: 9,
              searchTerm: query,
              offset: pagination.limit * pagination.current,
              isReset: false,
              inventoryType: searchParams.get("inventoryType") ?? "",
            });
          }}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <div className="w-[90vw] lg:w-[78vw]">
            {items.length > 0 ? (
              <>
                <Grid
                  container
                  mt={4}
                  mb={2}
                  spacing={{ xs: 1, lg: 0 }}
                  alignItems={"stretch"}
                  className={"grid grid-cols-2 xl:grid-cols-3 gap-2 md:gap-6"}
                >
                  {items.map((i, index) => {
                    return (
                      <div key={index}>
                        <ProductCard
                          onClick={() => {
                            // setOpenProductDetailsModal(true);
                            setSelectedItem(i);
                            // showEditModal;
                            setShowEditModal(true);
                          }}
                          key={i.id}
                          handleRemoveItem={handleRemoveItem}
                          item={i}
                          selectedItems={selectedItems}
                          handleChecked={handleChecked}
                        />
                      </div>
                    );
                  })}

                  {!isMobile &&
                    Array.from({ length: fillerItems }, () => (
                      <Grid
                        key={Math.random() * 6502}
                        xs={12}
                        lg={4}
                        flexGrow={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "stretch",
                          justifyContent: "space-between",
                        }}
                      >
                        <hr
                          className={`hidden lg:block mt-auto w-full border-b border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                        />
                      </Grid>
                    ))}
                </Grid>

                <div ref={loaderRef} className="flex justify-center w-full">
                  {isLoading && "Loading..."}
                </div>
              </>
            ) : (
              <div className="flex w-full mt-10">
                <p className="mx-auto my-auto text-base">
                  {t("catalog.noItemInCatalog")}
                </p>
              </div>
            )}
          </div>
        </InfiniteScroll>

        {/* modal */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={alertMessage}
          open={showAlert}
        />
        {/* <LoadingModal isLoading={isLoading} /> */}
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showToast}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
            backgroundColor: theme.colorSchemes.light.palette.others.success,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.black"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <ShareModal
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          catalogId={catalogId ?? ""}
          businessId={getActiveBusiness()?.id ?? ""}
          catalogType={catalog?.catalogType.toLowerCase() ?? "private"}
        />
        <ConfirmDeleteModal
          show={confirmDelete}
          onClick={handleRemove}
          onClose={() => {
            setConfirmDelete(false);
          }}
        />
        <ConfirmDuplicateModal
          show={confirmDuplicate}
          catalogType={
            t(`catalog.catalogType.${catalog?.catalogType.toLowerCase()}`) ??
            "private"
          }
          onClick={handleDuplicate}
          onClose={() => {
            setConfirmDuplicate(false);
          }}
        />

        {selectedItem && (
          <EditCatalogueItemModal
            catalogId={catalogId ?? ""}
            showModal={showEditModal}
            catalogInventoryItem={selectedItem}
            onClose={() => {
              setShowEditModal(false);
              setSelectedItem(undefined);
            }}
            onUpdate={(item: CatalogInventoryItemType) => {
              const updatedItems = items.map((i) => {
                if (i.id === item.id) {
                  return { ...item };
                } else {
                  return i;
                }
              });

              setItems(updatedItems);
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          backgroundColor:
            theme.colorSchemes.light.palette.others.neutral_light,
          position: "sticky",
          bottom: 0,
          left: 0,
          marginBottom: "-1rem",
          marginLeft: { xs: "-1rem", lg: "-2rem" },
          width: { xs: "calc(100% + 2rem)", lg: "calc(100% + 4rem)" },
          borderTop: "1px solid #e5e7eb",
          paddingY: selectedItems.length > 0 ? "1.25rem" : 0,
          paddingX: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxHeight: selectedItems.length > 0 ? "500px" : 0,
          transition: "all 0.1s ease-in-out",
          overflow: "hidden",
        }}
      >
        <Typography fontWeight={700} fontSize={14}>
          {selectedItems.length}{" "}
          <span className="font-medium">
            {t("catalog.inventory.batchEdit.itemsSelected")}
          </span>
        </Typography>

        <Stack direction={"row"} gap={"10px"}>
          <Button
            variant="plain"
            onClick={() => setShowDeleteModal(true)}
            disabled={selectedItems.length === 0}
            sx={{
              color: "var(--joy-palette-danger-500)",
              "&:hover": {
                backgroundColor: "var(--joy-palette-danger-100)",
              },
            }}
          >
            {t("catalog.inventory.batchEdit.deleteSelected")}
          </Button>
          <Button
            variant="plain"
            onClick={handleBatchEdit}
            disabled={selectedItems.length === 0}
            sx={{
              display: { xs: "none", lg: "block" },
              color: "var(--joy-palette-primary-500)",
              "&:hover": {
                backgroundColor: "var(--joy-palette-primary-100)",
              },
            }}
          >
            {t("catalog.inventory.batchEdit.editSelected")}
          </Button>
        </Stack>
      </Box>

      <LoadingModal isLoading={isLoading || isPending} />
      <ConfirmDeleteItemsModal
        show={showDeleteModal}
        onClick={() => {
          deleteInventoryItemBatch(selectedCatalogItems, catalogId);
        }}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        selectedItems={selectedCatalogItems}
      />
      {/* {selectedItem && (
        <Modal
          onClose={() => {
            setOpenProductDetailsModal(false);
            setSelectedItem(undefined);
          }}
          open={openProductDetailsModal}
          style={{
            backgroundColor: "rgba(50, 56, 62,.6)",
            backdropFilter: "blur(0px)",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex items-center w-full h-full">
            <ProductModal
              onUpdate={(item: CatalogInventoryItemType) => {
                const updatedItems = items.map((i) => {
                  if (i.id === item.id) {
                    return { ...item };
                  } else {
                    return i;
                  }
                });
                setItems(updatedItems);
              }}
              catalogId={catalogId ?? ""}
              catalogInventoryItem={selectedItem}
              onClose={() => {
                setOpenProductDetailsModal(false);
                setSelectedItem(undefined);
              }}
            />
          </div>
        </Modal>
      )} */}
    </Sheet>
  );
};

export default ListCatalogueItems;
