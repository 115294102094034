import { Chip, Grid, Stack, Typography } from "@mui/joy";
import { ReactElement } from "react";
import { referralStatuses } from "../../types/referral";
import { useAuthStore } from "../../store/session";
import { useTranslation } from "react-i18next";

const InfoGridRowSlot = ({
  column1,
  column2,
}: {
  column1: ReactElement;
  column2: ReactElement;
}) => (
  <Grid container spacing={1} sx={{ flexGrow: 1 }}>
    <Grid xs={3}>
      <Typography fontWeight={"bold"}>{column1}</Typography>
    </Grid>
    <Grid>{column2}</Grid>
  </Grid>
);

export default function ReferralCards({ referralsList }: any) {
  const { session } = useAuthStore();
  const { t } = useTranslation();
  return (
    <Stack spacing={3}>
      {referralsList &&
        referralsList?.map((referral: any) => (
          <Stack
            className="cardItem"
            key={1}
            sx={{
              width: "100%",
              borderRadius: "sm",
              my: 2,
              p: 2,
              boxShadow: "sm",
            }}
          >
            <Chip>
              {
                referralStatuses.find(
                  (status) => status.value === referral.status
                )?.label
              }
            </Chip>
            <Typography px={0} my={1}>
              {referral.referredBusiness?.companyName}
            </Typography>
            {session?.activeBusiness.isOwner && (
              <InfoGridRowSlot
                column1={
                  <Typography fontWeight={"bold"}>
                    {t("referral.inviter")} :
                  </Typography>
                }
                column2={<>{referral.referrerUser?.profile?.fullName}</>}
              />
            )}
            <InfoGridRowSlot
              column1={
                <Typography fontWeight={"bold"}>
                  {t("referral.invitee")} :
                </Typography>
              }
              column2={<>{referral.referredUser?.profile?.fullName}</>}
            />
          </Stack>
        ))}
    </Stack>
  );
}
