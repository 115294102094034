import {
  HeadObjectCommand,
  ObjectCannedACL,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { s3Client } from "../lib/spaces";
import { resizeFile } from "../utils/file";

export class UploadService {
  static async upload(
    file: File,
    name: string,
    folder?: string,
    isPrivate = false,
    compresed = true
  ) {
    var params: any = {
      Bucket: import.meta.env.VITE_SPACES_BUCKET_NAME,
      Key: `${folder ? folder + "/" : ""}${name}`,
    };
    const compresedFile = compresed ? await resizeFile(file) : file;

    if (compresedFile) {
      params = {
        ...params,
        Body: compresedFile,
        ACL: ObjectCannedACL.public_read,
        ContentType: file.type,
      };
      const data = await s3Client.send(
        new PutObjectCommand({
          ...params,
          ACL: isPrivate
            ? ObjectCannedACL.private
            : ObjectCannedACL.public_read,
        })
      );
      return data;
    } else {
      throw new Error("Failed to compress file");
    }
  }
  static async checkIfImageExists(imageName: string, folder?: string) {
    const key = `${folder ? folder + "/" : ""}${imageName}`;
    const command = new HeadObjectCommand({
      Bucket: import.meta.env.VITE_SPACES_BUCKET_NAME,
      Key: key,
    });

    try {
      await s3Client.send(command);
      const doSpaceHost =
        import.meta.env.VITE_SPACES_HOST ||
        "https://linkz-staging-bucket.sgp1.digitaloceanspaces.com";
      return `${doSpaceHost}/${key}`;
    } catch (error: any) {
      if (error.name === "NotFound") {
        console.log(`File does not exist: ${key}`);
        return false;
      }
      console.error(`Error checking file existence: ${error}`);
      throw error;
    }
  }

  static async urlToFile(url: string, fileName: string) {
    const urlWithTimestamp = `${url}${url.includes("?") ? "&" : "?"
      }_t=${Date.now()}`;
    const response = await fetch(urlWithTimestamp);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  }
}
