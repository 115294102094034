import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../../../components/Error";
import { BusinessContactService } from "../../../../services/business-contact.service";

export function useContactFormContainer({
  editMode,
  onSuccess,
}: {
  editMode?: boolean;
  onSuccess?: (data: any) => void;
}) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const businessScheme = z.object({
    contactPhone: z.string().min(10).optional(),
    companyName: z.string().min(3),
    nameOfContact: z.string().min(3),
    companyNumber: z.string().optional(),
    companyNumberCountryCode: z
      .string()
      .optional()
      .transform((arg, ctx) => {
        if (arg) {
          if (!new RegExp(/^[0-9]+$/).test(arg)) {
            ctx.addIssue({
              code: "custom",
              message: "Should be numbers",
            });
          }
        }
        return arg;
      }),
    companyCountry: z.string(),
    companyProvince: z.string().optional(),
    companyAddress: z.string().optional(),
    companyPostalCode: z
      .string()
      .optional()
      .transform((arg, ctx) => {
        if (arg) {
          if (!new RegExp(/^[0-9]+$/).test(arg)) {
            ctx.addIssue({
              code: "custom",
              message: "Should be numbers",
            });
          }
        }
        return arg;
      }),
    companyBankName: z.string().optional(),
    companyBankAccountNo: z
      .string()
      .optional()
      .transform((arg, ctx) => {
        if (arg) {
          if (!new RegExp(/^[0-9]+$/).test(arg)) {
            ctx.addIssue({
              code: "custom",
              message: "Should be number",
            });
          }
        }
        return arg;
      }),
    companyBankAccountHolderName: z.string().optional(),
    companyPaymentTerms: z.string().optional(),
    contactBusinessId: z.string().optional(),
    contactBusinessAccountId: z.string().optional(),
    companyCreditLimit: z.number().optional(),
    companyCreditLimitCurrency: z.string().optional(),
    phoneNumber: z.string().optional().nullable(),
    phoneNumberCountryCode: z.string().optional().nullable(),
    phoneOrEmail: z.string().min(3),
  });

  const [onSearching, setOnSearching] = useState(false);

  const createMutation = useMutation({
    mutationFn: BusinessContactService.createContact,
    onSuccess: (data) => {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        if (onSuccess) onSuccess(data);
        else navigate("/client/business-contact", { replace: true });
      });
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const upadateMutation = useMutation({
    mutationFn: BusinessContactService.updateContact,
    onSuccess: (_) => {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        if (state.backUrl) {
          window.location.href = state.backUrl;
        } else {
          navigate("/client/business-contact", { replace: true });
        }
      });
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const {
    register,
    watch,
    setError,
    formState,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
  } = useForm<z.infer<typeof businessScheme>>({
    resolver: zodResolver(businessScheme),
  });

  const { errors } = formState;

  const onSubmit: SubmitHandler<z.infer<typeof businessScheme>> = (data) => {
    setLoading(true);
    if (editMode) {
      upadateMutation.mutate({
        ...data,
        id: state?.contact?.id,
      });
    } else {
      const payload: any = { ...data };
      if (checkEmailOrPhone(data.phoneOrEmail) === "EMAIL") {
        payload.contactEmail = data.phoneOrEmail
        createMutation.mutate(payload);
      } else if (checkEmailOrPhone(data.phoneOrEmail) === "PHONE") {
        payload.contactPhone = data.phoneOrEmail
        createMutation.mutate(payload);
      } else {
        setLoading(false);
        setError("phoneOrEmail", { type: "validate", message: "Email or Phone Number is not valid" });
      }

    }
  };

  const checkEmailOrPhone = (payload: string) => {
    const phoneSchema = z.string().min(10).regex(/^[+]?[0-9]{1,4}[-\s]?[0-9]{1,14}([-\s]?[0-9]{1,13})?$/);
    const emailSchema = z.string().email();
    if (emailSchema.safeParse(payload).success) {
      return "EMAIL";
    } else if (phoneSchema.safeParse(payload).success) {
      return "PHONE";
    } else {
      return "OTHER";
    }
  }

  const searchContactByEmail = (payload: string) => {
    const check = checkEmailOrPhone(payload);
    if (check === "EMAIL") {
      setOnSearching(true);
      BusinessContactService.searchContactToAdd(payload)
        .then((data) => {
          setValue("contactBusinessAccountId", data.id);
          setValue("nameOfContact", data.profile?.fullName);
          setValue("phoneNumber", data.phoneNumber)
          setValue("phoneNumberCountryCode", data.phoneNumberCountryCode)
          setValue("phoneOrEmail", data.email)
          if (data?.businesses?.length === 1) {
            const business = data?.businesses?.[0];
            handleSetBusinessData(business);
          }
          setBusinessOptions(data.businesses);
        })
        .catch(() => {
          setValue("contactBusinessId", undefined);
          setValue("contactBusinessAccountId", undefined);
          setValue("contactBusinessAccountId", undefined);
          setBusinessOptions([]);
        })
        .finally(() => {
          clearErrors();
          setOnSearching(false);
        });
    } else if (check === "PHONE") {
      setOnSearching(true);
      BusinessContactService.searchContactToAddByPhone(payload)
        .then((data) => {
          setValue("contactBusinessAccountId", data.id);
          setValue("nameOfContact", data.profile?.fullName);
          setValue("phoneNumber", data.phoneNumber)
          setValue("phoneNumberCountryCode", data.phoneNumberCountryCode)
          setValue("phoneOrEmail", `${data.phoneNumberCountryCode}${data.phoneNumber}`)
          if (data?.businesses?.length === 1) {
            const business = data?.businesses?.[0];
            handleSetBusinessData(business);
          }
          setBusinessOptions(data.businesses);
        })
        .catch(() => {
          setValue("contactBusinessId", undefined);
          setValue("contactBusinessAccountId", undefined);
          setValue("contactBusinessAccountId", undefined);
          setBusinessOptions([]);
        })
        .finally(() => {
          clearErrors();
          setOnSearching(false);
        });
    } else {
      setValue("phoneOrEmail", payload);
      setValue("contactBusinessId", undefined);
      setValue("contactBusinessAccountId", undefined);
      setValue("contactBusinessAccountId", undefined);
      setBusinessOptions([]);
      if (payload.length > 5) {
        setError("phoneOrEmail", { type: "validate", message: "Email or Phone Number is not valid" });
      } else {
        clearErrors("phoneOrEmail");
      }
    }
  };

  const handleSetBusinessData = (data: any) => {
    setValue("companyName", data.companyName);
    setValue("companyNumber", data.companyNumber);
    setValue("companyNumberCountryCode", data.companyNumberCountryCode);
    setValue("companyCountry", data.companyCountry);
    setValue("companyProvince", data.companyProvince);
    setValue("companyAddress", data.companyAddress);
    setValue("companyPostalCode", data.companyPostalCode);
    setValue("companyBankName", data.bankInfo?.bankName ?? "");
    setValue("companyBankAccountNo", data.bankInfo?.accountNo ?? "");
    setValue(
      "companyBankAccountHolderName",
      data.bankInfo?.accountHolderName ?? ""
    );
    setValue("contactBusinessId", data.id);
  };

  const fromLocationState = () => {
    const contact = state.contact;

    let phoneOrEmail = ""
    if (contact?.contactBusinessAccount?.authStrategy === "PHONE_AUTH") {
      const { phoneNumber, phoneNumberCountryCode } = contact?.contactBusinessAccount;
      phoneOrEmail = `${phoneNumber}${phoneNumberCountryCode}`;
    } else {
      phoneOrEmail = contact.companyEmail ? contact.companyEmail : contact?.contactBusinessAccount?.email;
    }

    setValue("nameOfContact", contact?.nameOfContact);
    setValue("companyName", contact?.companyName);
    setValue("companyNumber", contact?.companyNumber);
    setValue("companyNumberCountryCode", contact?.companyNumberCountryCode);
    setValue("companyCountry", contact?.companyCountry);
    setValue("companyProvince", contact?.companyProvince);
    setValue("companyAddress", contact?.companyAddress);
    setValue("companyPostalCode", contact?.companyPostalCode);
    setValue("companyBankName", contact?.companyBankName);
    setValue("companyBankAccountNo", contact?.companyBankAccountNo);
    setValue(
      "companyBankAccountHolderName",
      contact?.companyBankAccountHolderName
    );
    setValue("phoneOrEmail", phoneOrEmail);
    setValue("contactBusinessId", contact?.contactBusiness?.id);
    setValue("contactBusinessAccountId", contact?.contactBusinessAccount?.id);
    setValue("companyPaymentTerms", contact?.companyPaymentTerms);
    setValue("companyCreditLimit", contact?.companyCreditLimit);
    setValue("companyCreditLimitCurrency", contact?.companyCreditLimitCurrency);
  };

  useEffect(() => {
    if (state?.contact && editMode) {
      fromLocationState();
    }
  }, []);

  return {
    register,
    watch,
    setError,
    formState,
    handleSubmit,
    setValue,
    errors,
    onSubmit,
    onSearching,
    setOnSearching,
    searchContactByEmail,
    businessOptions,
    handleSetBusinessData,
    success,
    loading,
    navigate,
    getValues,
    checkEmailOrPhone,
  };
}
