import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CatalogInventoryItemType } from "../../../types/item";
import { CatalogType } from "../../../types/catalog";
import { ListItem, Stack, Typography } from "@mui/joy";
import { theme } from "../../../components/Theme";
import CartQuantityManager from "../../../components/Catalogue/Item/CartQuantityManager";
import TagsCollection from "../../../components/Catalogue/Item/TagsCollection";
import UOMSelector from "../../../components/Catalogue/Item/UOMSelector";
import { CartItemDataType } from "../../../types/cart";
import { calculatePrice } from "../../../utils/cart";
import { If } from "../../../components/Condition";
import { formatPrice } from "../../../utils/formatPrice";

type Props = {
  type?: string;
  item: CatalogInventoryItemType;
  isMobile: boolean;
  catalog: CatalogType;
  setToCart: Dispatch<SetStateAction<CartItemDataType[]>>;
  resetFlag?: boolean;
  onClick?: () => void;
};

const ProductListItem = ({
  item,
  isMobile,
  catalog,
  type,
  setToCart,
  resetFlag = false,
  onClick,
}: Props) => {
  const currency = item.currency ?? item.inventoryItem.currency;

  const [activeUOM, setActiveUOM] = useState<"uom1" | "uom2" | "uom3">("uom1");

  useEffect(() => {
    if (setToCart) {
      setToCart((prev) => {
        const currentIndex = prev.findIndex((p) => p.cid === item.id);
        if (currentIndex !== -1) {
          const updatedItem = {
            ...prev[currentIndex],
            uom: activeUOM,
            initPrice: calculatePrice(item, activeUOM),
          };

          return [
            ...prev.slice(0, currentIndex),
            updatedItem,
            ...prev.slice(currentIndex + 1),
          ];
        }

        return prev;
      });
    }
  }, [activeUOM]);

  return (
    <ListItem key={item.id} sx={{ display: "block" }}>
      <div
        className={`pt-4 flex flex-col lg:flex-row lg:align-middle w-full gap-4`}
      >
        <Stack
          className="gap-4 w-full lg:w-[37%]"
          direction={"row"}
          onClick={onClick}
        >
          {type === "image" && (
            // <div className={`rounded-lg relative ${"w-[100px] h-[100px]"} `}>
            //   <img
            //     src={`${
            //       item.inventoryItem.thumbnail ??
            //       "/inventory-img-placeholder.png"
            //     }`}
            //     alt="item_image"
            //     className={` ${"w-[100px] h-[100px]"} `}
            //   />
            //   <div className="absolute bottom-0 left-0 items-end justify-between hidden w-full h-full p-1 md:flex">
            //     <div className="bg-[#F1F8F480] px-2 py-[2px] rounded-full text-xs">
            //       1/4
            //     </div>
            //     <div className="p-1 bg-white rounded">
            //       <ArrowsPointingOutIcon className="w-3 h-3" />
            //     </div>
            //   </div>
            // </div>

            <img
              src={`${
                item.inventoryItem.thumbnail ?? "/inventory-img-placeholder.png"
              }`}
              alt="item_image"
              // width={100}
              // height={100}
              className="lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-full sm:w-[176.5px] h-[176.5px] lg:w-[100px] lg:h-[100px] object-contain flex-shrink-0"
            />
          )}
          <Stack gap={"8px"}>
            <Typography fontSize={16} fontWeight={500} textColor={"#475467"}>
              {item.inventoryItem.sku ?? "-"}
            </Typography>
            <Typography fontSize={16} fontWeight={700} textColor={"#101828"}>
              {item.inventoryItem.productName}
            </Typography>
            <TagsCollection tags={item.inventoryItem.tags} />
          </Stack>
        </Stack>
        {/* <div className="flex flex-col flex-1 gap-2">
          <div className="flex flex-wrap items-center gap-2">
            {(item.inventoryItem.tags?.tags || ["Pieces", "Pack", "Box"]).map(
              (t) => (
                <div
                  onClick={() => setSelectedPacks(t)}
                  key={t}
                  className={`w-fit rounded-[4px]  py-[10px] px-[12px] cursor-pointer border-[#499873] border ${
                    selectedPacks === t ? "bg-[#CFE5DA]  border-none" : ""
                  } `}
                >
                  <Typography
                    className={`text-xs ${
                      selectedPacks === t ? "text-[#1B392D]" : "text-[#499873]"
                    } `}
                  >
                    {t}
                  </Typography>
                </div>
              )
            )}
          </div>
          <Typography className="text-sm font-medium">
            {`${currency}`}
            {formatPrice(price, currency)}
          </Typography>
          <Typography className="text-sm font-medium">
            1 box (1,000 pieces)
          </Typography>
        </div> */}
        <If condition={isMobile || type === "inventory"}>
          <Stack direction={"column"} gap={"4px"} onClick={onClick}>
            <Typography className="text-[#101828] text-base font-semibold">
              {`${currency}`}{" "}
              {formatPrice(calculatePrice(item, activeUOM), currency)}
            </Typography>

            <If condition={isMobile && item.inventoryItem.uom1 ? true : false}>
              <div className="border-[#499873] p-2 rounded border w-fit">
                <Typography className="text-xs text-[#499873]">
                  {item.inventoryItem.uom1}
                </Typography>
              </div>
            </If>
          </Stack>
        </If>

        {!isMobile && catalog && !catalog.hidePrice && (
          <div className="flex w-[63%] justify-start items-start gap-4">
            <UOMSelector
              item={item}
              hidePrice={catalog.hidePrice}
              catalogType={catalog.catalogType}
              activeUOM={activeUOM}
              setActiveUOM={(uom) => {
                setActiveUOM(uom);

                // if (updateUOMCallback) {
                //   updateUOMCallback(uom);
                // }
              }}
              size="lg"
            />
            <CartQuantityManager
              item={item}
              hidePrice={catalog?.hidePrice}
              withAddToCart
              catalog={catalog}
              defaultUOM={activeUOM}
              setToCart={setToCart}
              resetFlag={resetFlag}
            />
          </div>
        )}
      </div>
      <hr
        className={`mt-4 w-full border-b border-solid lg:border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      />
    </ListItem>
  );
};

export default ProductListItem;
