import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import i18next from "i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../../../components/Error";
// import { TRACKER_CONSTANTS } from "../../../../constants/tracker.events";
import { AuthService } from "../../../../services/auth.service";
// import { TrackerService } from "../../../../services/tracker.service";
import { BrowserRouterConstants } from "../../../../utils/constants";

const generalSchema = z.object({
  agreed: z.boolean().default(false),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  mobile: z
    .string()
    .min(
      1,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .max(
      100,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    ),
  referralCode: z.string().optional().nullable(),
  referrerUserId: z.string().optional().nullable(),
});
const invitationSchema = generalSchema.merge(
  z.object({
    referralCode: generalSchema.shape.referralCode.optional().nullable(),
    referrerUserId: generalSchema.shape.referrerUserId.optional().nullable(),
  })
);

const useContainer = () => {
  // const [invitationType, setInvitationType] = useState<InvitationType | null>(
  //   null
  // );
  // const [showModal, setShowModal] = useState(false);
  const [urlSearchParams] = useSearchParams();
  const token = urlSearchParams.get("token");
  // const callbackUrl = urlSearchParams.get("callbackURL");
  const navigate = useNavigate();

  const schema = token ? invitationSchema : generalSchema;

  const {
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    setValue,
    trigger,
    register,
    clearErrors,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const { mobile, country, agreed } = watch() as any;
  // const invitationTokenMutation = useMutation({
  //   mutationFn: AuthService.invitationTokenCheck,
  //   onSuccess: ({ data }) => {
  //     if (data.redirectURL) {
  //       navigate(data.redirectURL);
  //     } else {
  //       setInvitationType(data.invitationType);
  //       if (data.phoneNumber) {
  //         // setValue("mobile", data.phoneNumber.mobile);
  //         setValue("country", {
  //           code: data.phoneNumber.countryCode,
  //           dial: data.phoneNumber.countryCode + `${data.phoneNumber.mobile}`,
  //           label:
  //             countries.find((c) => c.code === data.phoneNumber.countryCode)
  //               ?.label ?? "",
  //         });
  //       }
  //     }
  //   },
  //   onError: (error: any) => {
  //     toast(showErrorToast(error), ErrorToastConfig);
  //     navigate("/signup");
  //   },
  // });

  // TODO: try with invitation token if can get the businessId: if with role and referrer businessId is the same, then invalid, if different business but there is an entry in ther eferral entity with user businessId, then invalid
  const mutation = useMutation({
    mutationFn: AuthService.verifyPhoneNumber,
    onSuccess: ({ data }) => {
      if (!data.isRegisteredPhone) {
        localStorage.setItem("countryCode", `+${country.code}`);
        localStorage.setItem("mobile", country.dial);
        localStorage.setItem("method", "signup-phone");
        localStorage.setItem(
          "signUpCredentials",
          JSON.stringify({
            country,
          })
        );

        navigate(
          `${
            BrowserRouterConstants.Signup
          }/company-profile?${urlSearchParams.toString()}`
        );
      } else {
        setError(
          "mobile",
          {
            message: i18next.t("commons.error.form.mobileNotAvailable"),
            type: "manual",
          },
          { shouldFocus: true }
        );
      }
      // if (data.token) {
      //   localStorage.setItem("phoneVerificationToken", data.token);
      //   localStorage.setItem("userSub", data.id);
      //   localStorage.setItem("email", email);
      //   localStorage.setItem("countryCode", country?.code.toString() ?? "");
      //   localStorage.setItem("mobile", mobile);
      //   setShowModal(true);
      //   setTimeout(() => {
      //     setShowModal(false);
      //     if (callbackUrl) {
      //       navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
      //     } else {
      //       navigate("/verify");
      //     }
      //   }, 4000);
      // }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data: any) => {
    let payload = {
      phoneNumber: data.country.dial,
      phoneNumberCountryCode: data.country.code,
    };

    mutation.mutate(payload);
  };

  // useEffect(() => {
  //   if (token) {
  //     invitationTokenMutation.mutate({ token });
  //   }
  // }, [token]);

  return {
    isValid,
    agreed,
    errors,
    mobile,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
    setError,
    setValue,
    isLoading: mutation.isPending,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
    register,
    // showModal,
  };
};

export default useContainer;
