import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../../../components/Error";
import { CreditsService } from "../../../../services/credit.service";
import { ICredit } from "../../../../types/credits";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { PaymentService } from "../../../../services/payment.service";

export type PaymentMethodFormValue = {
  paymentMethod: string;
}
const useContainer = () => {
  const { control, setValue, getValues } = useForm<PaymentMethodFormValue>()
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);
  const [credit, setCredit] = useState<ICredit>();
  const [paymentInfo, setPaymentInfo] = useState<any>();

  const reqPaymentMutation = useMutation({
    mutationFn: (payload: any) => PaymentService.requestPayment(payload,""),
    onSuccess: (data) => {
      // window.location.href = data.paymentUrl;
      setPaymentInfo(data.data)
      setShowPaymentInfoModal(true)
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  })

  const buyNowMutation = useMutation({
    mutationFn: CreditsService.buyCredit,
    onSuccess: (data) => {
      if (credit) {
        const payload = PaymentService.createPaymentRequestPayload(
          {
            method: getValues("paymentMethod").split(":")[0],
            option: getValues("paymentMethod").split(":")[1] ?? "",
            transactionType: "credit_top_up",
            transactionId: data.id,
            amount: Math.round(credit.price * (1 - credit.discount / 100) * 100) / 100,
            currency: credit.currency,
            redirectUrl: window.location.href
          }
        )
        reqPaymentMutation.mutate(payload)
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  //#region handler functions
  function byNowHandler(credit: ICredit) {
    console.log("Credit", credit)
    console.log("Credit-Payment", getValues("paymentMethod"))
    setCredit(credit);
    buyNowMutation.mutate(credit.id);
  }
  //#endregion
  return {
    isPending: buyNowMutation.isPending,
    byNowHandler,
    showPaymentModal,
    setShowPaymentModal,
    control, 
    setValue,
    showPaymentInfoModal,
    setShowPaymentInfoModal,
    paymentInfo, 
  };
};

export default useContainer;
