import { Box, Button, FormControl, Stack, Typography } from "@mui/joy";
import startsWith from "lodash.startswith";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
} from "../../../../utils/constants";
import { matchesFormat } from "../../../../utils/misc";
import useContainer from "./useContainer";

const PhoneSigninForm = () => {
  const { t } = useTranslation();

  const {
    errors,
    phoneNo,
    setError,
    setValue,
    isLoading,
    isValid,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
  } = useContainer();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full gap-y-6 px-4"
    >
      <Stack
        direction={"row"}
        sx={{
          mt: 3,
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl error={errors.mobile ? true : false} className="w-full">
          {/* <FormLabel>
            <Typography
              fontSize={"sm"}
              fontWeight={500}
              className={`transition-opacity duration-300 ${
                mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
              }`}
              textColor={"text.secondary"}
            >
              {t("landing.signup.phoneNumber")}
            </Typography>
          </FormLabel> */}
          <PhoneInput
            inputProps={{
              name: "input-phone",
            }}
            value={phoneNo}
            onlyCountries={PhNoWhiteListedCountries}
            masks={{
              my: "..-....-....",
              sg: "....-....",
              th: "... ... ...",
              id: "... ... ... ...",
            }}
            enableTerritories={false}
            country={"id"}
            isValid={(inputNumber, meta: any, countries) => {
              if (inputNumber === "") {
                return true;
              }
              const result = (countries || []).some((country: any) => {
                if (
                  startsWith(inputNumber, country.dialCode) ||
                  startsWith(country.dialCode, inputNumber)
                ) {
                  const iso2: CountryCode = meta.iso2 as CountryCode;
                  const format = PhNoFormat[iso2];
                  if (!format) return false;
                  const isMatch = matchesFormat(
                    inputNumber,
                    format,
                    country.countryCode
                  );

                  return isMatch;
                }
                return false;
              });

              if (!result && !errors.mobile?.message) {
                setError("mobile", {
                  message: t("commons.error.form.mobileInvalid"),
                });
              } else if (result && !!errors.mobile?.message) {
                if (errors.mobile.type === "manual") {
                  return false;
                }
                clearErrors("mobile");
                trigger("mobile");
              }
              return result;
            }}
            inputStyle={{
              height: "56px",
              width: "100%",
              fontSize: "14px",
              fontWeight: 500,
              borderRadius: "8px",
              paddingLeft: "5.5rem",
              lineHeight: "56px",
            }}
            buttonStyle={{
              padding: "0 1rem",
              borderRadius: "8px 0 0 8px",
            }}
            onChange={(phone, meta: any) => {
              setValue("country.code", meta.dialCode);
              setValue("country.label", meta.name);
              setValue(
                "country.dial",
                phone.replace(new RegExp(meta.dialCode), "")
              );
              setValue("mobile", phone.replace(new RegExp(meta.dialCode), ""));

              if (errors.mobile?.type === "manual") {
                clearErrors("mobile");
              }
            }}
          />

          {/* {errors.mobile?.message && (
            <FormHelperText>{errors.mobile?.message}</FormHelperText>
          )} */}
        </FormControl>
      </Stack>

      <Box
        sx={{
          width: "300px",
          mx: "auto !important",
          py: "1rem",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {errors.mobile?.message && (
          <Typography fontSize={12} color="danger">
            {errors.mobile?.message}
          </Typography>
        )}
        <Button
          id={"btn-login"}
          disabled={isLoading || !isValid}
          size="lg"
          fullWidth
          type="submit"
        >
          {t("landing.loginBtn")}
        </Button>
      </Box>
    </form>
  );
};

export default PhoneSigninForm;
