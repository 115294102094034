import {
  KeyboardArrowDown,
  LockOpenOutlined,
  LockOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Input,
  List,
  Option,
  Select,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { AxiosError } from "axios";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LogoImg from "../../assets/linkz-logo-small.png";
import ListUnavailable from "../../components/Catalogue/ListUnavailable";
import { If, IfElse } from "../../components/Condition";
import { theme } from "../../components/Theme";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { InventoryService } from "../../services/inventory.service";
import { ItemService } from "../../services/item.service";
import { SupplierService } from "../../services/supplier.service";
import { TrackerService } from "../../services/tracker.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CartItemDataType } from "../../types/cart";
import { CatalogInventoryItemType } from "../../types/item";
import { getCartItems } from "../../utils/cart";
import { formatPrice } from "../../utils/formatPrice";
import AddToCartButton from "./components/AddToCartButton";
import { ProductCard } from "./components/ProductCard";
import ProductDetailsModal from "./components/ProductDetailsModal";
import ProductListItem from "./components/ProductListItem";
import useContainer from "./useContainer";

const sortOptions = [
  { value: "", label: "Sort By" },
  { value: "id_asc", label: "Newest first" },
  { value: "id_desc", label: "Latest first" },
  { value: "productName_asc", label: "A to Z (ascending)" },
  { value: "productName_desc", label: "Z to A (descending)" },
  { value: "price_desc", label: "Highest Price" },
  { value: "price_asc", label: "Lowest Price" },
];

const ListSupplierItems = () => {
  const { t } = useTranslation();

  const { catalogId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const query = searchParams.get("q");
  const sortBy = searchParams.get("sortBy");
  const sortDirection = searchParams.get("sortDirection") ?? "";
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");
  const { session } = useAuthStore.getState();

  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>();
  const [unsavedOrderData, setUnsavedOrderData] = useState<string | null>(
    localStorage.getItem(`unsavedOrderData_${orderId}`)
  );
  const [searchTerm, setSearchTerm] = useState<string>();
  const [viewMode, setViewMode] = useState<string>("image");
  const [cartUpdated, setCartUpdated] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>();
  const [toCart, setToCart] = useState<CartItemDataType[]>([]);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [resetFlag, setResetFlag] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState<{
    show: boolean;
    item: CatalogInventoryItemType;
  }>();

  // const loaderRef = useRef(null);

  const {
    fetchAllItems,
    items,
    setItems,
    catalog,
    setCatalog,
    pagination,
    setPagination,
    setIsLoading,
    unauthorized,
    setUnauthorized,
    hasMore,
    setHasMore,
    cartItems,
    setCartItems,
    imgExistanceCheckQuery,
  } = useContainer();

  const initiateInventoryItems = useCallback(
    (catalogId: string, query?: string) => {
      InventoryService.getCatalogItems({
        catalogId,
        paging: true,
        limit: 10,
        offset: 0,
        searchTerm: query,
        sortDirection: (["asc", "desc"].includes(sortDirection)
          ? sortDirection
          : "desc") as "asc" | "desc",
        sortBy: sortBy ? sortBy : undefined,
      })
        .then((res) => {
          setItems(res.data);

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }

          // getting all inventoryType for categories
          const businessId = res.data[0].inventoryItem.business;
          if (businessId) {
            ItemService.getInventoryItemField({
              fields: ["inventoryType"],
              businessId,
            }).then((res) => {
              const cats = res.map(
                (type: { inventoryType: string }) => type.inventoryType
              );
              setCategories(cats);
            });
          }
        })
        .catch((err) => console.error(err));
    },
    []
  );

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      if (session?.accessToken && isGuestMode) {
        window.location.href = `/client/suppliers/${catalogId}/items`;
        return;
      }

      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          setCatalog(res);
          // setIsCatalogOwner(res.business.id === session?.activeBusiness.id);
          const tempCartItems = getCartItems(
            catalogId,
            session?.account.id || "wildcart",
            session?.accessToken ? "local" : "session"
          );

          if (tempCartItems) {
            setCartItems(tempCartItems);
            setCartUpdated(false);
          }

          TrackerService.track(
            TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AccessCatalogDetails,
            {
              "Catalog ID": catalogId,
              "Catalog Name": res?.catalogName,
              "Catalog Owner Company Name": res?.business.companyName,
              "Product Count": res?.itemsCount,
            }
          );

          if (res.catalogType !== "PUBLIC") {
            SupplierService.verifyAccess({
              catalog: catalogId,
              business: getActiveBusiness()?.id ?? null,
              accountId: session?.account.id ?? null,
            })
              .then((res) => {
                if (!res) {
                  setUnauthorized(true);

                  if (isGuestMode) {
                    window.location.href = `/client/suppliers/${catalogId}/items`;
                  }
                }

                initiateInventoryItems(catalogId, query ? query : undefined);
              })
              .catch((error) => {
                setUnauthorized(true);
                console.error(error);
              });
          } else {
            initiateInventoryItems(catalogId, query ? query : undefined);
          }
        })
        .catch((err) => {
          console.error(err);
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [catalogId, session]);

  // useEffect(() => {
  //   if (query && !searchTerm) return;
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting && hasMore && catalogId && items.length > 0) {
  //       fetchAllItems({
  //         catalogId: catalogId,
  //         paging: true,
  //         limit: 10,
  //         offset: pagination.limit * pagination.current,
  //         searchTerm: searchTerm ?? null,
  //         inventoryType: selectedCategory !== "all" ? selectedCategory : null,
  //         isReset: false,
  //       });
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchAllItems]);

  useEffect(() => {
    if (query && !searchTerm) return;
    if (
      catalogId &&
      (searchTerm ||
        searchTerm === "" ||
        selectedCategory ||
        (sortBy && sortDirection))
    ) {
      fetchAllItems({
        catalogId: catalogId,
        paging: true,
        limit: 10,
        offset: 0,
        searchTerm: searchTerm,
        inventoryType: selectedCategory !== "all" ? selectedCategory : null,
        isReset: true,
        sortDirection: (["asc", "desc"].includes(sortDirection)
          ? sortDirection
          : "desc") as "asc" | "desc",
        sortBy: sortBy ? sortBy : "updatedAt",
      });
    }
  }, [catalogId, searchTerm, selectedCategory, sortDirection, sortBy]);

  useEffect(() => {
    if (cartUpdated && catalogId) {
      const tempCartItems = getCartItems(
        catalogId,
        session?.account.id || "wildcart",
        session?.accessToken ? "local" : "session"
      );

      if (tempCartItems) {
        setCartItems(tempCartItems);
        setCartUpdated(false);
      }
    }
  }, [cartUpdated]);

  useEffect(() => {
    if (query && !searchTerm) {
      setSearchTerm(query);
    }
  }, [query]);

  useEffect(() => {
    if (toCart.length > 0) {
      let tmp = 0;
      console.log("BANANA IS TOCART BEFORE?", toCart);
      toCart.forEach((c) => {
        tmp += (c.initPrice ?? 0) * c.quantity;
      });

      console.log("BANANA IS TOCART AFTER?", tmp);
      setSubtotal(tmp);
    } else {
      setSubtotal(0);
    }
  }, [toCart]);

  const CatalogTypeButton = () => {
    return (
      <button
        className={`my-4 lg:my-0 py-3 px-4 rounded-lg ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default w-[106px]`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlined
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlined
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("q");
      } else {
        searchParams.set("q", value);
      }
      setSearchParams(searchParams);
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const orderDataCallback = () => {
    setUnsavedOrderData(localStorage.getItem(`unsavedOrderData_${orderId}`));
  };

  return (
    <Sheet
      sx={{
        position: "relative",
        backgroundColor: "transparent",
        overflowX: "hidden",
        px: {
          xs: isGuestMode ? 4 : 0,
        },
        width: isGuestMode ? "auto" : { xs: "100%" },
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <IfElse
        condition={!error}
        ifBlock={
          <IfElse
            condition={!unauthorized}
            ifBlock={
              <Stack
                direction={"column"}
                gap={{ xs: "8px", md: "24px" }}
                position={"relative"}
              >
                <If condition={isGuestMode}>
                  <div className="items-center w-full py-4 lg:flex">
                    <img
                      src={LogoImg}
                      alt="linkz-logo"
                      width={109.05}
                      height={52}
                      className={"mr-auto"}
                    />

                    <span className="flex flex-col gap-2 ml-auto lg:flex-row">
                      {t("catalog.loginToSeePrice")}
                      <Link
                        to={`/signin?callbackURL=/client/suppliers/${catalogId}/items`}
                        className="ml-3"
                      >
                        <Button className="min-w-[3rem]">
                          {t("landing.loginBtn")}
                        </Button>
                      </Link>
                    </span>
                  </div>
                </If>

                {/* catalog details */}
                <Stack
                  direction={{ xs: "column-reverse", md: "row" }}
                  gap={"24px"}
                >
                  <IfElse
                    condition={isMobile}
                    ifBlock={
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <CatalogTypeButton />

                        <If condition={!catalog?.hidePrice}>
                          <Link
                            to={
                              isGuestMode
                                ? catalog?.catalogType === "PRIVATE"
                                  ? `/client/suppliers/${catalogId}/items`
                                  : `/public/cart/${catalogId}/items`
                                : orderId
                                ? `/client/orders/${orderId}`
                                : session?.accessToken
                                ? `/client/cart/${catalogId}/items`
                                : `/public/cart/${catalogId}/items`
                            }
                          >
                            <Button
                              type="button"
                              variant="outlined"
                              startDecorator={<ShoppingCartOutlined />}
                            >
                              {t(`catalog.${orderId ? "order" : "cart"}`)}{" "}
                              {!orderId && cartItems
                                ? cartItems.items.length > 0 &&
                                  `(${cartItems?.items.length})`
                                : ""}
                            </Button>
                          </Link>
                        </If>
                      </Stack>
                    }
                    elseBlock={
                      <div className="block lg:hidden">
                        <CatalogTypeButton />
                      </div>
                    }
                  />

                  <div className={`py-4 lg:py-0 relative`}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      {imgExistanceCheckQuery.data && (
                        <img
                          style={{
                            width: "60px",
                            height: "60px",
                            objectFit: "cover",
                          }}
                          src={imgExistanceCheckQuery.data}
                          alt=""
                        />
                      )}
                      <div>
                        <Typography
                          fontSize={16}
                          fontWeight={500}
                          textColor={"#101828"}
                        >
                          {catalog?.business?.companyName}
                        </Typography>
                        <Typography
                          fontSize={18}
                          fontWeight={700}
                          textColor={"#101828"}
                        >
                          {catalog?.catalogName}
                        </Typography>
                      </div>
                      <div className="hidden lg:block">
                        <CatalogTypeButton />
                      </div>
                    </Stack>
                    {/* <Typography
                      fontSize={14}
                      sx={{
                        color: theme.colorSchemes.dark.palette.text.secondary,
                      }}
                    >
                      <span className="text-xs lg:text-sm lg:hidden">
                        {t("commons.lastUpdated")}:{" "}
                        <span className="ml-2">
                          {formatDate(new Date(catalog?.updatedAt ?? ""))}
                        </span>
                      </span>
                    </Typography> */}
                    <hr className="lg:hidden w-screen h-px absolute bottom-0 bg-[#636B744D]" />
                  </div>

                  <div className="hidden ml-auto lg:block">
                    <If condition={!catalog?.hidePrice}>
                      <Link
                        to={
                          isGuestMode
                            ? catalog?.catalogType === "PRIVATE"
                              ? `/client/suppliers/${catalogId}/items`
                              : `/public/cart/${catalogId}/items`
                            : orderId
                            ? `/client/orders/${orderId}`
                            : session?.accessToken
                            ? `/client/cart/${catalogId}/items`
                            : `/public/cart/${catalogId}/items`
                        }
                      >
                        <Button
                          type="button"
                          variant="outlined"
                          startDecorator={<ShoppingCartOutlined />}
                        >
                          {t(`catalog.${orderId ? "order" : "cart"}`)}{" "}
                          {!orderId && cartItems
                            ? cartItems.items.length > 0 &&
                              `(${cartItems?.items.length})`
                            : ""}
                        </Button>
                      </Link>
                    </If>
                  </div>
                </Stack>

                {/* search bar */}
                <Stack direction={"column"} gap={"24px"}>
                  <Input
                    defaultValue={query ?? ""}
                    variant="linkz-input-search"
                    size="sm"
                    fullWidth={isGuestMode}
                    name="searchFormData.search"
                    placeholder={t("catalog.searchItemPlaceholder")}
                    startDecorator={<SearchOutlinedIcon />}
                    sx={{ flexGrow: 1, width: { xs: "100%", lg: "41%" } }}
                    onChange={handleChange}
                  />

                  <div
                    className={`flex gap-2 w-[90vw] overflow-auto lg:w-full items-center hide-scrollbar`}
                  >
                    {/* category filter */}
                    <Select
                      defaultValue="all"
                      variant="plain"
                      size="sm"
                      indicator={<KeyboardArrowDown />}
                      className="w-[50%] lg:min-w-[10%] lg:max-w-[13%] uppercase"
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        setSelectedCategory(value);
                      }}
                    >
                      <Option
                        // key={cat}
                        color="primary"
                        value="all"
                        label={t("catalog.supplier.items.allCategoryLabel")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.allCategoryLabel")}
                      </Option>
                      {categories.map((cat) => {
                        return (
                          <Option
                            color="primary"
                            value={cat}
                            label={cat.toUpperCase()}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.common.black,
                            }}
                          >
                            {cat}
                          </Option>
                        );
                      })}
                    </Select>

                    {/* view mode */}
                    <Select
                      defaultValue="image"
                      variant="plain"
                      size="sm"
                      indicator={<KeyboardArrowDown />}
                      className="w-[50%] lg:min-w-[10%] lg:max-w-[13%] uppercase"
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        setViewMode(value || "image");
                      }}
                    >
                      <Option
                        color="primary"
                        value={"image"}
                        label={t("catalog.supplier.items.viewMode.image")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.viewMode.image")}
                      </Option>
                      <Option
                        color="primary"
                        value={"list"}
                        label={t("catalog.supplier.items.viewMode.list")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.viewMode.list")}
                      </Option>
                      {!isMobile && (
                        <Option
                          color="primary"
                          value={"grid"}
                          label={t("catalog.supplier.items.viewMode.grid")}
                          sx={{
                            color: theme.colorSchemes.dark.palette.common.black,
                          }}
                        >
                          {t("catalog.supplier.items.viewMode.grid")}
                        </Option>
                      )}
                    </Select>

                    {/* sort by */}
                    <Select
                      defaultValue={
                        sortBy && sortDirection
                          ? sortBy + "_" + sortDirection
                          : ""
                      }
                      variant="plain"
                      size="sm"
                      indicator={<KeyboardArrowDown />}
                      className="w-[50%] lg:min-w-[10%] lg:max-w-[13%] uppercase"
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        if (!value) {
                          return;
                        }

                        if (value.length > 1) {
                          const [field, order] = value.split("_");
                          searchParams.set("sortBy", field);
                          searchParams.set("sortDirection", order);
                        } else {
                          searchParams.delete("sortBy");
                          searchParams.delete("sortDirection");
                        }
                        setSearchParams(searchParams);
                      }}
                    >
                      {sortOptions.map((opt) => {
                        if (catalog?.hidePrice && opt.value.includes("price")) {
                          return;
                        }

                        return (
                          <Option
                            key={opt.value}
                            value={opt.value}
                            color="primary"
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.common.black,
                            }}
                          >
                            {t(`catalog.sortOptions.${opt.label}`)}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Stack>

                {/* product list */}
                <List sx={{ m: 0, p: 0, mb: "100px" }}>
                  <InfiniteScroll
                    pageStart={0}
                    initialLoad
                    loadMore={(page: number) => {
                      // console.log("LOADMORE TRIGGERED", pagination.limit, page);
                      fetchAllItems({
                        catalogId: catalogId ?? "",
                        paging: true,
                        limit: 10,
                        offset: pagination.limit * (page - 1),
                        searchTerm: searchTerm ?? null,
                        inventoryType:
                          selectedCategory !== "all" ? selectedCategory : null,
                        isReset: false,
                        sortDirection: (["asc", "desc"].includes(sortDirection)
                          ? sortDirection
                          : "desc") as "asc" | "desc",
                        sortBy: sortBy ? sortBy : undefined,
                      });
                    }}
                    hasMore={hasMore}
                    loader={
                      <div className="loader" key={0}>
                        Loading ...
                      </div>
                    }
                  >
                    <div
                      className={`${
                        viewMode === "image"
                          ? "grid grid-cols-2 lg:grid-cols-1 gap-2 lg:gap-0 align-stretch"
                          : viewMode === "grid"
                          ? "grid grid-cols-3 gap-2 lg:gap-6"
                          : "flex flex-col"
                      } `}
                    >
                      {items.map((i) => {
                        switch (viewMode) {
                          case "image":
                            return (
                              <>
                                <div className="block lg:hidden">
                                  <ProductCard
                                    item={i}
                                    catalog={catalog}
                                    setToCart={setToCart}
                                    resetFlag={resetFlag}
                                    type="supplier"
                                    onClick={() => {
                                      setSelectedItem({
                                        show: true,
                                        item: i,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="hidden lg:block">
                                  <ProductListItem
                                    type="image"
                                    item={i}
                                    isMobile={isMobile}
                                    catalog={catalog!}
                                    setToCart={setToCart}
                                    resetFlag={resetFlag}
                                    onClick={() => {
                                      setSelectedItem({
                                        show: true,
                                        item: i,
                                      });
                                    }}
                                  />
                                </div>
                              </>
                            );
                          case "grid":
                            return (
                              <ProductCard
                                type="supplier"
                                item={i}
                                catalog={catalog}
                                setToCart={setToCart}
                                resetFlag={resetFlag}
                                onClick={() => {
                                  setSelectedItem({
                                    show: true,
                                    item: i,
                                  });
                                }}
                              />
                            );
                          default:
                            return (
                              <ProductListItem
                                item={i}
                                isMobile={isMobile}
                                catalog={catalog!}
                                setToCart={setToCart}
                                resetFlag={resetFlag}
                                onClick={() => {
                                  setSelectedItem({
                                    show: true,
                                    item: i,
                                  });
                                }}
                              />
                            );
                        }
                      })}
                    </div>
                  </InfiniteScroll>
                </List>

                {/* add to cart toolbar */}
                <If condition={!catalog?.hidePrice}>
                  <div className="bg-[#FCFCFD] fixed hidden lg:flex w-full max-w-[inherit] bottom-0 left-0">
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent={{ md: "space-between" }}
                      alignItems={"center"}
                      width={"100%"}
                      ml={isGuestMode ? 0 : "240px"}
                      py={"24px"}
                      px={"32px"}
                      gap={{ xs: "8px", md: 0 }}
                    >
                      <hr
                        className={`w-screen h-px absolute ${
                          isGuestMode ? "left-0" : "left-[240px]"
                        } top-0 bg-[#636B744D]`}
                      />
                      <div className="flex align-middle lg:block">
                        <Typography fontSize={18}>
                          <span className="text-sm lg:text-lg">
                            {t("catalog.total")}:{" "}
                            <span className="text-black font-[600]">
                              {`${
                                items[0]?.currency ??
                                items[0]?.inventoryItem.currency ??
                                ""
                              } ${
                                catalog?.hidePrice
                                  ? "-"
                                  : formatPrice(
                                      subtotal,
                                      items[0]?.currency ??
                                        items[0]?.inventoryItem.currency
                                    )
                              }`}
                            </span>
                          </span>
                        </Typography>
                      </div>

                      <div className="flex flex-col lg:flex-row gap-[8px] lg:gap-[10px]">
                        <Button
                          variant="outlined"
                          className="border-[#CDD7E1] text-[#32383E] focus:bg-[#CDD7E1] focus:border-[#CDD7E1] focus:text-[#32383E] hover:bg-[#CDD7E1] hover:border-[#CDD7E1] hover:text-[#32383E]"
                          onClick={() => {
                            setToCart([]);
                            setResetFlag(!resetFlag);
                          }}
                        >
                          {t("catalog.supplier.items.resetQtyBtn")}
                        </Button>

                        {catalog && (
                          <AddToCartButton
                            toCart={toCart}
                            catalog={catalog}
                            inventoryItems={items}
                            setCartUpdated={setCartUpdated}
                            cartDataCallback={() => {
                              setToCart([]);
                              setResetFlag(!resetFlag);
                            }}
                            orderDataCallback={orderDataCallback}
                            isDisabled={toCart.length === 0}
                            unsavedOrderData={unsavedOrderData}
                          />
                        )}
                      </div>
                    </Stack>
                  </div>
                </If>

                {catalog && selectedItem && (
                  <ProductDetailsModal
                    show={selectedItem.show}
                    item={selectedItem.item}
                    catalog={catalog}
                    isDisabled
                    onClose={(item: CatalogInventoryItemType) => {
                      setSelectedItem({
                        show: false,
                        item: item,
                      });
                    }}
                    withAddToCart={isMobile}
                    items={items}
                    orderDataCallback={orderDataCallback}
                    setCartUpdated={setCartUpdated}
                  />
                  // <IfElse
                  //   condition={isMobile}
                  //   ifBlock={
                  //     <ProductDetailsModal
                  //       show={selectedItem.show}
                  //       item={selectedItem.item}
                  //       catalog={catalog}
                  //       isDisabled
                  //       onClose={(item: CatalogInventoryItemType) => {
                  //         setSelectedItem({
                  //           show: false,
                  //           item: item,
                  //         });
                  //       }}
                  //       withAddToCart={isMobile}
                  //       unsavedOrderData={unsavedOrderData}
                  //       items={items}
                  //       orderDataCallback={orderDataCallback}
                  //       setCartUpdated={setCartUpdated}
                  //     />
                  //   }
                  //   elseBlock={
                  //     <EditCatalogueItemModal
                  //       catalogId={catalog?.id}
                  //       showModal={selectedItem.show}
                  //       catalogInventoryItem={selectedItem.item}
                  //       onClose={(item: CatalogInventoryItemType) => {
                  //         setSelectedItem({
                  //           show: false,
                  //           item: item,
                  //         });
                  //       }}
                  //       isBuyerView={true}
                  //     />
                  //   }
                  // />
                )}
              </Stack>
            }
            elseBlock={
              <ListUnavailable message={t("catalog.noAccessCatalog")} />
            }
          />
        }
        elseBlock={
          <Box display={"flex"} width={"100%"}>
            <If condition={error?.response?.status === 404}>
              <p className="mx-auto my-8">Catalog not found</p>
            </If>
            <If condition={error?.response?.status === 500}>
              <p className="mx-auto my-8">Something went wrong</p>
            </If>
          </Box>
        }
      />
      {/* <div ref={loaderRef} className="flex justify-center w-full">
        {isLoading && "Loading..."}
      </div> */}
    </Sheet>
  );
};

export default ListSupplierItems;
