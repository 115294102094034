import { Card, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import ContactForm from "./components/ContactForm";

export default function AddBusinessContact() {
  const { t } = useTranslation();
  return (
    <>
      <Card className="mt-8">
        <Stack alignItems={"center"}>
          <Typography
            textColor={"text.secondary"}
            fontWeight={"lg"}
            fontSize={"lg"}
          >
            {t("manage.businessContact.addNewContact")}
          </Typography>
        </Stack>
        <ContactForm />
      </Card>
    </>
  );
}
